import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../guards/auth.guard";
import { SelectionComponent } from "./selection/selection.component";
import { MembershipComponent } from "./membership.component";
import { AgreementComponent } from "./agreement/agreement.component";
import { PaymentComponent } from "./payment/payment.component";
import { CompletedComponent } from "./completed/completed.component";

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: "membership",
        component: MembershipComponent,
        children: [
          { path: "", redirectTo: "/membership/selection", pathMatch: 'full' },
          {
            path: "selection",
            children:[
              {
                path: ":membership",
                component: SelectionComponent,
              },
              {
                path: "",
                pathMatch: "full",
                component: SelectionComponent
              }
            ]
          },
          {
            path: "agreement",
            component: AgreementComponent,
            canActivate: [],
          },
          {
            path: "payment",
            component: PaymentComponent,
            canActivate: [],
          },
          {
            path: "completed",
            component: CompletedComponent,
            canActivate: [],
          },
        ],
      },
    ]),
  ],
  exports: [RouterModule],
})
export class MembershipRoutingModule {}
