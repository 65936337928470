<!-- <ng-container *ngFor="let addon of addons"> -->
<!-- 	<h6 class="heading-6 target" id="{{addon.groupName}}">{{ addon.groupName }}</h6>
 --><ul class="services-list" *ngIf="addons?.length">
	<!-- <li> -->
		<!-- <h2 class="eyebrow">{{addonsList?.category}}</h2> -->
		<!-- <ul class="services-list" *ngIf="(cart?.selectedItems | addons:client).length"> -->
			
			<li  *ngFor="let addonItem of addons" [ngClass]="addonItem.selected ? 'active' : ''">
				<button class="add-to-cart desktop-only" (click)="addAddonForAll(addonItem)">
					<img [src]="addonItem.selected ? 'assets/svgs/icon-selected-check.svg' : 'assets/svgs/icon-accordion-open.svg' " />
					<span class="accessibility">Add this add-on to your cart</span>
				</button>
					
				<button class="flex-container open-services-pop" (click)="addonDetail(addonItem)">
					<!-- <img style="background: #484848; object-fit: none;" src="assets/svgs/icon-service-example.svg" alt=""/> -->
					<img [src]="'https://blvd.silvermirror.com/assets/thumb-urn\:blvd\:Service\:' + addonItem.id.split(':')[3] + '.png'" alt=""/>
					
					<div>
						<h2 class="heading-6">{{addonItem?.name}}</h2>
						<span class="badge-img"><img [src]="'https://blvd.silvermirror.com/assets/badge-urn\:blvd\:Service\:' + addonItem?.id?.split(':')[3] + '.png'" alt=""/></span>
						<!-- <span class="pill fill small yellow">Best Seller</span> -->
						<p class="pill">{{sharedService.getSkinType(addonItem?.description)}}</p>
						<span class="heading-6 small price">{{sharedService.formatPrice(addonItem?.listPrice) | currency:'USD':'symbol' : '1.0-0'}}</span>
					</div>
					
					
				</button>
			</li>
		<!-- </ul> -->
	<!-- </li> -->
</ul> 

<ul *ngIf="!addons?.length">
	<p>No add-ons are available for the selected service.</p>
</ul>
<!-- </ng-container> -->