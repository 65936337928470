import { Pipe, PipeTransform } from '@angular/core';
import { SharedService } from 'src/app/shared-component/shared.service';

@Pipe({
  name: 'order'
})
export class OrderPipe implements PipeTransform {

  constructor(private sharedService:SharedService){}

  transform(services: Array<any>, category: string): Array<any> {
    return services.sort((a,b)=> Number(this.sharedService.getServiceOrder(a.description)) - Number(this.sharedService.getServiceOrder(b.description)));
  }
  
}
