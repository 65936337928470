import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectionComponent } from './selection/selection.component';
import { MembershipRoutingModule } from './membership.routing.module';
import { MembershipComponent } from './membership.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { SharedComponentModule } from '../shared-component/shared-component.module';
import { MembershipLocationPipe } from './selection/location.pipe';
import { AgreementComponent } from './agreement/agreement.component';
import { PaymentComponent } from './payment/payment.component';



@NgModule({
  declarations: [
    SelectionComponent,
    AgreementComponent,
    PaymentComponent,
    MembershipComponent,
    MembershipLocationPipe
  ],
  imports: [
    MembershipRoutingModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSkeletonLoaderModule,
    NgxMaskDirective, 
    NgxMaskPipe,
    CommonModule,
    SharedComponentModule
  ],
  bootstrap: [MembershipComponent]
})
export class MembershipModule { }
