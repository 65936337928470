import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderEsthetician'
})
export class OrderEstheticianPipe implements PipeTransform {

  transform(estheticians: Array<any>): Array<any> {
    if(estheticians.length){
      return estheticians.sort((a, b) => {
        let orderA = Number(a?.staff?.bio) || Number(a?.node?.bio);
        let orderB = Number(b?.staff?.bio) || Number(b?.node?.bio);
        orderA == 0 ? orderA = 999 : null;
        orderB == 0 ? orderB = 999 : null;

        if (orderA < orderB) {
          return -1;
        }
        if (orderA > orderB) {
          return 1;
        }
        return 0;
      });
    }else{
      return estheticians;
    }
  }

}
