import { Pipe, PipeTransform } from '@angular/core';
import { BookingService } from '../booking.service';

@Pipe({
  name: 'filterService'
})
export class FilterServicePipe implements PipeTransform {

  constructor(private bookingService: BookingService) {}

  transform(categories: Array<any>, filter: string, client: any): Array<any> {
    /* console.log("categories", categories);
    console.log("filter", filter); */

    const allowedNames = ["30-Minute Facials", "50-Minute Facials", "Eyebrows", "Microchanneling", "Chemical Peels"];
    
    // Filter and sort categories to match the order in allowedNames
    let service = categories
      .filter((availableItem: any) => allowedNames.includes(availableItem.name))
      .sort((a, b) => allowedNames.indexOf(a.name) - allowedNames.indexOf(b.name));

    // Set all availableItems selected status to false by default
    service.forEach((availableService: any) => {
      availableService.availableItems.forEach((item: any) => {
        item.selected = false;
      });
    });

   // console.log("service", service);

    // Update the selected status based on client’s selected items
    service = this.isServiceAdded(service, client);
    return service;
  }

  isServiceAdded(services: Array<any>, client: any) {
    let selectedItems = this.bookingService.clientCart$.value.selectedItems;

    // Filter selected items based on client criteria
    selectedItems = selectedItems.filter((selectedItem: any) => {
      if (client !== 'me') {
        return selectedItem.guestId === client.id;
      } else {
        return selectedItem.guestId === null;
      }
    });

    // Mark items as selected if they are in the selectedItems list
    services.forEach((availableService: any) => {
      availableService.availableItems.forEach((item: any) => {
        selectedItems.forEach((selectedService: any) => {
          if (item.id === selectedService.item.id) {
            item.selected = true;
          }
        });
      });
    });

    return services;
  }
}
