import { DOCUMENT } from "@angular/common";
import { Component, HostListener, Inject, Input, OnInit ,ElementRef, ViewChild} from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/auth/auth.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @ViewChild('menuElement') menuElement: ElementRef | undefined;
  showSubMenu: boolean = false;

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: MouseEvent) {
    if (this.menuElement && !this.menuElement.nativeElement.contains(event.target)) {
      this.showSubMenu = false;
    }
  }
  @Input() mainTitle: string = "";

  ReadMore: boolean = true;
  visible: boolean = false;
  user:any;
  ngOnInit(): void {
    this.handleDocumentClick = this.handleDocumentClick.bind(this);
    this.document.addEventListener('click', this.handleDocumentClick);

    this.authService.$AuthUser.subscribe((user)=>{
      if(user){
        this.user = user;
        setTimeout(() => {
      const UserPopupButton:any = document.querySelector('.menu-item-has-children');
          UserPopupButton.addEventListener('click', this.onclick.bind(this));
        }, 6000);
      }
      user ? this.user = user : null;
    })
  }

  handleDocumentClick(event: any): void {
    // handle click event
    if (!event.target.closest('.user-pop') && !event.target.closest('.sign-in')) {
      this.visible = false;
    }
  }

  ngOnDestroy(): void {
    // remove click listener when component is destroyed
    this.document.removeEventListener('click', this.handleDocumentClick);
    this.document.removeEventListener('click', this.onclick);
  }

  constructor(@Inject(DOCUMENT) private document: Document,  public router: Router, public authService: AuthService ) {}

  logout() {
    localStorage.clear();
    this.authService.$AuthUser.next(null);
    this.router.navigateByUrl("/auth", {replaceUrl: true});
  }
  
  onclick() {
    this.ReadMore = !this.ReadMore;
    this.visible = !this.visible;
  }
  menuToggle(){
    this.showSubMenu = !this.showSubMenu
  }
  navigateToDashboard(){
    this.authService.$AuthUser.subscribe((user)=>{
      if(user){
        //this.router.navigate(['/dashboard']);
      }{
        this.menuToggle();
      }
    })
  }
  getIcon(){
    if(this.router.url.includes('booking')){
      return 'booking-logo.svg'
    }else if(this.router.url.includes('nutrition')){
      return 'nutrition-logo.svg'
    }else{
      return 'booking-logo.svg';
    }
  }
}
