import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { SharedService } from '../shared-component/shared.service';
import { environment } from "src/environments";
import { BookingService } from '../booking/booking.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

const BASE_URL = environment.apiBaseURL; 
const PAYMENT_API_BASE_URL = environment.paymentApiBaseURL;
const LOCATION_ID = environment.nutrition_location_id;

@Injectable({
  providedIn: 'root'
})
export class NutritionService {

  constructor( public ngxUiLoaderService: NgxUiLoaderService,private http:HttpClient, private authService:AuthService, private sharedService:SharedService, private bookingService:BookingService) { }

  // locationList$: BehaviorSubject<any> = new BehaviorSubject([]);
  clientCart$: BehaviorSubject<any> = new BehaviorSubject(null);
  checkoutBookingResponse$: BehaviorSubject<any> = new BehaviorSubject(null);

  mobileCartView:BehaviorSubject<boolean> = new BehaviorSubject(false);

  // getLocations() {
  //   return this.http.get(BASE_URL+'/get_locations');
  // }

  createCart(){
    const cartId = localStorage.getItem('nutrition_cartId');
    if(!cartId){
      this.bookingService.createCart(LOCATION_ID).subscribe((res:any)=>{
        if(!res.errors){
          localStorage.setItem('nutrition_cartId', res.data.createCart.cart.id);
          this.getCartDetail();
        }else{
          this.sharedService.showNotification("Error", res.errors[0].message);
        }
      });
    }else{
      if(!this.clientCart$.value){
        this.getCartDetail();
      }
    }
  }

  getCartDetail(){
    const cartId = localStorage.getItem('nutrition_cartId');
    if(cartId){
      this.cartDetail().subscribe((res:any)=>{
        if(!res.errors){
          if(res.data.cart.location.id != environment.nutrition_location_id){
            localStorage.removeItem('nutrition_cartId');
            this.createCart();
          }
          this.clientCart$.next(res.data.cart);
        }else{
          this.sharedService.showNotification("Error", res.errors[0].message);
        }
      });
    }
  }

  cartDetail(){
    const payload = {
      cartID: localStorage.getItem('nutrition_cartId'),
      clientId: this.authService.$AuthUser.value?.authId
    }; 
    return this.http.post(BASE_URL+'/get_cart_detail',payload);
  }

  // createGuest(){
  //   const guest = {
  //     "firstName":"",
  //     "lastName":"",
  //     "mobileNumber":"",
  //     "email":"guest@silvermirror.com"
  //     }
  //   const payload = {
  //     cartID: this.sharedService.getLocalStorageItem('cartId'),
  //     client: guest,
  //     client_id: this.authService.$AuthUser.value?.authId
  //   };
  //   return this.http.post(BASE_URL+'/create_cart_guest',payload);
  // }

  // removeGuest(guestId:string){
  //   let payload = {
  //     cartId:this.sharedService.getLocalStorageItem('cartId'),
  //     guestId: guestId,
  //     clientId:this.authService.$AuthUser.value?.authId
  //   };
  //   return this.http.post(BASE_URL+'/remove_cart_guest',payload);
  // }

  addItemInCart(item:any){
    const payload = {
      "cartId": this.sharedService.getLocalStorageItem('nutrition_cartId'),
      "itemId": item.id
    }
    return this.http.post(BASE_URL+'/add_item_in_cart',payload);
  }

  // addProductToCart(item:any){
  //   const payload = {
  //     "cartId": item.id || this.sharedService.getLocalStorageItem('cartId'),
  //     "itemId": item.itemId,
  //     "clientId": this.authService.$AuthUser.value?.authId,
  //     "itemDiscountCode": item.itemDiscountCode
  //   }
  //   return this.http.post(BASE_URL+'/add_product_to_cart',payload);
  // }

  // addAddonInCart(item:any){
  //   const payload = {
  //     "cartId": this.sharedService.getLocalStorageItem('cartId'),
  //     "itemGuestId":item.guestId,
  //     "itemId": item.id,
  //     "itemOptionIds": item.optionIds
  //   }
  //   return this.http.post(BASE_URL+'/add_service_options_in_cart',payload);
  // }

  removeItemInCart(itemId:string){
    const payload = {
      "cartId":this.sharedService.getLocalStorageItem('nutrition_cartId'),
      "itemId": itemId
    }
    return this.http.post(BASE_URL+'/remove_item_in_cart',payload);
  }

  getScheduleDates(locationId:string, lowerRange:string, upperRange:string, staffIDs:Array<string>){
    const payload = {
      "cartID":this.sharedService.getLocalStorageItem('nutrition_cartId'),
      "locationID":locationId,
      "timeZone":"America/New_York",
      "searchRangeLower":lowerRange,
      "searchRangeUpper":upperRange,
      "staffVariantIds":staffIDs,
      "clientId": this.authService.$AuthUser.value?.authId
    }
    return this.http.post(BASE_URL + '/get_cart_bookable_dates', payload);
  }

  getScheduleTimes(date:string, staffVariantIds:Array<any>){
    const payload = {
      "cartID":this.sharedService.getLocalStorageItem('nutrition_cartId'),
      "searchDate":date,
      "timeZone":"America/New_York",
      "clientId": this.authService.$AuthUser.value?.authId,
      "staffVariantIds": staffVariantIds
    }
    return this.http.post(BASE_URL + '/get_cart_bookable_times', payload);
  }

  reserveCartItems(bookableTimeId:string){
    const payload = {
      "cartId":this.sharedService.getLocalStorageItem('nutrition_cartId'),
      "bookableTimeId":bookableTimeId,
      "clientId": this.authService.$AuthUser.value?.authId
    }
    return this.http.post(BASE_URL + '/reserve_cart_bookable_items', payload);
  }

  getStaffList(){
    const payload = {
      "locationID": LOCATION_ID,
    }
    return this.http.post(BASE_URL + '/get_nutrition_staff', payload);
  }
  
  getCartStaffVarients(bookableTimeId:string, serviceId:string, locationId:string){
    const payload = {
      "cartId":this.sharedService.getLocalStorageItem('nutrition_cartId'),
      "bookableTimeId":bookableTimeId,
      "serviceId":serviceId,
      "locationId":locationId,
      "clientId": this.authService.$AuthUser.value?.authId
    }
    return this.http.post(BASE_URL + '/get_cart_staff_variants', payload);
  }

  updateItemInCart(itemId:any, staffId:string | null){
    const payload = {
      cartId:this.sharedService.getLocalStorageItem('nutrition_cartId'),
      itemId: itemId,
      clientId: this.authService.$AuthUser.value?.authId,
      itemStaffVariantId: staffId
    };
    return this.http.post(BASE_URL+'/update_item_in_cart',payload);
  }

  tokenizeCard(card:any){
    const tokenize_url = PAYMENT_API_BASE_URL + "/cards/tokenize";
    const payload = {
      "card": {
        "name": card.name,
        "number": card.number,
        "cvv": card.cvv,
        "exp_month": card.expiry.substring(0,2),
        "exp_year": card.expiry.substring(3,7),
        "address_postal_code": card.postal_code
      }
    }
    return this.http.post(tokenize_url,payload);
  }

  addCartPaymentMethod(token: string, cartId?: string){
    const payload = {
      "cartId": cartId || this.sharedService.getLocalStorageItem('nutrition_cartId'),
      "select":true,
      "token":token
    }
    return this.http.post(BASE_URL+ '/add_cart_card_payment_method',payload);
  }

  updateClientCartInfo(client: any, cartId?: string){
    const payload = {
      "cartId": cartId || this.sharedService.getLocalStorageItem('nutrition_cartId'),
      "clientInfo":{
        "email": client.email,
        "firstName":client.firstName,
        "lastName":client.lastName,
        "phoneNumber":client.mobilePhone
      },
      "clientNote":client.note
    }
    return this.http.post(BASE_URL+ '/update_cart_client_info',payload);
  }

  addCartOffer(offerCode:string, cartId?: string){
    const payload = {
      "cartId": cartId || this.sharedService.getLocalStorageItem('nutrition_cartId'),
      "offerCode":offerCode
    }
    return this.http.post(BASE_URL+ '/add_cart_offer',payload);
  }

  removeCartOffer(offerId:string){
    const payload = {
      "cartId": this.sharedService.getLocalStorageItem('nutrition_cartId'),
      "offerId":offerId
    }
    return this.http.post(BASE_URL+ '/remove_cart_offer',payload);
  }

  checkoutCart(cartId?: string){
    const payload = {
      "cartId": cartId || this.sharedService.getLocalStorageItem('nutrition_cartId'),
      "clientId": this.authService.$AuthUser.value?.authId
    }
    return this.http.post(BASE_URL+ '/checkout_cart',payload);
  }

  takeCartOwnership(){
    const payload = {
      "cartId": this.sharedService.getLocalStorageItem('nutrition_cartId'),
      "clientId": this.authService.$AuthUser.value?.authId
    }
    return this.http.post(BASE_URL+ '/take_cart_ownership',payload);
  }

  selectPaymentMethod(paymentMethodId:string){
    const payload = {
      "cartId":this.sharedService.getLocalStorageItem('nutrition_cartId'),
      "paymentMethodId":paymentMethodId,
      "clientId": this.authService.$AuthUser.value?.authId
    }
    return this.http.post(BASE_URL+ '/select_cart_payment_method',payload);
  }

  getAppointmentDetail(aptId:string, cartId:string)
  {
    const payload = { 
      "cartId": cartId,
      "appointmentId": aptId,
      "clientId": this.authService.$AuthUser.value?.authId
    }
    return this.http.post(BASE_URL+ '/appointment_detail',payload);
  }

  toggleMobileCart(){
    const mobileCartView = this.mobileCartView.value;
    this.mobileCartView.next(!mobileCartView);
  }

  referralPurchase(invoice_amount:number, firstName?:string, lastName?:string, email?:string){
    const payload = {
      "first_name": firstName,
      "last_name": lastName,
      "email": email,
      "invoice_amount": invoice_amount,
      "user_agent": "Silvermirror (https://booking.silvermirror.com)"
    }
    return this.http.post(BASE_URL+ '/purchase',payload);
  }

  invite(email:string){
    const payload = {
      "email": email
    }
    return this.http.post(BASE_URL+ '/invite',payload);
  }

  addAppointmentTag(data:{cartId:string, aptId:string}){
    const payload = {
      "cartId": data.cartId,
      "aptId": data.aptId,
      "tagIds":[environment.nutrition_tag_id]
    }
    return this.http.post(BASE_URL+ '/appointment_add_tags',payload);
  }
  getClientByEmail(email: string) {
    return this.http.post(BASE_URL + '/get_client_by_email', { emails: [email] });
  }
}
 