import { ApplicationRef, Injectable, NgZone } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { SharedService } from './shared-component/shared.service';
import { DisadleContinueService } from './disadle-continue.service';

export interface Notification{
  title:string,
  message:string
}


@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  
  public $notification:BehaviorSubject<Notification> = new BehaviorSubject<Notification>({title:'',message:''});

  constructor(private toastrService: ToastrService, private disableContinue:DisadleContinueService
  ) {
    
    this.$notification.subscribe((notification:Notification)=>{
      notification.title ? this.sendNotification(notification.title, notification.message) : null;
    })
   }
  
  sendNotification(title:string, message:string){
    const toast = this.toastrService.show('', '', {
      easing:'ease-in',
      easeTime:300,
    })
    toast.toastRef.componentInstance.title = title;
    toast.toastRef.componentInstance.message = message;
    toast.toastRef.componentInstance.toastId = toast.toastId; 
    setTimeout(() => {
      //console.log("Timeout called, setting disableContinue to false");
      this.toastrService.remove(toast.toastId);
      this.disableContinue.setDisableContinue(false); 
    }, 10000);
  }
}
