<app-header mainTitle="Book an Appointment"></app-header>
<main id="main">
<section class="schedule-flow">
	<div class="container grid">
        <!--Breadcrumbs-->
        <app-breadcrumbs class="breadcrumbs-container"></app-breadcrumbs>
        <app-mobile-menu class="user-head mobile-head"></app-mobile-menu>
        <div class="content-container">



            <ul class="schedule-filters">
	
                <!--Location-->
                
                <!-- <li class="location-filte"> -->
                    <!-- <button class="filter-button">Location <span class="counter">1</span></button> -->
                    
                    <!-- <div class="filter-pop">
                        <h3 class="flex-container eyebrow small">
                            <span class="mobile-only">Filter </span>Location 
                            <button class="close-filter"><span class="accessibility">Close filter</span></button>
                        </h3>
                        
                        <ul class="tabs">
                            <li><button class="pill active">NYC</button></li>
                            <li><button class="pill">Miami</button></li>
                            <li><button class="pill">DC</button></li>
                        </ul>
                        
                        <ul class="x-small">
                            <li>
                                <input checked name="location" id="location-flatiron" type="radio"/>
                                <label for="location-flatiron"><strong>Flatiron</strong> | 20 West 22nd Street</label>
                            </li>
                            
                            <li>
                                <input name="location" id="location-upper-east" type="radio"/>
                                <label for="location-upper-east"><strong>Upper East Side</strong> | 862 Lexington Avenue</label>
                            </li>
                            
                            <li>
                                <input name="location" id="location-bryant" type="radio"/>
                                <label for="location-bryant"><strong>Bryant Park</strong> | 431 5th Avenue</label>
                            </li>
                            
                            <li>
                                <input name="location" id="location-man-west" type="radio"/>
                                <label for="location-man-west"><strong>Manhattan West</strong> | 431 5th Avenue</label>
                            </li>
                            
                            <li>
                                <input name="location" id="location-brooklyn" type="radio"/>
                                <label for="location-brooklyn"><strong>Brooklyn Heights</strong> | 703 6th Avenue</label>
                            </li>
                        </ul>
            
                        <button class="btn black submit-btn mobile-only">Apply</button>
                    </div> -->
                <!-- </li> -->
                
                <!--Time-->
                
                <li class="time-filter">
                    <button class="filter-button" (click)="toggleTimeFilter = !toggleTimeFilter; toggleStaffFilter = false">Time <span class="counter">0</span></button>
                    <div class="popup-screen" [ngStyle]="{'display':toggleTimeFilter ? 'block' : 'none'}"></div>
                    <div class="filter-pop" [ngStyle]="{'display':toggleTimeFilter ? 'block' : 'none'}">
                        <h3 class="flex-container eyebrow small">
                            <span class="mobile-only">Filter </span>Time 
                            <button class="close-filter" (click)="toggleTimeFilter = !toggleTimeFilter"><span class="accessibility">Close filter</span></button>
                      
                        </h3>
                        
                        <div class="range-slider" *ngIf="toggleTimeFilter">
                            <ngx-slider (userChangeEnd)="changeTimeRange($event)" [(value)]="minValue" [(highValue)]="maxValue" [options]="options"></ngx-slider>
                            <!--Note: input[type=range] did would not work here, so assuming this'll be 100% JS controlled-->
                            
                            <!-- <span class="range-bar">
                                <span class="progress"></span>
                            </span>
                            
                            <span class="time active start-time">9AM</span>
                            <span class="time mid-time">12PM</span>
                            <span class="time active chosen-end-time">5PM</span>
                            <span class="time end-time">8PM</span> -->
            
                            <button class="text-link clear-time-range mobile-only" (click)="clearTimeFilter()">Clear</button>
                        </div>
            
                        <button class="btn black submit-btn mobile-only" (click)="closeMobileFilter()">Apply</button>
                    </div>
                </li>
                <li class="ng-autocomplete esthetician-filter" *ngIf="staffList?.value?.length && !cart?.guests?.length">
                    <ng-autocomplete
                        [data]="staffData"
                        searchKeyword="firstName"
                        placeholder="Dietician"
                        (selected)='selectEvent($event)'
                        (inputChanged)='onChangeSearch($event)'
                        (inputCleared)="clearStaffFilter(false)"
                        [itemTemplate]="staffTemplate"
                        [notFoundTemplate]="notFoundTemplate">
                    </ng-autocomplete>
                    
                    <ng-template #staffTemplate let-item>
                        <a [innerHTML]="item.firstName+ ' ' +item.lastName[0]+'.'"></a>
                    </ng-template>
                    
                    <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                    </ng-template>
                </li>
                <li *ngIf="getSelectedStaff()?.firstName">
                    <label class="selected-esthetician">{{getSelectedStaff()?.firstName+ ' ' +getSelectedStaff()?.lastName[0]+'.'}}</label>
                </li>
                <li *ngIf="!getSelectedStaff()?.firstName">
                    <label class="selected-esthetician"><span *ngIf="cart?.guests?.length">Dietician : </span>Any</label>
                </li>
            </ul>

            <!-- <ul class="schedule-filters" *ngIf="!cart?.guests?.length">
                 Esthetician

                <li class="esthetician-filter">
                    <button class="filter-button" (click)="toggleStaffFilter = !toggleStaffFilter; toggleTimeFilter = false">Please select an esthetician. <span class="counter">0</span></button>
                    <div class="popup-screen" [ngStyle]="{'display':toggleStaffFilter ? 'block' : 'none'}"></div>
                    <div class="filter-pop" [ngStyle]="{'display':toggleStaffFilter ? 'block' : 'none'}">
                        <h3 class="flex-container eyebrow small">
                            <span class="mobile-only">Filter </span>
                            <div class="esthetician-title">Esthetician 
                            </div>
                            <button class="close-filter" (click)="toggleStaffFilter = !toggleStaffFilter"><span class="accessibility">Close filter</span></button>
                        </h3>
                        
                        <ul [ngClass]="staffList.value?.length ? 'grid' : '' ">
                            <p class="xx-small info" *ngIf="!staffList.value?.length">Please choose the available date & time to get estheticians.</p>
                            <li>
                                <input name="esthetician-dropdown" [checked]="false" (change)="filterStaff($event, null)" id='flt-any' class="accessibility" type="radio"/>
                                <label for='flt-any'>Any</label>
                            </li>
                            <li *ngFor="let staff of staffList | async | orderEsthetician">
                                <input name="esthetician-dropdown" [checked]="staff.node.filter" (change)="filterStaff($event, staff)" [id]="'flt'+staff?.node.id" class="accessibility" type="radio"/>
                                <label [for]="'flt'+staff?.node?.id">{{staff?.node?.firstName}}</label>
                            </li>
                        </ul>
                    </div>
                </li>
                <li *ngIf="getSelectedStaff()?.firstName">
                    <label class="selected-esthetician">{{getSelectedStaff()?.firstName}}</label>
                </li>
            </ul> -->

            <!-- <div class="time-slots available-staff" *ngIf="!cart?.guests?.length">

                <div class="time-slot-container" *ngIf="staffList.value?.length">
                    <h2 class="heading-6 small">Esthetician Preference</h2>
                    <fieldset class="flex-container">
                        <span style="display: flex;">
                            <input (change)="filterStaff(null)" id="any" checked="true" class="accessibility" name="staff-chooser" type="radio"/>
                            <label for="any">Any</label>
                        </span>
                        <span style="display: flex;" *ngFor="let staff of staffList | async | orderEsthetician">
                            <input (change)="filterStaff(staff)" [id]="staff?.node?.id" [(checked)]="staff.node.filter" class="accessibility" name="staff-chooser" type="radio"/>
                            <label [for]="staff?.node?.id">{{staff?.node?.firstName}}</label>
                        </span>
                    </fieldset>
                </div>
            </div> -->


            <!-- <ul class="guest-tabs">
               
                <li class="active" *ngFor="let guest of silverService.selectedTabWithServices | keyvalue index as i">
                    <button class="pill">{{guest.key}} </button></li>
               
                
            </ul> -->

            <p *ngIf="!availableTimes.value?.length" class="x-small no-availability">Please select an appointment date.</p>
            <app-calendar [selectedDate]="selectedDate" [availableDates] = "availableDates | async" (changeMonthEvent)="monthChange($event)" (dateSelectEvent)="selectDate($event)"></app-calendar>


            <!-- <p class="x-small no-availability" *ngIf="!(availableTimes | async).length">
				If you are not able to see any available date. Please contact our support team.
			</p> -->
            <div class="time-slots">
    
                <div class="time-slot-container" *ngIf="(availableTimes | async).length">
                    <h2 class="heading-6 small">Available Time</h2>
                    <p style="position: sticky; left:0;" *ngIf="!staffVarients.value?.length" class="xx-small info">Please select an appointment time.</p>
                    <fieldset class="flex-container">
                        <span style="display: flex;" *ngFor="let time of availableTimes | async | timeFilter:{min:minValue, max:maxValue}">
                            <input (change)="selectTime(time)" [id]="time?.id" [checked]="time.selected" class="accessibility" name="time-chooser" type="radio"/>
                            <label [for]="time?.id">{{sharedService.removeLastTimezone(time?.startTime) | date:'hh:mm a'}}</label>
                        </span>
                    </fieldset>
                </div>
            </div>



            <div class="time-slots available-staff" *ngIf="cart?.guests?.length">

                <div class="time-slot-container" *ngIf="(staffVarients | async).length">
                    <h2 class="heading-6 small">Dietician Preference</h2>

                    <fieldset class="flex-container esthetician-pref" name="esthetician-pref" ngDefaultControl (change)="changeEstheticianPreference()" [(ngModel)]="estheticianPref">
                        <span style="display: inline-block;">
                            <input id="any" [checked]="estheticianPref == 1" class="" name="esthetician-pref" value="1" type="radio"/>
                            <label for="any">Any dietician</label>
                        </span>
                        <span style="display: inline-block;">
                            <input id="my-esthetician" [checked]="estheticianPref == 2" class="" name="esthetician-pref" value="2" type="radio"/>
                            <label for="my-esthetician">I want to select my dietician</label>
                        </span>
                    </fieldset>
                    
                    <fieldset class="flex-container" *ngIf="estheticianPref == 2">
                        <span style="display: flex;" *ngFor="let staff of staffVarients | async | orderEsthetician">
                            <input *ngIf="!staff.filter" (change)="selectStaff(staff)" [id]="staff?.id" [(checked)]="staff.selected" class="accessibility" name="staff-chooser" type="radio"/>
                            <label *ngIf="!staff.filter" [for]="staff?.id">{{staff?.staff?.firstName}}</label>
                        </span>
                    </fieldset>
                </div>
            </div>

            <!-- location data added-->
            <div class="nutrition-location">
                <div class="grid">
                    <p>
                        <b>IMPORTANT:</b> All of our Registered Dietitians can perform consultations for guests located in any of the following states:
                    </p>
    
                    <ul class="location-list">
                        <li>Arizona (AZ)</li>
                        <li>California (CA)</li>
                        <li>Colorado (CO)</li>
                        <li>Connecticut (CT)</li>
                        <li>Massachusetts (MA)</li>
                        <li>Michigan (MI)</li>
                        <li>New Jersey (NJ)</li>
                        <li>New York (NY)</li>
                        <li>North Carolina (NC)</li>
                        <li>Pennsylvania (PA)</li>
                        <li>Virginia (VA)</li>
                        <li>Washington (WA)</li>
                    </ul>
    
                    <div class="additional-location">
                        <p><b>The following Dietitians have the ability to perform consultations in these additional jurisdictions:</b></p>
    
                        <ul class="additional-location-list">
                            <li><b>Florida (FL):</b> Catherine, Cayla, Donna</li>
                            <li><b>Illinois (IL):</b> Donna, Lindsey</li>
                        </ul>
                    </div>

                </div>
            </div>
            <!-- location data end-->

            <div class="continue-btn mobile-only">
                <a class="btn" (click)="reserveCart()">Continue</a>
            </div>








            </div>


            <aside [ngClass] = "!cart?.selectedItems?.length ? 'empty':'' ">
                <app-cart [cart]="cart" ></app-cart>
                <a class="btn desktop-only" (click)="reserveCart()">Continue</a>
           </aside>




        </div>
        </section>
    </main>
    <app-footer></app-footer>