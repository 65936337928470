import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SharedService } from 'src/app/shared-component/shared.service';
// import { BookingService } from '../booking.service';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { NutritionService } from '../nutrition.service';
// import { TrackingService } from '../tracking.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent {

  @Input() cart:any;
  // @Output() onItemRemoveEvent = new EventEmitter<string>();
  toggleMobileCart:boolean = false;

  constructor(/* private trackingService:TrackingService, */ public nutritionService:NutritionService, public sharedService:SharedService, public router: Router) {
    // this.isSameService = this.sharedService.getLocalStorageItem("isSameService");
  }

  removeItem(item:any){
    // if(isSameService == 'true' || !isSameService){
      // this.onItemRemoveEvent.emit();
      this.nutritionService.removeItemInCart(item.id).subscribe((res:any)=>{
        if(!res.errors){
          // item.item.listPrice = item.price;
          // item.item.category = this.sharedService.getServiceCategoryName(item, this.cart.availableCategories) == '' ? 'addon' : this.sharedService.getServiceCategoryName(item, this.cart.availableCategories);
          // this.trackingService.removeItem(item.item);
          const title = 'Success';
          const message = 'Service removed successfully';
         // this.sharedService.showNotification(title, message);
          this.nutritionService.getCartDetail();
        }else{
          this.sharedService.showNotification('Errors', res.errors[0].message);
        }
      });
    // }else{
    //   const removeServiceRequests = this.cart.selectedItems.filter((selectedItem:any)=> selectedItem.item.id == item.item.id).map((service:any)=>{
    //     item.item.listPrice = item.price;
    //     item.item.category = this.sharedService.getServiceCategoryName(item, this.cart.availableCategories) == '' ? 'addon' : this.sharedService.getServiceCategoryName(item, this.cart.availableCategories);
    //     this.trackingService.removeItem(item.item);
    //     return this.bookingService.removeItemInCart(service.id);
    //   });
    //   forkJoin(removeServiceRequests).subscribe(res=>{
    //     this.sharedService.setLocalStorageItem('isSameService', 'true');
    //     this.onItemRemoveEvent.emit();
    //     const title = 'Success';
    //     const message = 'Service removed successfully';
    //     this.sharedService.showNotification(title, message);
    //     this.bookingService.updateCartDetail();
    //   })
    //   // const title = 'Please enable my guest can have different services.';
    //   // const message = 'Then you will be able to add/remove services.';
    //   // this.sharedService.showNotification(title, message);
    // }
  }

  // removeModifier(modifier:any, guestId:string){
  //   let isSameService = this.sharedService.getLocalStorageItem("isSameService");
  //   if(isSameService == 'true' || !isSameService){
  //     let selectedItems:any = this.cart.selectedItems.filter((selectedItem:any)=>{
  //       return selectedItem.guestId == guestId;
  //     })
  //     let optionIds:Array<string | null> = this.getSelectedModifiers(selectedItems);
  //     let index = optionIds.indexOf(modifier.id);
  //     optionIds.splice(index,1);
  
  //     const payload = {
  //       id: selectedItems[0].id,
  //       optionIds: [...optionIds],
  //       staffId:null,
  //       guestId:guestId
  //     }
  //     this.bookingService.addAddonInCart(payload).subscribe((res:any)=>{
  //       if(!res.errors){
  //         const title = 'Add-on removed';
  //         const message = 'REMOVED FROM CART';
  //         this.sharedService.showNotification(title, message);
  //         this.bookingService.updateCartDetail();
  //       }else{
  //         this.sharedService.showNotification('Errors', res.errors[0].message);
  //       }
  //     });
  //   }else{
  //     const title = 'Please enable my guest can have different services.';
  //     const message = 'Then you will be able to add/remove services.';
  //     this.sharedService.showNotification(title, message);
  //   }
  // }

  // getSelectedModifiers(selectedItems:any):Array<string | null>{
  //   if(selectedItems[0].selectedOptions.length){
  //     let ids = selectedItems[0].selectedOptions.map((option:any)=> option.id);
  //     return ids;
  //   }else{
  //     return []
  //   }
  // }

  get getTotalAddedServiceCount(){
    let count = 0;
    if(this.cart && this.cart.selectedItems){
      let selectedItems = this.cart.selectedItems;
      if(selectedItems && selectedItems.length){
        selectedItems.map((selectedItem:any)=>{
          ++count;
          count = count + selectedItem.selectedOptions.length;
        })
      }
    }
    return count;
  }

  getServicePrice(selectedService:any){
    let optionsPrice = 0;
    selectedService.selectedOptions.map((option:any)=>{
      optionsPrice = optionsPrice + option.priceDelta;
    });
    return selectedService.price - optionsPrice;
  }

  getSelectedStaffVariant(){
    let selectedStaff:any;
    if(this.cart && this.cart.selectedItems && this.cart.selectedItems.length){
      if(this.cart.selectedItems[0].selectedStaffVariant){
        selectedStaff = this.cart.selectedItems[0].selectedStaffVariant.staff.firstName+' '+this.cart.selectedItems[0].selectedStaffVariant.staff.lastName[0]+'.';
      }
    }
    return selectedStaff;
  }

  editLocation(){
    this.router.navigateByUrl("/nutrition", {replaceUrl: true});
  }
  

}
 