import { Component } from '@angular/core';
import { PackageService } from '../package.service';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared-component/shared.service';
import { PDF_INTERFACE, PdfService } from '../pdf.service';
import { Subscription, firstValueFrom } from 'rxjs';
import { TrackingService } from '../tracking.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-thanks',
  templateUrl: './thanks.component.html',
  styleUrls: ['./thanks.component.scss']
})
export class ThanksComponent {

  cart:any;
  isCustomAmountSelected:any;
  isGiftcardPurchase:boolean = false;
  giftcardCode:any;
  cartSubscription!:Subscription;
  packageDescription:any;
  packageRecipient:any;
  constructor(private titleService: Title,public packageService:PackageService, private router:Router, private trackingService:TrackingService, public sharedService:SharedService, private pdfService:PdfService){
  this.titleService.setTitle('Packages - Silver Mirror');
  let giftVoucherBuyer :any= localStorage.getItem('packageRecepient');
  this.packageRecipient=JSON.parse(giftVoucherBuyer);
  //console.log("this.packageRecipient",this.packageRecipient)
    this.packageDescription=this.packageService.getTextAfterShortdesc(this.sharedService.getPackagepackageDescription());
    if(!this.cart){
      packageService.getCartDetail();
      //console.log("clientCart$",packageService.clientCart$.value);
      this.cartSubscription = packageService.clientCart$.subscribe((cart:any)=>{
        this.cart = cart;
        if(this.cart){
          const giftId = this.cart?.selectedItems[0]?.id
          if(this.cart.selectedItems.length && this.cart.selectedItems[0].item.id == 'GIFT_CARD'){
            this.isCustomAmountSelected = true;
            this.getGiftcardCode();
          }else{
            let recipient:any = localStorage.getItem('packageRecepient');
            if(recipient){
              recipient = JSON.parse(recipient);
              recipient.messageFromSender = recipient.messageFromSender;
              recipient.deliveryDate = recipient.deliveryDate;
              recipient.unitCost = recipient.unitCost;
              this.cart.selectedItems[0].emailFulfillment = recipient;
            }
            !this.isGiftcardPurchase ? this.giftcardPurchase() : null;
          }
        }else{
          router.navigateByUrl('/packages', {replaceUrl: true});
        } 
      })
    }
  }
 
  injectGTAG(giftId:string){
    this.trackingService.setUserDetail();
    this.trackingService.purchase();

    (window as any).gtag('event', 'conversion', {
      'send_to': 'AW-1056511307/uq4qCNDxjpECEMuq5PcD',
      'value': Number(this.sharedService.formatPrice(this.cart?.summary?.total)),
      'currency': 'USD',
      'transaction_id': giftId
      });
  }

  clearCart(){
    this.packageService.clientCart$.next(null);
    localStorage.removeItem('packageRecepient');
    localStorage.removeItem('promoCode');
    localStorage.removeItem('packageCart');
    localStorage.removeItem('packageDetail');
    this.isGiftcardPurchase = false;
    this.cartSubscription.unsubscribe();
    this.router.navigateByUrl('/packages', {replaceUrl: true})
  }
  clearCartBooking(){
    this.packageService.clientCart$.next(null);
    localStorage.removeItem('packageRecepient');
    localStorage.removeItem('promoCode');
    localStorage.removeItem('packageCart');
    localStorage.removeItem('packageDetail');
    this.isGiftcardPurchase = false;
    this.cartSubscription.unsubscribe();
    this.router.navigateByUrl('/booking', {replaceUrl: true});
  }

 async downloadPDF(){
    const amount = !this.isCustomAmountSelected ? (this.cart?.selectedItems[0].emailFulfillment?.unitCost/100).toFixed(2) : (this.cart?.summary?.subtotal/100).toFixed(2)
    //console.log("packageDescription",this.packageDescription);
    const param:PDF_INTERFACE = {
      productName: this.sharedService.getPackageName(),
      amount: amount?amount:"",
      buyerName:this.packageRecipient.senderName.toUpperCase(),
      buyerEmail:this.packageRecipient.senderEmail.toUpperCase(),
      services:[],
      description:this.packageDescription.replace(/<\/?[^>]+>/gi, ''),
      download:true
    }
    //console.log("Param",param);

   await this.pdfService.createPDF(param,true);
   //this.pdfService.sendPDFEmail(param);

    const title = 'Silver Mirror Package has been successfully downloaded!';
    const message = '';
   // this.sharedService.showNotification(title, message);
  }

  async giftcardPurchase(){
    const resClientByEmail: any = await firstValueFrom(this.packageService.getClientByEmail(this.cart.clientInformation.email));
    const payload = {
      "amount": this.cart?.selectedItems[0].emailFulfillment?.unitCost,
      "giftcardName": this.cart?.selectedItems[0].item.name,
      "note": "",
      "deliveryDate": this.cart?.selectedItems[0]?.emailFulfillment?.deliveryDate,
      "messageFromSender": this.cart?.selectedItems[0].emailFulfillment?.messageFromSender ? this.cart?.selectedItems[0].emailFulfillment?.messageFromSender : '',
      "recipientEmail": this.cart?.selectedItems[0]?.emailFulfillment?.recipientEmail,
      "recipientName": this.cart?.selectedItems[0].emailFulfillment?.recipientName,
      "senderName": this.cart?.selectedItems[0].emailFulfillment?.senderName,
      "clientId": resClientByEmail.data.clients.edges[0].node.id
    }

    const resProcessGiftcard: any = await firstValueFrom(this.packageService.processGiftcard(payload));
    if(!resProcessGiftcard.errors){
      this.giftcardCode = resProcessGiftcard.giftcard.data.giftCard.code;
      this.injectGTAG(resProcessGiftcard.giftcard.data.giftCard.id);
      //Added for package [START]
      const resClientByEmail: any = await firstValueFrom(this.packageService.getClientByEmail(this.cart.clientInformation.email));
      const payload = {
        clientId:resClientByEmail.data.clients.edges[0].node.id,
        locationId: "urn:blvd:Location:704277ae-8958-4ba0-97a3-0e991477add7",
        code: this.giftcardCode,
        
        services:[{"id":"urn:blvd:Service:c51f8888-e89f-4c04-ba9d-a3ab499f2161",
        quantity:1}]
      };
      this.packageService.processGiftvoucher(payload).subscribe((res:any)=>{ 
        //console.log("processGiftvoucher",res);
       })
      //Added for package [END]
    }
    this.isGiftcardPurchase = true;
  }

  async getGiftcardCode(){
    const resGiftcardCode: any = await firstValueFrom(this.packageService.getGiftcardCode(this.cart.id));
    if(!resGiftcardCode.errors){
      this.giftcardCode = resGiftcardCode?.data?.cart?.orders[0]?.lineGroups[0]?.lines[0]?.giftCardCode;
      this.injectGTAG(this.cart.id);
    }
  }

  ngOnDestroy(){
    //this.clearCart();
  }

}
