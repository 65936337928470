<button class="mobile-only cart-summary flex-container" *ngIf="cart?.selectedItems?.length" (click)="toggleMobileCart = !toggleMobileCart" [ngClass]="toggleMobileCart ? 'active' : ''">
    <span class="eyebrow small">Summary</span>
    <!-- <span class="cost">{{sharedService.formatPrice(this.cart?.summary?.total) | currency:'USD':'symbol'}}</span> -->
</button>
<div class="cart-info" [ngClass]="toggleMobileCart ? 'active' : ''">  
    <button (click)="nutritionService.toggleMobileCart()" class="close-cart mobile-only"><span class="accessibility">Close Cart</span></button>
    <h2 class="eyebrow small flex-container"><span>Cart</span><span>{{getTotalAddedServiceCount}} Service{{getTotalAddedServiceCount > 1 ? 's' : ''}} Added</span></h2>

    <span *ngIf="!cart?.selectedItems?.length" class="heading-6 small empty-cart">Your cart is empty</span>
    
    <ul class="small">
        <li *ngIf="cart?.selectedItems?.length">
            <ul class="small" *ngFor="let groupedService of (cart?.selectedItems | orderCartItems); index as index">
                <li class="flex-container user-outer">
                    <span class="user">{{groupedService.guest ? groupedService?.guest?.label : 'Me'}} <span class="counter gray">{{groupedService?.data?.length}}</span> <span *ngIf="getSelectedStaffVariant() && router.url == '/nutrition/review'" class="person">{{getSelectedStaffVariant()}} </span> </span>
                </li>
                <li class="flex-container" *ngFor="let service of groupedService.data" [ngClass]="service?.item?.optionGroups?.length != 0 ? 'add-on' : ''">
                        <span class="item-info">
                            <strong>{{service.item.name}}</strong>
                            <span class="pill fill small" *ngIf="service?.item?.optionGroups?.length !=0">Add On</span>
                            <span *ngIf="sharedService.getServiceCategoryName(service, cart.availableCategories) != '' " class="pill">{{sharedService.getServiceCategoryName(service, cart.availableCategories)}}</span>
                        </span>
                        
                        <span class="cost">
                            <!-- {{sharedService.formatPrice(getServicePrice(service)) | currency:'USD':'symbol' : '1.0-0'}} -->
                            <span class="remove-service-outer">
                                <button *ngIf="router.url == '/nutrition/services'" (click)="removeItem(service)" class="remove-service"><span class="accessibility">Remove Service</span></button>
                            </span>
                        </span>
    
                    <!-- <li class="flex-container add-on" *ngFor="let modifier of service.selectedOptions">
                        <span class="item-info">
                            <strong>{{modifier?.name}}</strong>
                            <span class="pill fill small">Add On</span>
                        </span>
                        
                        <span class="cost">
                            {{sharedService.formatPrice(modifier.priceDelta) | currency:'USD':'symbol' : '1.0-0'}}
                            <button *ngIf="router.url == '/booking/services'" class="remove-service" (click)="removeModifier(modifier, service.guestId)"><span class="accessibility">Remove Add-on</span></button>
                        </span>
                    </li> -->
                    <!-- <li class="add-on-item" *ngIf="cart?.selectedItems?.length">
                        <ul class="small">
                            <li class="flex-container add-on" *ngFor="let modifier of cart?.selectedItems[0]?.selectedOptions">
                                <span class="item-info">
                                    <strong>{{modifier?.name}}</strong>
                                    <span class="pill fill small">Add On</span>
                                </span>
                                
                                <span class="cost">
                                    {{sharedService.formatPrice(modifier.priceDelta) | currency:'USD':'symbol' : '1.0-0'}}
                                    <button class="remove-service" (click)="removeModifier(modifier, 'me')"><span class="accessibility">Remove Addon</span></button>
                                </span>
                            </li>
                        </ul>
                    </li> -->
                
                    <!-- <li *ngIf="cart.guest"><span class="user empty-cart-guest">Guest 1 <span class="counter gray">0</span></span></li> -->
                    <!-- <li><span class="user empty-cart-guest">Guest 2 <span class="counter gray">0</span></span></li> -->

                </li>
            </ul>

            <button class="btn black" routerLink="/nutrition/services" *ngIf="router.url != '/nutrition/services'">Add/Edit Services</button>

        </li>

        <!-- <div class="flex-container cart-total" *ngIf="cart?.selectedItems?.length">
            <span class="eyebrow small">Total</span>
            <p class="small">{{sharedService.formatPrice(this.cart?.summary?.total) | currency:'USD':'symbol' : '1.0-0'}}</p>
        </div> -->
        
        <!-- <li class="flex-container" *ngIf="!cart?.startTime || router.url != '/nutrition/review'">
            <span class="pill location">Location</span>
            <span>
                <strong>{{cart?.location?.address?.city}} - {{cart?.location?.name}}</strong>
                <address>{{cart?.location?.address?.line1}}</address>
                <a *ngIf="cart?.location?.phoneNumber" href="tel:{{cart?.location?.phoneNumber}}">{{sharedService.removeCountryCode(cart?.location?.phoneNumber) | mask: '(000) 000-0000'}}</a>
            </span>
            
            <span class="cost">
                <button class="edit-location" (click)="editLocation()"><span class="accessibility">Edit Location</span></button>
            </span>
        </li> -->
    </ul>

    <!-- <div class="flex-container cart-total" *ngIf="cart?.summary?.taxAmount && router.url == '/nutrition/review'">
        <span class="eyebrow small">Tax</span>
        <p class="small">{{sharedService.formatPrice(this.cart?.summary?.taxAmount) | currency:'USD':'symbol'}}</p>
    </div>
    <div class="flex-container cart-total" *ngIf="cart?.offers?.length && this.cart?.summary?.discountAmount && router.url == '/nutrition/review'">
        <span class="eyebrow small">Subtotal</span>
        <p class="small">{{sharedService.formatPrice(this.cart?.summary?.subtotal) | currency:'USD':'symbol'}}</p>
    </div>
    <div class="flex-container cart-total" *ngIf="cart?.offers?.length && this.cart?.summary?.discountAmount && router.url == '/nutrition/review'">
        <span class="eyebrow small">{{cart.offers[0]?.name}}</span>
        <p class="small">-{{sharedService.formatPrice(this.cart?.summary?.discountAmount) | currency:'USD':'symbol'}}</p>
    </div>
    <div class="flex-container cart-total" *ngIf="cart?.selectedItems?.length">
        <span class="eyebrow small">Total</span>
        <p *ngIf="router.url == '/nutrition/review'" class="small">{{sharedService.formatPrice(this.cart?.summary?.total) | currency:'USD':'symbol'}}</p>
        <p *ngIf="router.url != '/nutrition/review'" class="small">{{sharedService.formatPrice(this.cart?.summary?.subtotal) | currency:'USD':'symbol'}}</p>
    </div> -->

    <ul class="xx-small cart-details" *ngIf="cart?.startTime && router.url == '/nutrition/review'">
        <li *ngIf="cart?.startTime && router.url == '/nutrition/review'" class="time">{{cart?.startTime | date:'EEEE, MMMM d @ h:mm a'}}</li>
        <li *ngIf="getSelectedStaffVariant() && router.url == '/nutrition/review'" class="person">Dietician: {{getSelectedStaffVariant()}}</li>
        <!-- <li class="location">{{cart?.location?.address?.city}} - {{cart?.location?.name}}</li> -->
    </ul>
    
</div>
	
<!-- <a class="btn inactive" href="#">Add to Continue</a> -->

<!-- <button class="btn black view-cart mobile-only">View Cart<span class="item-count">{{getTotalAddedServiceCount}}</span></button> -->