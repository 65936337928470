<main id="main">
    <section class="membership-flow membership-detail">
      <div class="container grid">
        <!--Breadcrumbs-->
        <div class="breadcrumbs-container">
          <a class="back-btn" href="#"><span class="accessibility">Back</span></a>
  
          <ul class="flex-container breadcrumbs">
            <li class="active">
              <a class="eyebrow small" href="">Select Membership</a>
            </li>
            <li><a class="eyebrow small">Sign Agreement</a></li>
            <li><a class="eyebrow small">Review Purchase</a></li>
          </ul>
        </div>
        <app-mobile-menu class="user-head mobile-head"></app-mobile-menu>
        <!--Hero-->
        <div class="hero desktop-only">
          <h1 class="heading-2">Silver Mirror Memberships</h1>
          <div class="image-container">
            <img
              src="assets/images/memberships-purchasing-hero.png"
              alt="Three people smiling and holding each other's shoulders."
            />
          </div>
        </div>
        <ul class="accordions">
          <!--Select Your Membership-->
  
          <li class="membership-selections {{ activeTab === 0 ? 'active' : '' }}">
            <h3>
              <button class="flex-container heading-6" (click)="setActiveTab(0)">
                1. Select Your Membership
                <span>
                  <svg
                    width="8px"
                    height="8px"
                    viewBox="0 0 8 8"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <title></title>
                    <g
                      id="📖-Booking-Flow"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        id="Services---Single---Filter---30-Min"
                        transform="translate(-1273.000000, -519.000000)"
                        fill="#404040"
                        fill-rule="nonzero"
                      >
                        <g
                          id="Cart"
                          transform="translate(1009.000000, 247.000000)"
                        >
                          <g
                            id="Location"
                            transform="translate(30.000000, 232.000000)"
                          >
                            <g
                              id="Icon/UI/Location"
                              transform="translate(227.000000, 33.000000)"
                            >
                              <g id="" transform="translate(7.000017, 7.000000)">
                                <path
                                  d="M7.12029522,0.29296875 C6.92498272,0.0976877213 6.66873234,0 6.41404503,0 C6.15810746,0 5.90216988,0.09765625 5.70685738,0.29296875 L0.704826054,5.2953124 C0.570950922,5.4291563 0.477794585,5.59828091 0.436388192,5.78296852 L0.00498193197,7.70171833 C-0.0265806348,7.8453126 0.0962161391,8 0.24920052,8 C0.265559849,8 0.282262978,7.99838448 0.299169239,7.99499702 C0.299169239,7.99499702 1.6212005,7.71452808 2.21791929,7.57421589 C2.39823168,7.5317626 2.55963796,7.44104385 2.69057554,7.31015348 L7.70932573,2.29140329 C8.09979433,1.9009347 8.09838861,1.26921558 7.7079224,0.878746986 L7.12029451,0.29296875 L7.12029522,0.29296875 Z M2.15935701,6.7796874 C2.12631005,6.81273413 2.08899754,6.83379698 2.04516953,6.84410906 C1.76126331,6.91099977 1.30360692,7.01145267 0.909388003,7.09692144 L1.1679818,5.94723415 C1.17838806,5.90099955 1.20157569,5.85889053 1.23510688,5.82531214 L5.01479429,2.04562473 L5.95432561,2.98515606 L2.15935677,6.7796874 L2.15935701,6.7796874 Z"
                                  id="Shape"
                                ></path>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
              </button>
            </h3>
  
            <form>
              <ul class="content">
                <li *ngFor="let membership of memberships">
                  <button
                    class="flex-container membership-selection"
                    [ngClass]="selectedMembership?.node?.id == membership['node']['id'] ? 'active' : ''"
                    (click)="setActiveMembership(membership)"
                  >
                    <article class="description-and-options">
                      <h3 class="heading-6">{{membership['node']['name']}}</h3>
                      <div class="cost-container mobile-only">
                        <span class="cost">${{membership['node']['unitPrice'] / 100}}/month</span>
                        <span *ngIf="membership['node']['originalPrice'] != membership['node']['unitPrice']" class="slashed-cost">${{membership['node']['unitPrice'] / 100}}/month</span>
                      </div>
                      <span class="pill fill" *ngIf="membership['node']['sku']" >Limited to 50 members</span>
                      <p class="xx-small info">
                        {{membership['node']['description']}}
                      </p>
                      <a href="https://silvermirror.com/memberships/" class="text-link">View Detail</a>
                    </article>
  
                    <div class="cost-container desktop-only">
                      <span class="cost">${{membership['node']['unitPrice'] / 100}}/month</span>
                      <span *ngIf="membership['node']['originalPrice'] != membership['node']['unitPrice']" class="slashed-cost">${{membership['node']['originalPrice'] / 100}}/month</span>
                    </div>
                  </button>
                </li>
              </ul>
  
              <a class="btn black" (click)="proceedToOptions()">Confirm</a>
            </form>
          </li>
  
          <!--Membership Options-->
  
          <li class="membership-options {{ activeTab === 1 ? 'active' : '' }}">
            <h3>
              <button class="flex-container heading-6" (click)="setActiveTab(1)">
                2. Membership Options
                <span>
                  <svg
                    width="8px"
                    height="8px"
                    viewBox="0 0 8 8"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <title></title>
                    <g
                      id="📖-Booking-Flow"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        id="Services---Single---Filter---30-Min"
                        transform="translate(-1273.000000, -519.000000)"
                        fill="#404040"
                        fill-rule="nonzero"
                      >
                        <g
                          id="Cart"
                          transform="translate(1009.000000, 247.000000)"
                        >
                          <g
                            id="Location"
                            transform="translate(30.000000, 232.000000)"
                          >
                            <g
                              id="Icon/UI/Location"
                              transform="translate(227.000000, 33.000000)"
                            >
                              <g id="" transform="translate(7.000017, 7.000000)">
                                <path
                                  d="M7.12029522,0.29296875 C6.92498272,0.0976877213 6.66873234,0 6.41404503,0 C6.15810746,0 5.90216988,0.09765625 5.70685738,0.29296875 L0.704826054,5.2953124 C0.570950922,5.4291563 0.477794585,5.59828091 0.436388192,5.78296852 L0.00498193197,7.70171833 C-0.0265806348,7.8453126 0.0962161391,8 0.24920052,8 C0.265559849,8 0.282262978,7.99838448 0.299169239,7.99499702 C0.299169239,7.99499702 1.6212005,7.71452808 2.21791929,7.57421589 C2.39823168,7.5317626 2.55963796,7.44104385 2.69057554,7.31015348 L7.70932573,2.29140329 C8.09979433,1.9009347 8.09838861,1.26921558 7.7079224,0.878746986 L7.12029451,0.29296875 L7.12029522,0.29296875 Z M2.15935701,6.7796874 C2.12631005,6.81273413 2.08899754,6.83379698 2.04516953,6.84410906 C1.76126331,6.91099977 1.30360692,7.01145267 0.909388003,7.09692144 L1.1679818,5.94723415 C1.17838806,5.90099955 1.20157569,5.85889053 1.23510688,5.82531214 L5.01479429,2.04562473 L5.95432561,2.98515606 L2.15935677,6.7796874 L2.15935701,6.7796874 Z"
                                  id="Shape"
                                ></path>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
              </button>
            </h3>
  
            <form>
              <div class="billing-cycle">
                <div class="pill">
                  Select membership start date & billing cycle
                </div>
                <label for="first-of-month">
                  <input
                  checked="checked"
                    type="radio"
                    id="first-of-month"
                    name="billing-cycle"
                    value="1"
                    (input)="setDate(1)"
                  />
                  1st of the month
                </label>
  
                <label for="fifteenth-of-month">
                  <input
                   
                    type="radio"
                    id="fifteenth-of-month"
                    name="billing-cycle"
                    value="15"
                    (input)="setDate(15)"
                  />
                  15th of the month
                </label>
              </div>
  
              <div class="billing-disclaimer">
                <p class="small">
                  You will be billed today and your membership will begin {{nextBilling | date:'MMMM d, YYYY'}}.
                </p>
                <div class="next-billing-date">
                  Next billing date: {{nextBilling | date:'MMMM d, YYYY'}}
                </div>
              </div>
  
              <a class="btn black" (click)="setActiveTab(2)">Confirm</a>
            </form>
          </li>
  
          <!--Member Information-->
  
          <li class="member-info {{ activeTab === 2 ? 'active' : '' }}">
            <h3>
              <button class="flex-container heading-6" (click)="setActiveTab(2)">
                3. Member Information
              </button>
            </h3>
  
            <!-- <p class="xx-small" *ngIf="!authUser">
              Have an account?
              <a class="text-link" href="/auth/login">Login or create an account</a>
            </p> -->
  
            <form [formGroup]="form">
              <div class="one-columns pill">
                <label for="recipient-email">
                  Email address
                  <input (blur)="onEmailKeyup(form.controls.email.value)"  type="email" placeholder="email@address.com"  formControlName="email" 
                  [ngClass]="form.controls.email.invalid && form.controls.email.touched ? 'is-invalid' : ''" />
  
                  <div
                    class="required"
                    *ngIf="form.controls.email.invalid && form.controls.email.touched"
                    class="invalid-feedback"
                  >
                    <div *ngIf="form.controls.email?.errors?.required">
                      Email is required
                    </div>
                    <div *ngIf="form.controls.email.errors?.email">Invalid Email address</div>
                  </div>
                </label>
                <label for="first-name">
                  First Name
                  <input id="first-name" type="text" placeholder="First Name" formControlName="firstName"
                  [ngClass]="form.controls.firstName.invalid && form.controls.firstName.touched ? 'is-invalid' : ''" />
                  <div
                    class="required"
                    *ngIf="form.controls.firstName.invalid && form.controls.firstName.touched"
                    class="invalid-feedback"
                  >
                    <div *ngIf="form.controls.firstName?.errors?.required">
                      First Name is required
                    </div>
                  </div>
                </label>
  
                <label for="last-name">
                  Last Name
                  <input id="last-name" type="text" placeholder="Last Name" formControlName="lastName" 
                  [ngClass]="form.controls.lastName.invalid && form.controls.lastName.touched ? 'is-invalid' : ''" />
                  <div
                    class="required"
                    *ngIf="form.controls.lastName.invalid && form.controls.lastName.touched"
                    class="invalid-feedback"
                  >
                    <div *ngIf="form.controls.lastName?.errors?.required">
                      Last Name is required
                    </div>
                  </div>
                </label>
                <label for="recipient-phone">
                  Phone Number
                  <input type="phone" mask='(000) 000-0000' type="tel" id="phone-number" name="phone-number" placeholder="(555) 555-5555" formControlName="mobilePhone" 
                  [ngClass]="form.controls.mobilePhone.invalid && form.controls.mobilePhone.touched ? 'is-invalid' : ''" />
                  <div
                    class="required"
                    *ngIf="form.controls.mobilePhone.invalid && form.controls.mobilePhone.touched"
                    class="invalid-feedback"
                  >
                    <div *ngIf="form.controls.mobilePhone?.errors?.required">
                      Phone Number is required
                    </div>
                  </div>
                </label>
              </div>
  
              <div class="two-columns pill">
               
  
              
              </div>
  
              <label class="pill" for="home-facial-bar">
                Home Facial Bar Location
                
                <select name="home-facial-bar" id="home-facial-bar" formControlName="location" [(ngModel)]="selectedLocation">
                  <option value="" selected>Select location</option>
                  <option [ngValue]="location.node.id" *ngFor="let location of bookingService.locationList$.value| membershipLocation ">{{location?.node?.name}}</option>
                </select>
                <div
                    class="required"
                    *ngIf="form.controls.location.invalid && form.controls.location.touched"
                    class="invalid-feedback"
                  >
                    <div *ngIf="form.controls.location?.errors?.required">
                      Location is required
                    </div>
                  </div>
              </label>
  
              <a class="btn black" (click)="submitForm()"
                >Continue to agreement</a
              >
            </form>
          </li>
        </ul>
      </div>
    </section>
  </main>