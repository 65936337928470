import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'staffShorting',
})
export class StaffShortingPipe implements PipeTransform {

  transform(value: any[], field: string): any[] {

    if (!value || value.length === 0 || !field) {
      return value;
    }

    return value.sort((a, b) => {
      const aField = this.getField(a, field);
      const bField = this.getField(b, field);

      if (aField < bField) {
        return -1;
      } else if (aField > bField) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  private getField(object: any, field: string) {
    return field.split('.').reduce((o, i) => o[i], object);
  }
}
