import { Component } from '@angular/core';
import { NutritionService } from './nutrition.service';

@Component({
  selector: 'app-nutrition',
  templateUrl: './nutrition.component.html',
  styleUrls: ['./nutrition.component.scss']
})
export class NutritionComponent {
  
  constructor(private nutritionService:NutritionService){
    this._init();
  }

  _init(){
    this.nutritionService.createCart();
  }
}
