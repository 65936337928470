import { Component, HostListener } from '@angular/core';
import { NotificationService } from './notification.service';
import { UserIdleService } from 'angular-user-idle';
import { AuthService } from './auth/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments';
import { Location } from '@angular/common';
import { SharedService } from './shared-component/shared.service';
import { BookingService } from './booking/booking.service';
import { DisadleContinueService } from './disadle-continue.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  
  title = 'silver-mirror-phaseII';

  constructor(private sharedService: SharedService,private location: Location, 
    private notificationService:NotificationService, private userIdle: UserIdleService, 
    private authService:AuthService, private router:Router, private bookingServices:BookingService) {
       
  
     }
    /*  @HostListener('window:beforeunload', ['$event'])
     unloadNotification(event: BeforeUnloadEvent): void {
       this.sharedService.showNotification('Browser refresh will reload the complete application and can take little time', 
        '');
     } */
  
  ngOnInit() {
    
    if (!this.bookingServices.isMobile()) {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          const excludedRoutes = ['/dashboard/appointments', '/dashboard/reschedule', '/auth/register']; // Add routes to exclude
          const currentRoute = event.url;
    
          if (!excludedRoutes.includes(currentRoute)) {
            this.preventBackButton(); // Call this method only if the route is not excluded
          }
        }
      });
    }
    
    this.versionCheck();

    this.authService.$AuthUser.subscribe(user=>{
      if(user){
        // Reset Timer
        this.restart();

        //Start watching for user inactivity.
        this.startWatching();

         // Start watching when user idle is starting.
        this.userIdle.onTimerStart().subscribe();

        this.userIdle.onTimeout().subscribe(count => {
          this.logout();
          this.stop();
          this.stopWatching();
        });
      }else{
        this.stop();
        this.stopWatching();
      }
    })
  }
  preventBackButton() {
    history.pushState(null, '', window.location.href);
    window.onpopstate = () => {
      const title = 'Browser back button not allowed';
      const message = 'Please use application navigation';
      this.sharedService.showNotification(title, message);
      history.pushState(null, '', window.location.href);  // Disable back button
    };
  }
  stop() {
    this.userIdle.stopTimer();
  }

  stopWatching() {
    this.userIdle.stopWatching();
  }

  startWatching() {
    this.userIdle.startWatching();
  }

  restart() {
    this.userIdle.resetTimer();
  }

  logout() {
    localStorage.clear();
    this.authService.$AuthUser.next(null);
    this.router.navigateByUrl("/auth", {replaceUrl: true});
  }

  versionCheck(){
    const localVersion = localStorage.getItem('appVersion');
    if(environment.version != localVersion){
      localStorage.clear();
      localStorage.setItem("appVersion", environment.version);
    }
  }

}
