import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SharedService } from 'src/app/shared-component/shared.service';
import { BookingService } from '../../booking.service';
import { MdbModalRef, MdbModalService } from "mdb-angular-ui-kit/modal";
import { ModalServiceDetailComponent } from './modal-service-detail/modal-service-detail.component';
import { TrackingService } from '../../tracking.service';

@Component({
  selector: 'app-services-list',
  templateUrl: './services-list.component.html',
  styleUrls: ['./services-list.component.scss']
})
export class ServicesListComponent {

  @Input() services:any;
  @Input() client:any;
  @Input() category:string='';
  @Output() addServiceEvent = new EventEmitter<string>();
  @Output() addAddonsEvent = new EventEmitter<string>();

  serviceDetailModalRef!: MdbModalRef<ModalServiceDetailComponent> | null;

  modalConfig: any = {
    animation: true,
    backdrop: true,
    containerClass: "right",
    data: {},
    ignoreBackdropClick: false,
    keyboard: true,
    modalClass: "modal-top-right",
  }; 

  constructor(private trackingService:TrackingService, public sharedService:SharedService, private bookingService:BookingService, private modalService: MdbModalService){}

  addService(service:any){
     // console.log("service",service);
      //const discount = '[{"location":"urn:blvd:Location:79afa932-b486-4fe9-8502-d805a9e48caa","start_time":"13:00","end_time":"16:00","valid_days":["MON","TUE","WED","THU"],"coupon_code":"SD10"},{"location":"456","start_time":"13:00:00","end_time":"16:00:00","valid_days":["MON","WED","FRI"],"coupon_code":"SD20"}]';
      const regex = /SD:(\[\{.*\}\])/;
      const match = service.description.match(regex);
      let extractedJson = [];
      if (match && match[1]) {
        extractedJson = match[1];
       // console.log("extractedJson", extractedJson);
        this.sharedService.setLocalStorageItem('discount', extractedJson)
      }else{
        this.sharedService.removeLocalStorageItem('discount');
      }
      this.addServiceEvent.emit(service); 
  }

  getServiceName(name:string){
    return name.replace('(', '<br/>(');
  }

  serviceDetail(service: any, cat:any) {
    service.category = cat;
    this.modalConfig.data.service = service;
    this.modalConfig.data.client = this.client;
    this.serviceDetailModalRef = this.modalService.open(
      ModalServiceDetailComponent,
      this.modalConfig
    );

    this.trackingService.viewItem(service);

    this.serviceDetailModalRef.onClose.subscribe(params=>{
      if(params && params.addAddons){
        this.addAddonsEvent.emit();
      }
    })
  }

}
