import { Component, OnInit } from '@angular/core';
import { MembershipService } from '../membership.service';
import { SharedService } from 'src/app/shared-component/shared.service';
import { AuthService } from 'src/app/auth/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BookingService } from 'src/app/booking/booking.service';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-selection',
  templateUrl: './selection.component.html',
  styleUrls: ['./selection.component.scss']
})
export class SelectionComponent implements OnInit {

  activeTab = 0;
  memberships = [];
  selectedMembership:any = null;
  billDate = 15
  today = new Date(new Date().setDate(1));
  nextBilling = this.addMonths(this.today, 1);
  authUser:any;
  form!: FormGroup;
  selectedLocation: any = "";
  cart:any;
  paramMembership:any;
  
  constructor(public membershipService:MembershipService, private sharedService:SharedService, private authService:AuthService, 
    private formBuilder: FormBuilder, public bookingService:BookingService, private router:Router, private route: ActivatedRoute,private titleService: Title){
    this.membershipService.$memberships.subscribe(memberships=>{
      if(memberships.length){
        this.memberships = memberships;
        //console.log("this.paramMembership",this.paramMembership);
        if(this.paramMembership && this.paramMembership != ''){
          //console.log("this.memberships",this.memberships);
          const filterMembership = this.memberships.filter((mem:any)=>{
           const membershpName = mem.node.name.toLowerCase().replace(/\s/g, '');
            return membershpName.includes(this.paramMembership.toLowerCase());
          });
          //console.log("filterMembership",filterMembership);
          filterMembership.length ? this.selectedMembership = filterMembership[0] : null;
          filterMembership.length ? this.setActiveTab(1) : null;
        }
      }
    });
    membershipService.createCart();
    membershipService.clientCart$.subscribe((cart:any)=>{
      if(cart){
        this.cart = cart;
        if(cart.selectedItems.length){
          const match = this.memberships.filter(((membership:any)=>{
            return membership.node.name == cart.selectedItems[0].item.name;
          }));
          if(match.length){
            this.selectedMembership = match[0];
          }
        }
        if(cart.clientInformation){
          setTimeout(() => {
            this._patchAdditionalInfoForm(cart.clientInformation);
          }, 10);
        }
        cart.location ? this.selectedLocation = cart.location.id : null;
      }
    })
    this.authService.$AuthUser.subscribe((user: any) => {
      if(user){
        this.authUser = user;
        this.authService.getClientByEmail(user.email, true);
      }
    });
  }

  setActiveTab(idx: number) {
    this.activeTab = idx;
  }

  ngOnInit(): void {
    this.titleService.setTitle('Silver Mirror - Membership Sign-up');
    this.route.params.subscribe(params => {
      this.paramMembership = params['membership'];
    });
    this.membershipService.getMemberships();
    this._getLocations();
    this._buildForm();
  }

  _buildForm(){
    this.form = this.formBuilder.group({
      firstName: [
        this.authUser && this.authUser.firstName ? this.authUser.firstName : "",
        Validators.required,
      ],
      lastName: [this.authUser && this.authUser.lastName ? this.authUser.lastName : "", Validators.required],
      location: ["", Validators.required],
      email: [
        this.authUser && this.authUser.email ? this.authUser.email : "",
        Validators.compose([Validators.required, Validators.email])
      ], // {value: history.state.email ? history.state.email : ""}
      mobilePhone: [
        this.authUser && this.authUser.mobilePhone ? this.authUser.mobilePhone : "",
        Validators.compose([
          Validators.required,
          Validators.maxLength(12),
          Validators.minLength(10),
        ]),
      ],
    });
  }

  _patchAdditionalInfoForm(user:any){
    this.form.patchValue({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      mobilePhone: user.phoneNumber
    });
  }

  _getLocations(){
    if (this.bookingService.locationList$.value.length === 0) {
      this.bookingService.getLocations().subscribe((res: any) => {
        if (!res.errors) {
          this.bookingService.locationList$.next([...res.data.locations.edges]);
        } else {
          this.sharedService.showNotification("Error getting locations: ", res.errors[0].message);
        }
      });
    }
  }

  setActiveMembership(membership:any){
    this.selectedMembership = membership;
  }

  addMonths(date: Date, months: number) {
    date.setMonth(date.getMonth() + months);
    return date;
  }

  setDate(billDate: number) {
    this.billDate = billDate;
    const today = new Date(new Date().setDate(this.billDate));
    this.nextBilling = this.addMonths(today, 1);
    // this.nextBilling = this.addMonths(today, 1).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" }).replace(",", "")
  }

  async proceedToOptions(){
    if (!this.selectedMembership) {
      this.sharedService.showNotification("No Membership is selected", "Please select a membership to proceed");
      return;
    }
    this.setActiveTab(1);
  
  }

  updateUserInfo(){
    return new Promise((resolve, reject)=>{
      if(this.form.valid){
        const client = this.form.value;
        const cartID:any = localStorage.getItem('membershipCart');
        this.bookingService.updateClientCartInfo(client, cartID).subscribe((res:any)=>{
          if(!res.errors){
            resolve(true);
            // this.bookingService.updateCartDetail();
          }else{
            reject();
          }
        });
      }else{
        this.form.markAllAsTouched();
        reject();
        const title = 'Incorrect user information';
        const message = 'Please fill the correct additional information';
        this.sharedService.showNotification(title, message);
      }
    })
  }

  async submitForm() {
    //console.log("dd",this.form.controls);
    this.bookingService.ngxUiLoaderService.start();
    if (this.selectedLocation === "") {
      this.bookingService.ngxUiLoaderService.stop();
      this.sharedService.showNotification("Invalid Data Provided", "Please select a valid location.");
      return;
    }

    let formValue = this.form.value;
   if(this.form.controls.email.errors){
    this.bookingService.ngxUiLoaderService.stop();

   }

    if (this.form.invalid) {
      this.bookingService.ngxUiLoaderService.stop();
      const message =
        "Please fill the correct information in the membership information form.";
      this.sharedService.showNotification("Invalid Data Provided", message);
      return;
    }

    // if (this.membershipCartId === null) {
    //   this.bookingService.createCart("2");
    // }


    if (!this.selectedMembership) {
      this.bookingService.ngxUiLoaderService.stop();
      this.sharedService.showNotification("No Membership is selected", "Please select a membership to proceed");
      return;
    }
    if(this.cart && !this.cart.location){
      const res:any = await firstValueFrom(this.membershipService.setCartLocation(this.selectedLocation));
      if (res.errors) {
        this.bookingService.ngxUiLoaderService.stop();
        return this.sharedService.showNotification("Error", "Error assigning location to cart");
      }
    }else if(this.cart && this.cart.location.id != this.selectedLocation){
      localStorage.removeItem('membershipCart');
      const res:any = await firstValueFrom(this.membershipService.newCart(this.selectedLocation));
      if (res.errors) {
        this.bookingService.ngxUiLoaderService.stop();
        return this.sharedService.showNotification("Error", "Error creating cart");
      }
      localStorage.setItem('membershipCart', res.data.createCart.cart.id);
      const detailres:any = await firstValueFrom(this.membershipService.cartDetail());
      if (res.errors) {
        this.bookingService.ngxUiLoaderService.stop();
        return this.sharedService.showNotification("Error", "Error getting cart detail");
      }
      this.membershipService.clientCart$.next(detailres.data.cart);
      this.bookingService.ngxUiLoaderService.stop();
    }

    await this.updateUserInfo();

    await this.replaceProduct();

    this.membershipService.getCartDetail();

    localStorage.setItem('billDate', this.billDate.toString());
    this.bookingService.ngxUiLoaderService.stop();
    this.router.navigate(["membership", "agreement"]);
  }

  async replaceProduct(){
    if(this.cart && this.cart.selectedItems.length && this.cart.selectedItems[0].item.name != this.selectedMembership.node.name){
      const res:any = await firstValueFrom(this.membershipService.removeItemInCart(this.cart.selectedItems[0].id));
      if (res.errors) {
        return this.sharedService.showNotification("Error", "Error updating membership to cart");
      }
      const resAddProduct: any = await firstValueFrom(this.membershipService.addProductToCart(this.selectedMembership.node.id));
      if (resAddProduct.errors) {
        return this.sharedService.showNotification("Error", "Error adding items to cart");
      }
    }else if(this.cart && !this.cart.selectedItems.length){
      const resAddProduct: any = await firstValueFrom(this.membershipService.addProductToCart(this.selectedMembership.node.id));
      if (resAddProduct.errors) {
        return this.sharedService.showNotification("Error", "Error adding items to cart");
      }
    }
  }
  async onEmailKeyup(email: string) {
   // this.bookingService.ngxUiLoaderService.start();
    if (this.form.controls.email.valid) {
      try {
        const clientDetail: any = await firstValueFrom(this.bookingService.getClientByEmail(email));
        let firstName = clientDetail.data.clients.edges[0]?.node.firstName;
        let lastName = clientDetail.data.clients.edges[0]?.node.lastName;
        let mobilePhone = clientDetail.data.clients.edges[0]?.node.mobilePhone.substring(2);
        // Patch the first name into the form control
        this.form.patchValue({
          firstName: firstName || '',
          lastName: lastName || '',
          mobilePhone: mobilePhone || ''
        });
        /* if (firstName) {
          this.userInfoForm.controls.firstName.disable();  // Disable if firstName exists
        } else {
          console.log("Enable fname called");
          this.userInfoForm.controls.firstName.enable();  // Enable if firstName is empty or undefined
        }
        // Enable or disable the lastName field based on the availability of the value
        if (lastName) {
          this.userInfoForm.controls.lastName.disable();  // Disable if lastName exists
        } else {
          this.userInfoForm.controls.lastName.enable();  // Enable if lastName is empty or undefined
        }

        // Enable or disable the mobilePhone field based on the availability of the value
        if (mobilePhone) {
          this.userInfoForm.controls.mobilePhone.disable();  // Disable if mobilePhone exists
        } else {
          this.userInfoForm.controls.mobilePhone.enable();  // Enable if mobilePhone is empty or undefined
        } */
        this.bookingService.ngxUiLoaderService.stop();
      } catch (error) {
        console.error('Error fetching client details', error);
        this.bookingService.ngxUiLoaderService.stop();
      }
    }
  }
}
