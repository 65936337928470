import { Component } from '@angular/core';
import { GiftcardService } from '../giftcard.service';
import { firstValueFrom } from 'rxjs';
import { SharedService } from 'src/app/shared-component/shared.service';
import { AuthService } from 'src/app/auth/auth.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TrackingService } from '../tracking.service';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent {

  enableStep2: boolean = false;
  giftcardsList: any = [];
  isCustomAmountSelected: boolean = true;
  customAmount: any;
  cart: any;
  user: any;
  today: any;
  paramPreset: any;
  disableContinue = false;

  recipientType: string = "someone";
  recipientForm!: FormGroup;
  giftCardDetails: any;
  notHaveRecEmail: boolean = false;

  constructor(private route: ActivatedRoute, private trackingService: TrackingService, public giftcardService: GiftcardService, private sharedService: SharedService, private authService: AuthService, private formBuilder: FormBuilder, private router: Router) {
    this.route.params.subscribe(params => {
      this.paramPreset = params['preset'];
    });
    const giftCardDetail = localStorage.getItem('giftCardDetail');
    if (giftCardDetail) {
      this.giftCardDetails = JSON.parse(giftCardDetail);
    }
    this.getToday();
    this._buildForm();
    const cartID = localStorage.getItem('giftcardCart');
    cartID ? this.getCartDetail() : null;
    this.getGiftcardsList();
    giftcardService.clientCart$.subscribe(cart => {
      if (cart) {
        this.cart = cart;
        this._patchData();
      }
    })
    this.authService.$AuthUser.subscribe((user: any) => {
      if (user) {
        this.user = user;
        this._patchAdditionalInfoForm(user);
      }
    });
  }

  _buildForm() {
    // PaymentForm
    this.recipientForm = this.formBuilder.group({
      recipientName: ['', Validators.required],
      recipientEmail: ['', Validators.compose([Validators.required, Validators.email])],
      message: [''],
      sendDate: [this.today, Validators.compose([Validators.required, this.sendDateValidator])],
    });
  }

  _patchAdditionalInfoForm(user: any) {
    this.recipientForm.patchValue({
      // senderName:user.firstName + ' ' + user.lastName,
      // senderEmail: user.email,
      sendDate: this.today
    });
    // this.recipientForm.controls.senderEmail.disable();
    // this.recipientForm.controls.senderName.disable();
  }

  _patchData() {
    // Patch giftcard selection
    let giftcardLocal: any = this.sharedService.getLocalStorageItem('giftCardDetail');
    giftcardLocal ? giftcardLocal = JSON.parse(giftcardLocal) : null;

    if (this.paramPreset) {
      const selectedGiftCard = this.giftcardsList.filter((giftcard: any) => giftcard.node.sku == this.paramPreset);
      selectedGiftCard.length ? this.selectGiftCard(selectedGiftCard[0]) : null;
    } else if (giftcardLocal && giftcardLocal.customAmount == 'false') {
      const selectedGiftCard = this.giftcardsList.filter((giftcard: any) => giftcard.node.name == giftcardLocal.giftCardName);
      selectedGiftCard.length ? this.selectGiftCard(selectedGiftCard[0]) : null;
    } else if (giftcardLocal && giftcardLocal.customAmount == 'true') {
      this.isCustomAmountSelected = true;
      this.customAmount = giftcardLocal?.giftCardAmount;
    }

    const recipientType = localStorage.getItem('recipientType');
    recipientType ? this.recipientType = recipientType : null;

    // Patch emailfulfilment
    if (this.isCustomAmountSelected) {
      // from cart
      this._patchRecipientForm(this.cart?.selectedItems[0]?.emailFulfillment);
    } else {
      // from local storage
      let recipient: any = localStorage.getItem('giftcardRecepient');
      if (recipient) {
        recipient = JSON.parse(recipient);
        recipient.messageFromSender = recipient.message;
        recipient.sendDate ? recipient.deliveryDate = recipient.sendDate : null;
        this._patchRecipientForm(recipient);
      }
    }
  }

  _patchRecipientForm(recipient: any) {
    if (recipient) {
      this.recipientForm.patchValue({
        recipientName: recipient.recipientName,
        // senderName: recipient.senderName,
        recipientEmail: recipient.recipientEmail,
        // senderEmail: this?.user?.email,
        message: recipient.messageFromSender,
        sendDate: new Date() > new Date(recipient?.deliveryDate) ? this.today : this.getToday(recipient?.deliveryDate),
      });
    }
  }

  getToday(dateValue?: string) {
    let date = dateValue ? new Date(dateValue) : new Date();
    let m = Number(new Date().getMonth()) + 1;
    let y = new Date().getFullYear();
    let d = new Date().getDate();
    // let returnDate = (m < 10 ? '0'+m : m)  + '/' + (d < 10 ? '0'+d : d) + '/' + y;
    let returnDate = y + '-' + (m < 10 ? '0' + m : m) + '-' + (d < 10 ? '0' + d : d);
    !dateValue ? this.today = returnDate : null;
    return dateValue ? dateValue : returnDate;
  }

  sendDateValidator(control: FormControl): { [key: string]: any } | null {
    if (control.value) {
      let date = new Date();
      let m = Number(new Date().getMonth()) + 1;
      let y = new Date().getFullYear();
      let d = new Date().getDate();
      let returnDate = (m < 10 ? '0' + m : m) + '/' + (d < 10 ? '0' + d : d) + '/' + y;
      const today = returnDate;

      let controlDate = control.value.substr(5, 2) + '/' + control.value.substr(8, 2) + '/' + control.value.substr(0, 4);

      const valid = new Date(controlDate).setHours(0, 0, 0, 0) >= new Date(today).setHours(0, 0, 0, 0);

      return valid ? null : { invalidSendDate: true };
    } else {
      return { invalidSendDate: true };
    }
  }

  changeRecipient(ev: any) {
    if (ev == 'myself') {
      const user = this.authService.$AuthUser.value;
      if (user) {
        this.recipientForm.patchValue({
          recipientName: user.firstName + ' ' + user.lastName,
          // senderName: user.firstName + ' ' + user.lastName,
          recipientEmail: user.email,
          // senderEmail: user.email,
          sendDate: this.today
        });
      }
    } else if (ev == 'someone') {
      const user = this.authService.$AuthUser.value;
      if (user) {
        this.recipientForm.reset();
        this._patchAdditionalInfoForm(user);
      }
    } else {
      this.recipientForm.reset();
    }
    localStorage.setItem('recipientType', ev);
  }
  removeDiscountCode(description:any){
    let discountIndex = description.indexOf("DISCOUNT");

    // Check if "DISCOUNT" is found in the string
    if (discountIndex !== -1) {
        // Extract the substring before "DISCOUNT"
        let resultString = description.substring(0, discountIndex).trim();
        return resultString;
    }else{
      return description;
    }
  }
  async getGiftcardsList() {
    await this.giftcardService.getGiftcardsList().subscribe((res: any) => {
      this.giftcardsList = res.data.products.edges.sort((a: any, b: any) => +a.node.size - +b.node.size);


      /* if (this.giftcardsList?.length > 0) {
        this.giftcardsList[0].node.description = `${this.giftcardsList[0].node.description} DISCOUNT:HOLIDAYGIFTCARD`;
      } */


      this._patchData();
    });
  }

  activeStep(step: number) {
    this.enableStep2 = step == 1 ? false : true;
  }
  saveSelection(giftCard: any) {
    let toSave = { "giftCardName": giftCard.node.name, "giftCardDescription": giftCard.node.description, "giftCardAmount": giftCard.node.unitPrice / 100, "customAmount": "false" };
    this.sharedService.setLocalStorageItem('giftCardDetail', JSON.stringify(toSave));
  }

  selectGiftCard(giftCard: any) {
    this.isCustomAmountSelected = false;
    this.customAmount = null;
    this.giftcardsList.map((card: any) => card.selected = false);
    giftCard.selected = true;
    // this.giftCardService.selectedGiftCard.next(giftCard);
    // this.customAmount = null;
  }

  selectCustomAmount() {
    this.isCustomAmountSelected = true;
  }

  confirmAmount() {
    if (this.isSelectedGiftcardChange()) {
      this.addGiftcard();
    } else {
      this.enableStep2 = true;
    }
  }

  async addGiftcard() {
    if (this.isCustomAmountSelected) {
      if (!this.customAmount || Number(this.customAmount) < 25 || Number(this.customAmount) > 1000) {
        const title = 'Enter purchase amounts between $25 and $1000';
        const message = '';
        return this.sharedService.showNotification(title, message);
      }
    }
    this.enableStep2 = true;
  }

  async createCart() {
    const cartId = localStorage.getItem('giftcardCart');
    if (!cartId) {
      const resCreateCart: any = await firstValueFrom(this.giftcardService.createCart());
      if (!resCreateCart.errors) {
        localStorage.setItem('giftcardCart', resCreateCart.data.createCart.cart.id);
      } else {
        this.sharedService.showNotification("Error", resCreateCart.errors[0].message);
      }
    }
  }

  async addProduct() {
    const selectedGiftcard = this.giftcardsList.filter((giftcard: any) => giftcard.selected);
    if (selectedGiftcard.length) {
      if (this.cart && this.cart.selectedItems.length) {
        const selectedItemId = this.cart.selectedItems[0].id;
        const resRemoveProduct: any = await firstValueFrom(this.giftcardService.removeProductFromCart(selectedItemId));
      }
      const itemId = selectedGiftcard[0].node.id
      const resAddProduct: any = await firstValueFrom(this.giftcardService.addProductToCart(itemId));
    }
  }

  async addGiftcardInCart() {
    await this.createCart();
    const cartId = localStorage.getItem('giftcardCart');
    if (this.cart && this.cart.selectedItems.length) {
      const selectedItemId = this.cart.selectedItems[0]?.id;
      await firstValueFrom(this.giftcardService.removeProductFromCart(selectedItemId));

    }
    await firstValueFrom(this.giftcardService.addGiftCard());

    await this.getCartDetail();
    const giftCardDetail: any = localStorage.getItem('giftCardDetail');
    var giftCardDetails = JSON.parse(giftCardDetail);

    (this.cart.offers.length && this.cart.offers[0].applied) ? this.giftcardService.removeCartOffer(this.cart.offers[0].id).subscribe() : null;

    //this.giftcardsList.forEach((giftcard: any) => {
      const description = giftCardDetails?.giftCardDescription;
      const discountMarker = "DISCOUNT:";
      if (description.includes(discountMarker)) {
        const discountCode = description.split(discountMarker)[1]?.trim(); 
        if (giftCardDetail) {
          localStorage.setItem("giftcardPromo", discountCode);
        }
      }
      // else{
      //   (this.cart.offers.length && this.cart.offers[0].applied) ? this.giftcardService.removeCartOffer(this.cart.offers[0].id).subscribe() : null;
      // }
    //});





    const trackItem = {
      name: giftCardDetails.giftCardName,
      id: "GIFT_CARD",
      listPrice: giftCardDetails.giftCardAmount,
      total: giftCardDetails.giftCardAmount
    }

    this.trackingService.addItem(trackItem);

  }

  async getCartDetail() {
    const resCartDetail: any = await firstValueFrom(this.giftcardService.cartDetail());
    if (!resCartDetail.errors) {
      this.giftcardService.clientCart$.next(resCartDetail.data.cart);
    } else {
      this.sharedService.showNotification("Error", resCartDetail.errors[0].message);
    }
  }

  async addReceipient() {
    const giftCardDetail = localStorage.getItem('giftCardDetail');
    if (giftCardDetail) {
      var giftCardDetails = JSON.parse(giftCardDetail);
    }
    const selectedItemId = this.cart.selectedItems[0].id;
    const resCardDesign: any = await firstValueFrom(this.giftcardService.setCardDesign(selectedItemId));

    //if(this.cart && this.cart?.selectedItems?.length){
    let payload = this.recipientForm.value;
    payload.senderName = "Me";
    const resEmailfulfilment: any = await firstValueFrom(this.giftcardService.updateEmailfulfilment(payload));
    if (!resEmailfulfilment.errors) {
      // this.giftCardService.getCartDetail();
      const title = 'Recipient information';
      const message = 'added successfully';
      //this.sharedService.showNotification(title, message);
      this.router.navigateByUrl("/giftcards/review");
    } else {
      this.sharedService.showNotification("Error", resEmailfulfilment.errors[0].message);
    }
    //}else{
    // this.giftCardService.setEmailfulfilment(this.recipientForm.value).subscribe((res:any)=>{
    //   if(!res.errors){
    //     this.giftCardService.getCartDetail();
    //     this.router.navigateByUrl("/giftcard/review");
    //     // this.router.navigateByUrl("/giftcard/email-design");
    //     const title = 'Recipient information';
    //     const message = 'added successfully';
    //     this.sharedService.showNotification(title, message);
    //   }else{
    //     this.sharedService.showNotification("Error", res.errors[0].message);
    //   }
    // });
    //}
  }

  async continue() {
    this.disableContinue = true;
    const recipient = this.recipientForm.value;
    localStorage.setItem("giftcardRecepient", JSON.stringify(recipient));

    // Add Recepient
    await this.addGiftcardInCart();
    await this.addReceipient();
    
    this.router.navigateByUrl("/giftcards/review");

    /*  if(this.isCustomAmountSelected){
       await this.addReceipient();
     }else{
       const recipient = this.recipientForm.value;
       const selectedGiftcard = this.giftcardsList.filter((giftcard:any)=>giftcard.selected);
       recipient.unitCost = selectedGiftcard[0].node.unitCost;
       localStorage.setItem("giftcardRecepient", JSON.stringify(recipient));
       this.router.navigateByUrl("/giftcards/review");
     } */



    // Cart Detail
    await this.getCartDetail();
    this.disableContinue = false;
  }


  // Changes Detection
  isSelectedGiftcardChange() {
    if (this.customAmount) {
      let toSave = { "giftCardName": "GIFT_CARD","giftCardDescription": '', "giftCardAmount": this.customAmount, "customAmount": "true" };
      this.sharedService.removeLocalStorageItem('promoCode');
      this.sharedService.setLocalStorageItem('giftCardDetail', JSON.stringify(toSave));
    }
    return true;
  }

}
