import { Component, Input } from '@angular/core';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { Observable } from 'rxjs';
import { TrackingService } from 'src/app/booking/tracking.service';
import { NutritionService } from 'src/app/nutrition/nutrition.service';
import { SharedService } from 'src/app/shared-component/shared.service';

@Component({
  selector: 'app-modal-service-detail',
  templateUrl: './modal-service-detail.component.html',
  styleUrls: ['./modal-service-detail.component.scss']
})
export class ModalServiceDetailComponent {

  @Input() service:any;
  // @Input() client:any;

  cart:any;
  cartSubscription:any;
  // client!: string;

  constructor(private trackingService:TrackingService, public sharedService:SharedService, private nutritionService:NutritionService, public serviceModalRef: MdbModalRef<ModalServiceDetailComponent>){
    this.cartSubscription = this.nutritionService.clientCart$.subscribe((cart:any)=>{
      if(cart){
        this.cart = cart;
      }
    })
  }

  // isBaseServiceAdded(){
  //   let selected:boolean = false;
  //   this.cart.selectedItems.map((selectedItem:any)=>{
  //     if(this.client != 'me'){
  //       selectedItem.guestId == this.client.id ? selected = true : null;
  //     }else{
  //       selectedItem.guestId == null ? selected = true : null;
  //     }
  //   })
  //   return selected;
  // }

  addService(){
    

    if(!this.cart.selectedItems.length){
      this.nutritionService.addItemInCart(this.service).subscribe((res:any)=>{
        if(!res.errors){
          // this.trackingService.addItem(this.service);

          const title = 'Great choice! Looking gorgeous…';
          const message = 'ADDED TO CART';
          this.cartSubscription.unsubscribe();
          this.serviceModalRef.close();
          this.sharedService.showNotification(title, message);
          this.nutritionService.getCartDetail();
        }else{
          this.sharedService.showNotification('Errors', res.errors[0].message);
        }
      });
    }
  }

  // addAddons(){
  //   this.serviceModalRef.close({addAddons : true});
  // }

  getServiceName(name:string){
    return name.replace('(', '<br/>(');
  }

}
