import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { BookingService } from 'src/app/booking/booking.service';
import { MembershipService } from 'src/app/membership/membership.service';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrl: './mobile-menu.component.scss'
})
export class MobileMenuComponent implements OnInit {

  showSubMenu:boolean=false;
  user:any;
  cart:any;

  ngOnInit(): void {
    this.authService.$AuthUser.subscribe((user)=>{
      if(user){
        this.user = user;
      }
      user ? this.user = user : null;
    })
  }


  constructor(public bookingService:BookingService, private authService:AuthService, public router: Router,public membershipService:MembershipService){
    this.bookingService.clientCart$.subscribe((cart:any)=>{
      this.cart = cart;
    })
    this.membershipService.clientCart$.subscribe((cart:any)=>{
      this.cart = cart;
    })
  }

  menuToggle(){
    this.showSubMenu = !this.showSubMenu
  }

  logout() {
    localStorage.clear();
    this.authService.$AuthUser.next(null);
    this.router.navigateByUrl("/auth", {replaceUrl: true});
  }

}
