import { Component } from '@angular/core';
import { NutritionService } from '../nutrition.service';
import { SharedService } from 'src/app/shared-component/shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TrackingService } from '../tracking.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent {

  nutritionCart:any;
  serviceParam:any;

  constructor(private route: ActivatedRoute, public nutritionService:NutritionService, private sharedService:SharedService, public router:Router, private trackingService:TrackingService){
    nutritionService.clientCart$.subscribe((cart)=>{
      if(cart){
        this.nutritionCart = cart;
        this.serviceAddRequested();
      }
    })
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const couponCode = params['coupon'];
      this.serviceParam = params['service'];
      couponCode ? this.sharedService.setLocalStorageItem('coupon', couponCode) : null;
    });
  }

  serviceAddRequested(){
    // const serviceName = this.sharedService.getLocalStorageItem('serviceName');
    if(this.serviceParam && this.nutritionCart && this.nutritionCart.id){
      // let cartSubscription2 = this.bookingService.clientCart$;
    
      // cartSubscription2.subscribe((cart)=>{
        // if(cart && cart.id){
          const service = this.sharedService.findServiceIdByName(this.serviceParam, this.nutritionCart);
          // this.sharedService.removeLocalStorageItem('nutritionServiceName');
          // cartSubscription2.unsubscribe();
          service ? this.addService(service) : null;
        // }
      // });
    }
  }

  addService(service:any){
    // let selected:boolean = false;
    // this.nutritionCart.selectedItems.map((selectedItem:any)=>{
    //   if(this.tabs.guest != 'me'){
    //     selectedItem.guestId == this.tabs.guest.id ? selected = true : null;
    //   }else{
    //     selectedItem.guestId == null ? selected = true : null;
    //   }
    // })
    
    if(!this.nutritionCart.selectedItems.length){
      const payload = {
        id:service.id,
        staffId:null,
        guestId:null
      }
      this.nutritionService.addItemInCart(payload).subscribe((res:any)=>{
        if(!res.errors){
          service.category = 'Nutrition';
          this.trackingService.addItem(service);
          
          const title = 'Great choice! Looking gorgeous…';
          const message = 'ADDED TO CART';
          this.sharedService.showNotification(title, message);
          this.nutritionService.getCartDetail();
        }else{
          this.sharedService.showNotification('Errors', res.errors[0].message);
        }
      });
    }else if((this.serviceParam && this.nutritionCart.selectedItems[0].item.name.trim().replaceAll(' ', '').toLowerCase() != this.serviceParam.trim().replaceAll(' ', '').toLowerCase()) || !this.serviceParam){
      this.replaceItem(service);
      // const title = 'Facials, Microchanneling and Chemical Peels cannot be performed together';
      // const message = 'Please remove existing service from cart';
      // this.sharedService.showNotification(title, message);

      // remove service & add new service
    }
  }

  replaceItem(service:any){
    const addedItem = this.nutritionCart.selectedItems[0];
    this.nutritionService.removeItemInCart(addedItem.id).subscribe((res:any)=>{
      if(!res.errors){
        addedItem.item.listPrice = addedItem.price;
        addedItem.item.category = 'Nutrition'
        this.trackingService.removeItem(addedItem.item);
        const payload = {
          id:service.id,
          staffId:null,
          guestId:null
        }
        this.nutritionService.addItemInCart(payload).subscribe((res:any)=>{
          if(!res.errors){
            service.category = 'Nutrition';
            this.trackingService.addItem(service);
            
            const title = 'Great choice! Looking gorgeous…';
            const message = 'ADDED TO CART';
            this.sharedService.showNotification(title, message);
            this.nutritionService.getCartDetail();
          }else{
            this.sharedService.showNotification('Errors', res.errors[0].message);
          }
        });
      }else{
        this.sharedService.showNotification('Errors', res.errors[0].message);
      }
    });
  }

  continue(){
    window.scrollTo(0, 0);
    // toggleCart ? this.bookingService.toggleMobileCart() : null;
    // let cartMemberCount = this.cart.guests.length + 1;
    if(this.nutritionCart.selectedItems.length){
      this.nutritionService.mobileCartView.next(false);
      this.router.navigateByUrl('/nutrition/location');
    }else{
      const title = 'Cart is empty';
      const message = 'Add service to continue';
      this.sharedService.showNotification(title, message);
    }
  }

}
