<app-header mainTitle="Book an Appointment"></app-header>

<section class="services-flow" [ngClass]="(cart?.guests?.length && isGuestTabVisible) ? 'has-guests' : '' ">
	<div class="container grid">
		
		<!--Breadcrumbs-->
        <app-breadcrumbs class="breadcrumbs-container"></app-breadcrumbs>
		<app-mobile-menu class="user-head mobile-head"></app-mobile-menu>

		<div class="content-container">
			
			<!--Services Tabs-->
			<!-- <?php include('includes/services/services-tabs.php'); ?> -->
            <!-- <app-services-tabs class="desktop-only" [reset]="resetServiceTabs" [isAddonEnable]="cart?.selectedItems?.length" (changeTabEvent)="changeServiceTab($event)"></app-services-tabs> -->
			<!-- {{isSameServices}} --{{ this.getGroupedServices.length}} -- {{isGuestTabVisible}} -->
			<div class="guest-different-services" *ngIf="cart?.guests?.length" (click)="canAllowDifferentService()">
				<input [disabled]="isHydrafacialPresent" [ngClass]="(!isSameServices && this.getGroupedServices.length == 1) ? 'disabled-input' : ''" [(ngModel)]="!isSameServices" [checked]="!isSameServices"  (change)="showGuest()" id="guest-different" type="checkbox"/>
				<label for="guest-different">MY GUESTS & I WANT DIFFERENT SERVICES</label>
			</div>
			<!-- <div class="guest-different-services" *ngIf="cart?.guests?.length">
			<input type="checkbox"/>
			<label for="guest-different">MY GUESTS & I WANT DIFFERENT SERVICES</label>
		</div> -->
			<!--Guest Tabs-->
			<!-- <?php include('includes/schedule/guest-tabs.php'); ?> -->
			<!-- {{cart?.guests?.length}}--{{isGuestTabVisible}} -->
            <app-guest-tabs *ngIf="cart?.guests?.length && isGuestTabVisible" [serviceCount]="getMemberAddedServiceCount" [guests]="cart.guests" (changeTabEvent)="changeGuestTab($event)"></app-guest-tabs>
						
			<!--Mobile Tabs/Filters-->
			<app-services-tabs [cart]="cart" [reset]="resetServiceTabs" [isAddonEnable]="cart?.selectedItems?.length" (changeTabEvent)="changeServiceTab($event)"></app-services-tabs>
			
			<!--Time Filters-->
			<!-- <?php include('includes/services/time-filters.php'); ?> -->
            <!-- <app-time-filters [reset]="resetTimeTabs" *ngIf="tabs.service.toLowerCase().includes('30')" (changeTabEvent)="changeTimeFilterTab($event)"></app-time-filters>
 -->
			<!-- <?php include('includes/services/mobile-services-tabs.php'); ?> -->
			<p *ngIf="tabs.service == '50'" class="xx-small info">All 50-minute facials are interchangable and customizable. Please speak with your esthetician.</p>
			
			<!--Services List-->
			<!-- <?php include('includes/services/services-list.php'); ?> -->
			
            <app-services-list [category]="tabs.service" *ngIf="cart?.availableCategories?.length && tabs.service != 'addon'" 
			[client]="tabs.guest" [services]="cart.availableCategories | filterService:tabs.service:tabs.guest" 
			(addAddonsEvent)="showAddons()" (addServiceEvent)="addService($event)"></app-services-list>
			<!-- <app-addons-list *ngIf="tabs.service == 'addon'" [client]="tabs.guest" [cart]="cart"></app-addons-list> -->
		</div>
		
		<!--Sidebar-->
		<!-- <?php include('includes/services/sidebar-guests-empty.php'); ?> -->
		<aside *ngIf="cart" [ngClass]="(bookingService.mobileCartView | async) ? 'active' : '' " 
		[ngClass] = "!cart?.selectedItems?.length ? 'empty':'' ">

			<div class="sidebar-sticky-desktop-only">
				<app-cart [cart]="cart" [isSameService]="isSameServices" (onItemRemoveEvent)="itemRemovedFromCart()"></app-cart>
				<!-- <span (click)="continue()"><a class="btn active desktop-only">Continue</a></span>
				<span (click)="continue()" *ngIf="!cart?.selectedItems?.length">
					<a class="btn active view-cart mobile-only" aria-current="page" >Continue</a>
				</span>
				<span (click)="continue()" *ngIf="cart?.selectedItems?.length">
					<a class="btn active view-cart mobile-only" *ngIf="cart?.selectedItems?.length">Continue</a>
				</span> -->
			</div>
        </aside>
		
	</div>
</section>

<span class="fixed-continue-button">
	<a 
	  [ngClass]="{'active': this.cart?.selectedItems?.length === 1, 'btn-disabled': this.disableContinue}" 
	  (click)="continue()" 
	  class="btn">Continue
	</a>
  </span>
  

<!-- <app-footer></app-footer> --> 