<!-- <div class="content"> -->
	<!-- <h1 class="heading-4">Welcome back, Tamara!</h1> -->
	<div>
		<ul class="tabs-nav">
			<li><button [ngClass]="activeTab == 1 ? 'pill fill' : 'pill fill outline gray'" (click)="changeTab(1)">Facial</button></li>
			<li><button [ngClass]="activeTab == 2 ? 'pill fill' : 'pill fill outline gray'" (click)="changeTab(2)">Nutrition</button></li>
		</ul>
	</div>
	
	<h2 class="eyebrow">Upcoming Appointments</h2>
	
	<div *ngIf="(dashboardService.$myAppointments | async | upcomingappointments:activeTab).length; else noUpcomingAppointments">
		<app-appointment-tile [appointmentType]="'upcoming'" [activeTab]="activeTab" [canReschedule]="appt.node.reschedulable ? true : false" *ngFor="let appt of dashboardService.$myAppointments | async | upcomingappointments:activeTab" [appointment]="appt?.node"></app-appointment-tile>
	</div>

	<ng-template #noUpcomingAppointments>
		<p>No Upcoming Appointments</p>
	</ng-template>
	
	<h2 class="eyebrow">Past Appointments</h2>
	
	<div *ngIf="(dashboardService.$myAppointments | async | pastappointments:activeTab).length; else noPastAppointments">
		<app-appointment-tile [appointmentType]="'past'" [canReschedule]="appt.node.reschedulable ? true : false" [rebook]="activeTab == 1 ? true : false" [activeTab]="activeTab" [cancel]="cancel" *ngFor="let appt of dashboardService.$myAppointments | async | pastappointments:activeTab" [appointment]="appt?.node"></app-appointment-tile>
	</div>
	<ng-template #noPastAppointments>
		<p>No Past Appointments</p>
	</ng-template>
<!-- </div> -->

<!-- <app-reschedule [appointmentId]="appointmentId"></app-reschedule> -->