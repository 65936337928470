import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ServicesComponent } from "./services/services.component";
import { NutritionComponent } from "./nutrition.component";
import { SchedulingComponent } from "./scheduling/scheduling.component";
import { ReviewComponent } from "./review/review.component";
import { CongratsComponent } from "./congrats/congrats.component";
import { LocationComponent } from "./location/location.component";
// import { LocationComponent } from "./location/location.component";
// import { WhosComingComponent } from "./whos-coming/whos-coming.component";
// import { SchedulingComponent } from "./scheduling/scheduling.component";
// import { ReviewComponent } from "./review/review.component";
// import { CongratsComponent } from "./congrats/congrats.component";
// import { LocationGuard } from "../guards/location.guard";

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: "nutrition",
        component: NutritionComponent,
        children: [
          { path: "", redirectTo: "/nutrition/services", pathMatch: 'full' },
          {
            path: "services",
            children:[
              {
                path: "service/:service/coupon/:coupon",
                component: ServicesComponent,
              },
              {
                path: "coupon/:coupon",
                component: ServicesComponent,
              },
              {
                path: "service/:service",
                component: ServicesComponent,
              },
              {
                path: "",
                pathMatch: "full",
                component: ServicesComponent
              }
            ]
            // canActivate: [LocationGuard]
          },
          {
            path: "location",
            component: LocationComponent,
            // canActivate: [LocationGuard]
          },
          {
            path: "schedule",
            component: SchedulingComponent,
            // canActivate: [LocationGuard]
          },
          {
            path: "review",
            component: ReviewComponent,
            // canActivate: [LocationGuard]
          },
          {
            path: "congrats",
            component: CongratsComponent,
          },
          // { path: "location", redirectTo: "location/service" },
        ],
      },
    ]),
  ],
  exports: [RouterModule],
})
export class NutritionRoutingModule {}
