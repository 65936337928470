import { commonEnv } from "./environment.common";

const env: Partial<typeof commonEnv> = {
    production: true,
    environmentName: "production",
    apiBaseURL: "https://blvd.silvermirror.com",
    //apiBaseURL: "https://blvdpdstg.silvermirror.com",
    //apiBaseURL: "http://localhost:50001",
    paymentApiBaseURL: "https://pci.boulevard.app",
    giftcard_location_id: "urn:blvd:Location:704277ae-8958-4ba0-97a3-0e991477add7",
    nutrition_location_id: "urn:blvd:Location:72fd44e3-1c00-4a7a-a2a2-92fc9aaaee97", // Silver Mirror Nutrition
    nutrition_tag_id:"493632c5-2013-4e15-aac2-dfab0fdbba18", // Nutrition
    klavio_booking_segment_id:"Yzqjqu",
    klavio_membership_segment_id:"Vc8nJ3",
    version: "3.1.62"
};

// Export all settings of common replaced by dev options
export const environment = Object.assign(commonEnv, env);
