<div class="popup-screen"></div>

<div class="popup card-details">
	<button class="close" (click)="addonModalRef.close()"><span class="accessibility">Close Popup</span></button>
	
	<!-- <img style="background: #484848; object-fit: contain;" src="assets/svgs/icon-service-example.svg" alt=""/> -->
	<img [src]="'https://blvd.silvermirror.com/assets/cover-urn\:blvd\:Service\:' + addon.id.split(':')[3] + '.png'" alt=""/>
	
	<article>
		<ngx-simplebar >
			<h2 class="heading-6 flex-container">{{addon?.name}} <span class="heading-6 small">{{sharedService.formatPrice(addon.listPrice) | currency:'USD':'symbol' : '1.0-0'}}</span></h2>
			<span class="badge-img"><img [src]="'https://blvd.silvermirror.com/assets/badge-urn\:blvd\:Service\:' + addon.id.split(':')[3] + '.png'" alt=""/></span>

			<p class="x-small skin-types"><span class="pill">Recommended for</span> {{sharedService.getSkinType(addon?.description)}}</p>
			<p class="x-small">{{sharedService.getDescriptionOnly(addon?.description)}}</p>
		</ngx-simplebar>
		
		<button *ngIf="!addon.selected" class="btn black" (click)="addAddonForAll()">Add-on</button>
		<button *ngIf="addon.selected" class="btn blue" (click)="addAddonForAll()">
			<span class="added-icon"></span>
			Addon Added
		</button>
	</article>
</div> 