<ul class="services-list" *ngIf="services.length">
	<ng-container *ngFor="let service of services">
		<!-- Display the name of the service -->
		<h6 class="heading-6 target" id="{{service.name}}">{{ service.name }}</h6>
	  
		<!-- Loop over availableItems within each service -->
		<li *ngFor="let item of service.availableItems | order:category" [ngClass]="item.selected ? 'active' : ''">
		  <button (click)="addService(item)" class="add-to-cart desktop-only">
			<img [src]="item.selected ? 'assets/svgs/icon-selected-check.svg' : 'assets/svgs/icon-accordion-open.svg'" />
			<span class="accessibility">Add this service to your cart</span>
		  </button>
	  
		  <button class="flex-container open-services-pop" (click)="serviceDetail(item,service.name)">
			<img [src]="'https://blvd.silvermirror.com/assets/thumb-' + item.id + '.png'" alt="" />
			
			<div>
			  <h2 class="heading-6" [innerHTML]="getServiceName(item.name)"></h2>
			  <span class="badge-img">
				<img [src]="'https://blvd.silvermirror.com/assets/badge-' + item.id + '.png'" />
			  </span>
			  <p class="pill">{{ sharedService.getSkinType(item?.description) }}</p>
			  <span class="heading-6 small price">
				{{ sharedService.formatPrice(item.listPrice) | currency:'USD':'symbol':'1.0-0' }}
			  </span>
			</div>
		  </button>
		</li>
	  </ng-container>
	  
</ul>

<ul *ngIf="services.length == 0">
	<p>No service is available for selected location.</p>
</ul>