import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, lastValueFrom } from 'rxjs';
import { SharedService } from 'src/app/shared-component/shared.service';
import { BookingService } from '../booking.service';

@Component({
  selector: 'app-whos-coming',
  templateUrl: './whos-coming.component.html',
  styleUrls: ['./whos-coming.component.scss']
})
export class WhosComingComponent {

  tab:string = 'me';
  guestCount:number = 1;
  cart:any;

  constructor(private router:Router, private bookingService:BookingService, private sharedService:SharedService) {
    this.bookingService.ngxUiLoaderService.start();
    this.bookingService.getCartDetail().subscribe((res:any)=>{
      if(!res.errors){
        this.cart = res.data.cart;
        if(this.cart && this.cart.guests?.length){
          this.tab = 'guest';
          this.guestCount = this.cart.guests.length;
        }
        this.bookingService.clientCart$.next(res.data.cart);
      }
      this.bookingService.ngxUiLoaderService.stop();
    });
   }

  selectGuest(){
    // runs when guest is selected
  }

  createGuests(count:number){
    const requests = [];
    for (let index = 0; index < count; index++) {
        requests.push(this.bookingService.createGuest());
    }
    forkJoin(requests).subscribe(res=>{
      this.bookingService.updateCartDetail();
      this.bookingService.ngxUiLoaderService.stop();
      this.router.navigateByUrl('/booking/services');
    });
  }

  // async createGuests(count: number) {
  //   const requests = [];
  //   for (let index = 0; index < count; index++) {
  //     requests.push(this.bookingService.createGuest());
  //   }

  //   const responses = await lastValueFrom(forkJoin(requests));

  //   const lastResponse:any = responses[responses.length - 1];

  //   const myBaseService = this.cart.selectedItems.filter((item:any)=>{
  //     return item.guestId == null && !item.item.optionGroups.length
  //   })

  //   const promises:any = [];
  //   lastResponse.data.createCartGuest.cart.guests.forEach((res:any) => {
  //     const payload = {
  //       id: myBaseService[0]?.item?.id,
  //       staffId: null,
  //       guestId: res.id
  //     }
  //     const guestIdExists = this.bookingService.clientCart$.value.selectedItems.some((item: { guestId: any; }) => item.guestId ===  res.id);

  //     !guestIdExists ? promises.push(this.bookingService.addItemInCart(payload)) : null;
  //   });
  //   forkJoin(promises).subscribe(()=>{
  //     // this.bookingService.updateCartDetail();
  //     this.bookingService.ngxUiLoaderService.stop();
  //     this.router.navigateByUrl('/booking/services');
  //   });

  
  //   // try {
  //   //   const responses = await lastValueFrom(forkJoin(requests));
  //   //   responses.forEach((response: any, index) => {
  //   //     const mySelectedBaseService = this.getGroupedServices.filter((groupService: any) => groupService.guestId == null)[0]?.data.filter((service: any) => !service.item.optionGroups.length);
   
  //   //     response.data.createCartGuest.cart.guests.forEach((guest: any) => {

  //   //       // this.bookingService.clientCart$.value.selectedItems
  //   //       const guestIdExists = this.bookingService.clientCart$.value.selectedItems.some((item: { guestId: any; }) => item.guestId ===  guest.id);

  //   //       if(!guestIdExists){
  //   //       const payload = {
  //   //         id: mySelectedBaseService[0]?.item?.id,
  //   //         staffId: null,
  //   //         guestId: guest.id
  //   //       }
  //   //       this.bookingService.addItemInCart(payload).subscribe((res: any) => { 
            
  //   //       });
  //   //     }
          
  //   //     });
  //   //   });
  //   // } catch (error) {
  //   //   console.error('Error creating guests:', error);
  //   // }
  // }
  async removeGuestsAll(count: number) {
    const requests = [];
    const guests = this.cart.guests;
    for (let index = 0; index < count; index++) {
      requests.push(this.bookingService.removeGuest(guests[index].id));
    }
    await lastValueFrom(forkJoin(requests));
    // this.bookingService.updateCartDetail();
  }
  async removeGuests(guestId: any) {
    const requests = [this.bookingService.removeGuest(guestId)];
    await lastValueFrom(forkJoin(requests));
    // await this.bookingService.updateCartDetail();
  }

  resetCart(){
    const message = "If you remove guests, you will lose the added services in cart"
    let locationId = this.cart.location?.id;
    this.sharedService.openConfirmationAlert(message).then((res:any)=>{
      //this.bookingService.ngxUiLoaderService.start();
      if(res){
       /*  this.bookingService.createCart(locationId).subscribe((res:any)=>{
          if(!res.errors){
            this.sharedService.removeLocalStorageItem('isSameService');
            this.sharedService.setLocalStorageItem('cartId', res.data.createCart.cart.id);
            this.tab != 'me' ? this.createGuests(this.guestCount) : null;
            this.bookingService.updateCartDetail();
            this.router.navigateByUrl('/booking/services')
            //this.bookingService.ngxUiLoaderService.stop();
          }else{
            this.sharedService.showNotification("Error", res.errors[0].message);
            //this.bookingService.ngxUiLoaderService.stop();
          }
        }); */ 
      }
    });
  }
  setGuestMe(value: string) {
    if (value === 'me') {
      this.tab = 'me';
      this.guestCount = 0;
      this.sharedService.setLocalStorageItem('guestSet', 'false');
    } else if (value === 'guest1') {
      this.tab = 'guest';
      this.guestCount = 1;
      this.sharedService.setLocalStorageItem('guestSet', 'true');
    } else if (value === 'guest2') {
      this.tab = 'guest';
      this.guestCount = 2;
      this.sharedService.setLocalStorageItem('guestSet', 'true');
    }
  }
  
  groupObjectsById(array: any) {
    const groups: any = {};

    for (const obj of array) {
      if (obj.guestId in groups) {
        groups[obj.guestId].data.push(obj);
      } else {
        groups[obj.guestId] = { guestId: obj.guestId, guest: obj.guest, data: [obj] };
      }
    }

    return Object.values(groups);
  }
  get getGroupedServices() {
    const groupedSelectedServices: any = this.groupObjectsById(this.cart.selectedItems);
    return groupedSelectedServices;
  }


  async gotoServices() {
    this.bookingService.ngxUiLoaderService.start();
    const guestSet = this.sharedService.getLocalStorageItem('guestSet');
  
    if (guestSet) {
      if (this.cart) {
          if (this.cart.guests.length > this.guestCount) {
          await this.removeGuests(this.cart.guests[0].id);
          this.cart.selectedItems?.length ? this.sharedService.setLocalStorageItem('isSameService', 'false') : null;
          this.router.navigateByUrl('/booking/services');
          this.bookingService.ngxUiLoaderService.stop();
        } else if (this.cart.guests.length < this.guestCount) {
          const guestCount = this.guestCount - this.cart.guests.length;
          await this.createGuests(guestCount);
          // this.bookingService.updateCartDetail();
          this.cart.selectedItems?.length ? this.sharedService.setLocalStorageItem('isSameService', 'false') : this.sharedService.setLocalStorageItem('isSameService', 'true');
          this.bookingService.ngxUiLoaderService.stop();
        } else if (this.tab === 'me' && this.cart.guests.length) {
          await this.removeGuestsAll(this.cart.guests.length);
          this.bookingService.ngxUiLoaderService.stop();
          this.router.navigateByUrl('/booking/services');
          this.bookingService.ngxUiLoaderService.stop();
        }else{
          this.cart.selectedItems?.length ? this.sharedService.setLocalStorageItem('isSameService', 'false') : this.sharedService.setLocalStorageItem('isSameService', 'true');
          this.bookingService.ngxUiLoaderService.stop();
          this.router.navigateByUrl('/booking/services');
          this.bookingService.ngxUiLoaderService.stop();
        }
      }
    } else {
      if (this.tab === 'guest') {
        await this.createGuests(this.guestCount);
        this.bookingService.ngxUiLoaderService.stop();
      }
      this.router.navigateByUrl('/booking/services');
      this.bookingService.ngxUiLoaderService.stop();
    }
    
    // const cartId = this.sharedService.getLocalStorageItem('cartId');
    // if(cartId){
    //   this.bookingService.getCartDetail().subscribe((res:any)=>{
    //     if(!res.errors){
    //       this.bookingService.clientCart$.next(res.data.cart);
    //       this.bookingService.ngxUiLoaderService.stop();
    //       this.router.navigateByUrl('/booking/services');
    //     }
    //   });
    // }
   
  }
  
  


}
