import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, forkJoin, map, Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { environment } from "src/environments";

const BASE_URL = environment.apiBaseURL; 
// const this.authUser.authId = localStorage.getItem('clientID'); // himanshu.sharma@opensourcetechnologies.com
// const this.authUser.authId = "0b9b1658-d8f2-4f3e-aabc-0574ef6a8af9" // testost1@gmail.com


@Injectable({
  providedIn: 'root'
})
export class DashboardService {

    $myAppointments:BehaviorSubject<any> = new BehaviorSubject([]);
    $servicesList:BehaviorSubject<any> = new BehaviorSubject([]);
    $loyalty:BehaviorSubject<any> = new BehaviorSubject(null);
    $allAppointments:BehaviorSubject<any> = new BehaviorSubject([]);
    loggedInclientName:any = localStorage.getItem('clientName');
    authUser:any;
    
    constructor(private httpClient: HttpClient, private authService:AuthService){
      authService.$AuthUser.subscribe(user=>{
        this.authUser = user;
      });
    }

    getServices(){
        return this.httpClient.get<HttpResponse<any>>(BASE_URL + '/get_services');
        // .subscribe((res:any)=>{
        //     if(!res.errors){
        //         this.$servicesList.next(res.data.services.edges);
        //     }
        // });
    }

    getAllAppointments(){
      const payload = {
        "clientEmail": this.authUser.email
      }
      return this.httpClient.post<HttpResponse<any>>(BASE_URL + '/get_business_carts', payload)
    }

    getAppointmentsList():Observable<HttpResponse<any>>{
        const payload = {
            "clientId": this.authUser.authId
        }
        return this.httpClient.post<HttpResponse<any>>(BASE_URL + '/my_appointments', payload).pipe(map((res:any)=>{
            const services = this.$servicesList.value;

            res = this.mergeGuestAppointments(res);

            // Filter out duplicate edges
            res.data.myAppointments.edges.filter((item:any, index:number) => res.data.myAppointments.edges.indexOf(item) !== index);
            
            res.data.myAppointments.edges = this.removeDuplicateAppointments(res.data.myAppointments.edges);

            res.data.myAppointments.edges.map((apt: any) => {
                // Add option name for guests
                if(apt.node.guestsAppointment && apt.node.guestsAppointment.appointments.length){
                  apt.node.guestsAppointment.appointments.map((apts:any)=>{
                    apts.appointmentServiceOptions.map((aptOption:any)=>{
                      services.map((service: any) => {
                        if (service.node.serviceOptionGroups.length) {
                          service.node.serviceOptionGroups.map((optionGroup: any) => {
                            if (optionGroup.serviceOptions.length) {
                              const match = optionGroup.serviceOptions.filter((option: any) => {
                                  return option.id == aptOption.serviceOptionId
                              })
                              match.length ? aptOption.name = match[0].name : null;
                            }
                          })
                        }
                      });
                    })
                  })
                }
                apt.node.appointmentServiceOptions.map((appointmentServiceOption: any) => {
                    services.map((service: any) => {
                    if (service.node.serviceOptionGroups.length) {
                      service.node.serviceOptionGroups.map((optionGroup: any) => {
                        if (optionGroup.serviceOptions.length) {
                          const match = optionGroup.serviceOptions.filter((option: any) => {
                              return option.id == appointmentServiceOption.serviceOptionId
                          })
                          match.length ? appointmentServiceOption.name = match[0].name : null;
                        }
                      })
                    }
                  });
                })
              });
            return res;
        }));
    }

    removeDuplicateAppointments(appts:any) {
      const seenIds = new Set();
      const uniqueArray = [];
      
      for (const obj of appts) {
        if (!seenIds.has(obj.node.id)) {
          seenIds.add(obj.node.id);
          uniqueArray.push(obj);
        }
      }
      
      return uniqueArray;
    }

    mergeGuestAppointments(myAppointments:any){

      let allAppointments = this.$allAppointments.getValue();
      const my_appointments = myAppointments;

      allAppointments.map((appointment:any)=>{
        if(appointment.node.appointments.length > 1){
          appointment.node.appointments.map((apt:any)=>{
            myAppointments.data.myAppointments.edges.map((myAppointment:any)=>{
              if(apt.id == myAppointment.node.id){
                const guestApts = appointment.node.appointments.filter((element:any)=> element.id != myAppointment.node.id);
                appointment.node.appointments = guestApts;
                myAppointment.node.guestsAppointment = appointment.node;
              }
            })
          })
        }
      });
      
      return myAppointments;
    }

    cancelAppointment(aptId:string, clientId:string):Observable<HttpResponse<any>>{
        const payload = {
            "clientId": clientId.replace("urn:blvd:Client:", ""),
            "appointmentId": aptId,
            "notes":""
        }
        return this.httpClient.post<HttpResponse<any>>(BASE_URL + '/cancel_appointment', payload);
    }

    getClientInfo():Observable<HttpResponse<any>>{
        const payload = {
            "emails": this.authUser.email
        }
        return this.httpClient.post<HttpResponse<any>>(BASE_URL + '/get_client_by_email', payload);
    }

    updateClientInfo(client:any):Observable<HttpResponse<any>>{
      client.id="urn:blvd:Client:"+this.authUser.authId;
        const payload = {
            "clientId": this.authUser.authId,
            "client": client
          }
        return this.httpClient.post<HttpResponse<any>>(BASE_URL + '/update_client_profile', payload);
    }

    myMembershipsList(){
        const payload = {
            "clientId": this.authUser.authId
          }
        return this.httpClient.post<HttpResponse<any>>(BASE_URL + '/my_memberships', payload);
    }

    getRescheduleDates(aptId:string, lowerRange:string, upperRange:string){
        const payload = {
            "appointmentId":aptId,
            "searchRangeLower":lowerRange,
            "searchRangeUpper":upperRange,
            "timeZone":'America/New_York'
          }
        return this.httpClient.post<HttpResponse<any>>(BASE_URL + '/appointment_reschedule_available_dates', payload);
    }

    getRescheduleTimes(aptId:string, date:string){
        const payload = {
            "appointmentId":aptId,
            "date":date,
            "timeZone":'America/New_York'
          }
        return this.httpClient.post<HttpResponse<any>>(BASE_URL + '/appointment_reschedule_available_times', payload);
    }

    reschedule(aptId:string, timeId:string){
        const payload = {
            "appointmentId":aptId,
            "bookableTimeId":timeId
          }
        return this.httpClient.post<HttpResponse<any>>(BASE_URL + '/rechedule_appointment', payload);
    }

  getLocations() {
    return new Promise(resolve=>{
      this.httpClient.get(BASE_URL+'/get_locations').subscribe((res:any)=>{
        resolve(res.data.locations.edges);
      });
    })
  }

  inStorePurchaseOrders(){
    return new Promise((resolve)=>{
      const requests:any = [];
      this.getLocations().then((locations:any)=>{
        locations.forEach((location:any) => {
          const payload = {
            "location": location.node.id,
            "clientEmail": this.authUser.email,
            "clientID": this.authUser.id
          }
          requests.push(this.httpClient.post<HttpResponse<any>>(BASE_URL + '/orders_by_location', payload));
        });
        return forkJoin(requests).pipe(map((res:any)=>{
          let finalResponse:any = {data:{orders:{edges:[]}}};
          res.forEach((groupRes:any) => {
            if(groupRes && groupRes.length){
              finalResponse.data.orders.edges = [...finalResponse.data.orders.edges, ...groupRes]
            }
          });
          return finalResponse;
        })).subscribe((res:any)=>{
          resolve(res);
        });
      })
    })
  }

  getLoyalty(){
    return new Promise(resolve=>{
      const param = {userID: this.authUser.authId};
      this.httpClient.post(BASE_URL+'/get_loyalty', param).subscribe((res:any)=>{
        resolve(res);
      });
    })
  }

  getMemberships() {
    return this.httpClient.get(BASE_URL + "/memberships");
  }

  getZoomLink(payload: {aptID:string}){
    return this.httpClient.post(BASE_URL + "/appointment_zoom_link", payload);
  }

}