import { Pipe, PipeTransform } from "@angular/core";
import { ADDON_CATEGORIES_ORDER } from "src/app/constants/constants";

@Pipe({
  name: "sortAppointmentServices",
})
export class SortAppointmentServicesPipe implements PipeTransform {

  transform(services: Array<any>): Array<any> {
    if(services.length){
      const sortedServices = services.sort((a:any, b:any) => {
        const nameA = a.service.category.name.toUpperCase();
        const nameB = b.service.category.name.toUpperCase();

        const orderA = ADDON_CATEGORIES_ORDER.find(cat=> cat.category.toUpperCase() == nameA)?.order ?? 0;
        const orderB = ADDON_CATEGORIES_ORDER.find(cat=> cat.category.toUpperCase() == nameB)?.order ?? 0;

        return Number(orderA) - Number(orderB);
    });
    return sortedServices;
    }else{
      return services;
    }
  }

}
