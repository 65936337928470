<div class="popup-screen"></div>

<div class="popup addon-pop">

	<button class="close" (click)="confirmationModalRef.close()"><span class="accessibility">Close Popup</span></button>

	<div class="scroller addon-suggest">
		<h2 class="heading-6" style="text-align:center" [innerHTML]="message"></h2>
	</div>

	<div class="fixed-info appointment-cancel">
		<button class="btn black" (click)="continue()">Clear My Cart and Continue</button>
		<!-- <button class="btn black outline" (click)="close()">Close</button> -->
		<button class="btn black outline" (click)="closeandContinue()">Keep My Cart and Continue</button>
		<!-- <a class="#" (click)="close()">Cancel</a> -->
	</div>
</div>