<main id="main">
    <section class="membership-flow review-flow membership-review">
      <form class="container grid">
        <!--Breadcrumbs-->
        <div class="breadcrumbs-container">
          <a class="back-btn" href="#"><span class="accessibility">Back</span></a>
  
          <ul class="flex-container breadcrumbs">
            <li>
              <a class="eyebrow small" (click)="navigateToOptions()"
                >Select Membership</a
              >
            </li>
            <li>
              <a class="eyebrow small" (click)="navigateToAgreement()"
                >Sign Agreement</a
              >
            </li>
            <li class="active">
              <a class="eyebrow small">Review Purchase</a>
            </li>
          </ul>
        </div>

        <app-mobile-menu class="user-head mobile-head"></app-mobile-menu>
        
        <div class="content-container">
            <!--Credit Card-->
            <div class="payment-details">

                <ul class="grid additional-info">
                    <li></li>
                </ul>
                <div [formGroup]="couponForm" class="mobile-only flex-container promo-code">
                    <label class="pill" for="promo-code">Promo Code</label>
                    <input formControlName="promoCode" type="text" id="promo-code" placeholder="Enter Code"/>
                    <button *ngIf="!cart?.offers?.length" (click)="applyPromoCode()" type="submit" class="text-link large">Apply</button>
                    <button *ngIf="cart?.offers?.length" (click)="removePromoCode()" type="submit" class="text-link large">Remove</button>
                </div>

                <h2 class="heading-6">Credit Card Payment Details</h2>

                <label class="pill" for="card-detail-options" *ngIf="availablePaymentMethods.length">Card Details</label>
                
                <div class="flex-container credit-card-options" *ngIf="availablePaymentMethods.length">
                    <ul id="card-detail-options" class="x-small" *ngFor="let card of availablePaymentMethods">
                        <li *ngIf="card.id && card.cardLast4" [ngClass]="cart?.selectedItems[0]?.selectedPaymentMethod?.id == card?.id ? 'active' : 'inactive' " class="visa" (click)="selectPaymentMethod(card)">
                            <strong>{{card?.cardBrand}} •••• {{card?.cardLast4}} <span>Expires {{card?.cardExpMonth}}/{{card?.cardExpYear}}</span></strong>
                            <span *ngIf="card?.cardIsDefault" class="pill fill small">Default</span>
                        </li>
                    </ul>
                    
                    
                </div>

                <form [formGroup]="paymentForm">

                    <ul class="grid credit-card-details">
                        <li>
                            <label class="pill" for="name">Name On The Card*</label>
                            <input formControlName="name" type="text" id="name" placeholder="Name"/>
                            <div class="required" *ngIf="paymentForm.controls.name.invalid && paymentForm.controls.name.touched">
                                <div *ngIf="paymentForm.controls.name.errors?.required">Name is required</div>
                            </div>
                        </li>
                        <li>
                            <label class="pill" for="card-details">Card Details*</label>
                            <input mask='0000 0000 0000 0000 000' [validation]="false" formControlName="number" type="text" id="card-details" placeholder="Card Number"/>
                            <div class="required" *ngIf="paymentForm.controls.number.invalid && paymentForm.controls.number.touched">
                                <div *ngIf="paymentForm.controls.number.errors?.required">Card number is required</div>
                                <div *ngIf="paymentForm.controls.number.errors?.minlength || paymentForm.controls.number.errors?.maxlength || paymentForm.controls.number.errors?.invalidCard">Invalid card</div>
                            </div>
                        </li>
                        
                        <li>
                            <label class="pill" for="card-exp">Expiration Date*</label>
                            <input  maxLength="7" minLength="7" (input)="onExpiryDateInput()" formControlName="expiry" type="text" id="card-exp" placeholder="MM/YYYY"/>
                            <div class="required" *ngIf="paymentForm.controls.expiry.invalid && paymentForm.controls.expiry.touched">
                                <div *ngIf="paymentForm.controls.expiry.errors?.required">Expiration date is required</div>
                                <div *ngIf="!paymentForm.controls.expiry.errors?.required && paymentForm.controls.expiry.errors?.invalidExpiry">Expiration date is invalid. (MM/YYYY)</div>
                            </div>
                        </li>
                        
                        <li *ngIf="paymentForm.controls.number.valid && paymentForm.controls.expiry.valid">
                            <label class="pill" for="card-cvv">CVV*</label>
                            <input maxLength="4" minLength="3" formControlName="cvv" type="tel" id="card-cvv" placeholder="****"/>
                            <div class="required" *ngIf="paymentForm.controls.cvv.invalid && paymentForm.controls.cvv.touched">
                                <div *ngIf="paymentForm.controls.cvv.errors?.required">CVV is required</div>
                                <div *ngIf="!paymentForm.controls.cvv.errors?.required && paymentForm.controls.cvv.errors?.invalidCVV">Invalid CVV</div>
                            </div>
                        </li>

                        <li *ngIf="paymentForm.controls.number.valid && paymentForm.controls.expiry.valid">
                            <label class="pill" for="postal-code">Zip Code*</label>
                            <input maxLength="5" minLength="5" formControlName="postal_code" type="text" id="postal-code" placeholder="Zip Code"/>
                            <div class="required" *ngIf="paymentForm.controls.postal_code.invalid && paymentForm.controls.postal_code.touched">
                                <div *ngIf="paymentForm.controls.postal_code.errors?.required">Zip code is required</div>
                                <div *ngIf="paymentForm.controls.postal_code.errors?.minlength || paymentForm.controls.postal_code.errors?.maxlength">Zip code must be of 5 digits.</div>
                            </div>
                        </li>
                    </ul>
                </form>
            
            </div>
            <div class="continue-btn mobile-only">
                <span (click)="submitPaymentForm()"><a class="btn black">Purchase Membership</a></span>
            </div>
        </div>
  
        <!--Sidebar-->
        <aside [ngClass] = "!cart?.selectedItems?.length ? 'empty':'' ">
            <button class="mobile-only cart-summary flex-container" (click)="toggleMobileCart = !toggleMobileCart" [ngClass]="toggleMobileCart ? 'active' : ''">
                <span class="eyebrow small">Summary</span>
                <span class="cost">${{ cart?.summary?.total / 100 }}</span>
            </button>
  
            <div class="cart-info" [ngClass]="toggleMobileCart ? 'active' : ''">
                <h2 class="eyebrow small flex-container desktop-only">Membership</h2>
    
                <div class="flex-container amount">
                <span>
                    <strong>{{cart?.selectedItems[0]?.item?.name}}</strong>
                    <span class="pill">Monthly Payment (Due Today)</span>
                </span>
    
                <span class="cost">
                    {{sharedService.formatPrice(cart?.summary?.subtotal) | currency:'USD':'symbol'}}
                    </span
                >
                </div>
    
                <div *ngIf="cart?.summary?.taxAmount && cart?.summary?.taxAmount != 0" class="flex-container cart-tax cart-total">
                <span class="eyebrow small">Tax</span>
                <p class="small">
                    {{sharedService.formatPrice(cart?.summary?.taxAmount ) | currency:'USD':'symbol'}}
                   
                </p>
                </div>
    
                <div *ngIf="cart?.summary?.discountAmount > 0" class="flex-container cart-tax cart-total">
                <span class="eyebrow small">Discount</span>
                <p class="small">{{sharedService.formatPrice(cart?.summary?.discountAmount) | currency:'USD':'symbol'}}</p>
                </div>
    
                <div class="flex-container cart-total">
                <span class="eyebrow small">Total</span>
                <p class="small">
                    {{sharedService.formatPrice(cart?.summary?.total ) | currency:'USD':'symbol'}}
                </p>
                </div>
    
                <!-- <div class="next-billing-date">Next billing date: {{ nextBilling }}</div> -->
            </div>
  
            <div [formGroup]="couponForm" class="desktop-only flex-container promo-code">
                <label class="pill small" for="promo-code">Promo Code</label>
                <input formControlName="promoCode" type="text" id="promo-code" placeholder="Enter Code"/>
                <button *ngIf="!cart?.offers?.length" (click)="applyPromoCode()" type="submit" class="text-link large">Apply</button>
                <button *ngIf="cart?.offers?.length" (click)="removePromoCode()" type="submit" class="text-link large">Remove</button>
            </div>
            
            <span (click)="submitPaymentForm()"><a class="btn black desktop-only">Purchase Membership</a></span>
        </aside>
      </form>
    </section>
</main>
  