import { Component } from '@angular/core';
import { SharedService } from 'src/app/shared-component/shared.service';
import { DashboardService } from '../dashboard.service';

@Component({
  selector: 'app-purchases',
  templateUrl: './purchases.component.html',
  styleUrls: ['./purchases.component.scss']
})
export class PurchasesComponent {

  activeTab = 2;
  inStorePurchaseOrders:any = [];

  constructor(public sharedService:SharedService, public dashboardService:DashboardService){
    dashboardService.inStorePurchaseOrders().then((res:any)=>{
      if(!res.errors){
        res.data.orders.edges.map((order:any)=>{
          let filterItems:any = [];
          order.node.lineGroups.map((lineGroup:any)=>{
            filterItems = [...filterItems, ...lineGroup.lines.filter((line:any)=> line.productId || line.serviceId)]
          })
          order.orders = filterItems
        })
        this.inStorePurchaseOrders = res.data.orders.edges.filter((order:any)=> order.orders.length);
      }
    });
  }

  changeTab(tab:number){
    this.activeTab = tab;
  }

}
