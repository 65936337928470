import { Component, Input } from '@angular/core';
import { SharedService } from 'src/app/shared-component/shared.service';
import { DashboardService } from '../dashboard.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss']
})
export class AppointmentComponent {

  cancel:boolean = false;
  activeTab = 1;

  constructor(public dashboardService: DashboardService, private sharedService:SharedService,private route: ActivatedRoute){
    // this.getAppointments();
  }

  // getAppointments(){
  //   this.dashboardService.getAppointmentsList().subscribe((res:any)=>{
  //     if(!res.errors){
  //       this.dashboardService.$myAppointments.next(res.data.myAppointments.edges);
  //     }else{
  //       const title = 'Information';
  //       const message = res.errors[0].message;
  //       this.sharedService.showNotification(title, message);
  //     }
  //   })
  // }
  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
        let tab = params['tab'] ? +params['tab'] : 1; 
        this.changeTab(tab);
    });
}




  changeTab(tab:number){
    this.activeTab = tab;
  }

}
