import { Component, Input } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { SharedService } from 'src/app/shared-component/shared.service';
import { BookingService } from '../../booking.service';
import { ModalAddonDetailComponent } from './modal-addon-detail/modal-addon-detail.component';
import { TrackingService } from '../../tracking.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-addons-list',
  templateUrl: './addons-list.component.html',
  styleUrls: ['./addons-list.component.scss']
})
export class AddonsListComponent {
 
  @Input() cart:any;
  @Input() addons:any;
  @Input() client:any;


  serviceDetailModalRef!: MdbModalRef<ModalAddonDetailComponent> | null;
  modalConfig: any = {
    animation: true,
    backdrop: true,
    containerClass: "right",
    data: {},
    ignoreBackdropClick: false,
    keyboard: true,
    modalClass: "modal-top-right",
  }; 

  constructor(private trackingService:TrackingService, public sharedService:SharedService, 
    private bookingService:BookingService, private modalService: MdbModalService){
      //console.log("addons22",this.addons);
    }

  addAddon(service:any, guestId:string|null){
    if(!service.selected){
      const payload = {
        id:service.id,
        staffId:null,
        guestId:guestId ? guestId : null
      }
      this.bookingService.ngxUiLoaderService.start();
      this.bookingService.addItemInCart(payload).subscribe((res:any)=>{
        if(!res.errors){
          service.category = 'addon';
          this.trackingService.addItem(service);
          const cartId = this.sharedService.getLocalStorageItem('cartId');
          if(cartId){
            this.bookingService.getCartDetail().subscribe((res:any)=>{
              if(!res.errors){
                this.bookingService.clientCart$.next(res.data.cart);
              }
              this.bookingService.ngxUiLoaderService.stop();
            });
          }
        }else{
          this.sharedService.showNotification('Errors', res.errors[0].message);
          this.bookingService.ngxUiLoaderService.stop();
        }
      });
    }/* else{
      const title = 'Add-on already added';
      const message = 'please choose another add-on to add in cart';
      this.sharedService.showNotification(title, message);
    } */
  }

  addAddonForAll(addon:any){

    if(addon.selected){
      this.removeItem(addon);
      
    }
    let isSameService = this.sharedService.getLocalStorageItem('isSameService');
    const selectedItemsWithoutAddons = this.cart.selectedItems.filter((item: any) => item.addons.length == 0 && !item.guestId);
    const selectedItemsWithoutAddonsGuest = this.cart.selectedItems.filter((item: any) => item.addons.length == 0 && this.client.id == item.guestId);
    const countMe = selectedItemsWithoutAddons.length;
    const countGuest = selectedItemsWithoutAddonsGuest.length;

    // console.log("selectedItemsWithoutAddonsGuest => ", selectedItemsWithoutAddonsGuest);

    if(isSameService == 'true'){
      if(countMe<4) {
      this.addAddon(addon, null);
      let guests = this.cart.guests;
      guests.map((guest:any)=>{
        const selectedItem = this.cart.selectedItems.filter((item: any) => item.guestId == guest.id);
        
        let id = addon.id.substr(addon.id.lastIndexOf(":") + 1)
        let newId = selectedItem[0].id.substr(selectedItem[0].id.lastIndexOf(":") + 1)
        addon.id = addon.id.replace(id, newId);

        this.addAddon(addon, guest.id);
      })
      }else{
        if(!addon.selected){
        const title = 'Add-on limit';
        const message = 'You can add up to 4 add-ons per user only!';
        this.sharedService.showNotification(title, message);
        }
        
      }
    }else{
      if(countGuest<4) {
      this.addAddon(addon, this.client.id);
      }else{
        if(!addon.selected){
        const title = 'Add-on limit';
        const message = 'You can add up to 4 add-ons per user only!';
        this.sharedService.showNotification(title, message);
        }
      }
    }
  }
  removeItem(item:any){
    
    let isSameService = this.sharedService.getLocalStorageItem("isSameService");
    if(isSameService == 'false' || !isSameService){
      const removeServiceRequests = this.cart.selectedItems
      .filter((selectedItem: any) => selectedItem.item.id === item.serviceID).map((service:any)=>{

      this.bookingService.ngxUiLoaderService.start();
      this.bookingService.removeItemInCart(service.id).subscribe((res:any)=>{
        if(!res.errors){
          /* item.item.listPrice = item.price;
          item.item.category = this.sharedService.getServiceCategoryName(item, this.cart.availableCategories) == '' ? 'addon' : this.sharedService.getServiceCategoryName(item, this.cart.availableCategories);
          this.trackingService.removeItem(item.item); */
          this.bookingService.getCartDetail().subscribe((res:any)=>{
            if(!res.errors){
              this.bookingService.clientCart$.next(res.data.cart);
            }
            this.bookingService.ngxUiLoaderService.stop();
          });
        }else{
          this.bookingService.ngxUiLoaderService.stop();
          this.sharedService.showNotification('Errors', res.errors[0].message);
        }
      });
    });
    }else{
      const removeServiceRequests = this.cart.selectedItems
      .filter((selectedItem: any) => selectedItem.item.id === item.serviceID).map((service:any)=>{
        
        /* 
        item.item.listPrice = item.price;
        item.item.category = this.sharedService.getServiceCategoryName(item, this.cart.availableCategories) == '' ? 'addon' : this.sharedService.getServiceCategoryName(item, this.cart.availableCategories);
        this.trackingService.removeItem(item.item); */
        return this.bookingService.removeItemInCart(service.id);
      });
      this.bookingService.ngxUiLoaderService.start();
      forkJoin(removeServiceRequests).subscribe(()=>{
        this.sharedService.setLocalStorageItem('isSameService', 'true');
        this.bookingService.getCartDetail().subscribe((res:any)=>{
          if(!res.errors){
            this.bookingService.clientCart$.next(res.data.cart);
          }
          this.bookingService.ngxUiLoaderService.stop();
        });
      })
      
    }
  }

  addonDetail(addon: any) {
    this.modalConfig.data.addon = addon;
    this.modalConfig.data.client = this.client;
    this.serviceDetailModalRef = this.modalService.open(
      ModalAddonDetailComponent,
      this.modalConfig
    );
    addon.category = 'addon';
    this.trackingService.viewItem(addon);
  }

}
 