<div class="date-slots" [ngClass]="showFullCalendar ? 'active' : ''">

    <!-- <div  *ngIf="!toggleFullCalendar" class="cal-loader"> <img class="desktop-only" width="22px" src="assets/Iphone-spinner.gif"></div>
     -->
    <div class="week-chooser">
        
        <button class="arrow last-week" (click)="previousMonth()"><span class="accessibility">Last Week</span></button>
        <span class="eyebrow small">{{currentMonth.format('MMMM YYYY')}}</span>
        <button class="arrow next-week" (click)="nextMonth()"><span class="accessibility">Next Week</span></button>
        <button id="show-calen" class="show-calendar" (click)="toggleFullCalendar = !toggleFullCalendar" [ngClass]="{'active': toggleFullCalendar}">
            <span>{{toggleFullCalendar ? 'Hide' : 'Show'}} Calendar</span>
        </button>
        
        
        <!--Calendar Popup-->
        <!-- <app-calendar-popup class="calendar-pop" *ngIf="showFullCalendar" [selectedDate]="selectedDate" (selectDateEvent)="selectDate($event)" (nextMonthEvent)="nextMonth()" (prevMonthEvent)="previousMonth()" [currentMonth]="currentMonth"[calendar]="calendar"></app-calendar-popup>
       -->
    </div>
    
    <!--Days of the Week-->    
    <div class="week-container">
        <fieldset class="flex-container" *ngFor="let week of calendar | toggleCalendar:toggleFullCalendar:selectedWeek">
           
            <div class="date-size" *ngFor="let day of week">
                <div [ngClass]="day.isToday ? 'active' : ''" class="flex-container expand-tile">
                    <input [checked]="selectedDate?.fullDate == day.fullDate ? true : false" (change)="selectDate(day)" [id]="day.fullDate" [disabled]="!day.isAvailable" class="accessibility" name="weekday" type="radio"/>
                    <label [for]="day.fullDate" class="heading-6">
                        <span class="pill">{{day.day}}</span> {{day?.date}}
                    </label>
                </div>
            </div>
        </fieldset>
    </div>
</div>