import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { BookingService } from '../../booking.service';

@Component({
  selector: 'app-services-addons-tabs',
  templateUrl: './services-addons-tabs.component.html',
  styleUrl: './services-addons-tabs.component.scss'
})
export class ServicesAddonsTabsComponent {
  tab: number = 0;
  @Input() isAddonEnable: boolean = false;
  @Input() reset: any;
  @Input() cart: any;
  @Input() client: any;
  @Output() changeTabEvent = new EventEmitter<string>();
  categories: Array<string> = [];
  activeTabIndex: number = 0; // Track the active tab index

  constructor(private bookingService:BookingService) { 
  

  }

  changeTab(tab: any,cat:any) {
    this.tab = tab;
    this.changeTabEvent.emit(tab);
    const element = document.getElementById(cat);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    this.tab = tab;
    
  }
  @HostListener('window:scroll', [])
  onScroll() {
    const addons = document.querySelectorAll('h6.heading-6.target');
    
    addons.forEach((addon, index) => {
      const isVisible = this.isElementInViewport(addon.id);
      if (isVisible) {
        this.tab = index; // Update active tab index if visible
      }
    });
  }

 
  isElementInViewport(elementId: string): boolean {
    const element = document.getElementById(elementId);
    if (!element) return false;

    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  get categorizeAddons() {
    if (this.cart && this.cart.selectedItems) {
      let addonsList: any = this.cart.selectedItems.filter((item: any) => {
        if (this.client == 'me') {
          return item.guestId == null && !item.item.optionGroups.length
        } else {
          return item.guestId == this.client.id && !item.item.optionGroups.length
        }
      })[0].addons;
      let categories = Array.from(new Set(addonsList.map((option: any) => option.optionGroups[0].name)));
     
      return categories;

    } else {
      return []
    }
  }

}
