<app-header mainTitle="Gift Cards"></app-header>
<!--Main Content-->

<main id="main">
  <section class="congrats gift-card-review">
    <div class="container">
      <h2 class="heading-3">
        Your gift is in good hands.
        <span>Your gift card has been purchased and will be sent shortly!</span>
      </h2>

      <ul class="grid details">
        <!--Services Info-->

        <li>
          <h3 class="eyebrow small">Gift Card</h3>

          <div class="cart-info gift-card-info">
            <div class="flex-container amount">
              <span class="small" style="word-break: break-word;">
                <!-- <strong>{{this.cart?.selectedItems[0]?.item?.name}}</strong> -->
                <strong>{{sharedService.getGiftCardName()}}</strong>
              </span>

              <span class="cost">{{sharedService.formatPrice(this.cart?.summary?.subtotal) | currency:'USD':'symbol'}}</span>
            </div>

            <div class="flex-container cart-subtotal cart-total" *ngIf="(cart?.offers?.length && this.cart?.summary?.discountAmount) || (cart?.summary?.taxAmount && cart?.summary?.taxAmount != 0)">
              <span class="eyebrow small">Subtotal</span>
              <p class="small">{{sharedService.formatPrice(this.cart?.summary?.subtotal) | currency:'USD':'symbol'}}</p>
            </div>

            <div class="flex-container cart-subtotal cart-total" *ngIf="this.cart?.summary?.discountAmount">
              <span class="eyebrow small">Discount</span>
              <p class="small">-{{sharedService.formatPrice(this.cart?.summary?.discountAmount) | currency:'USD':'symbol'}}</p>
            </div>

            <div class="flex-container cart-tax cart-total" *ngIf="cart?.summary?.taxAmount && cart?.summary?.taxAmount != 0">
              <span class="eyebrow small">Tax</span>
              <p class="small">{{sharedService.formatPrice(this.cart?.summary?.taxAmount) | currency:'USD':'symbol'}}</p>
          </div>
          <div class="flex-container cart-total" *ngIf="cart?.selectedItems?.length">
              <span class="eyebrow small">Total</span>
              <p class="small">{{sharedService.formatPrice(this.cart?.summary?.total) | currency:'USD':'symbol'}}</p>
          </div>
          </div>
        </li>

        <!--Details-->

        <li>
          <h3 class="eyebrow small">Details</h3>

          <div class="cart-info details-info">
            <div class="flex-container recipient">
              <span class="label">To <strong>{{cart?.selectedItems[0].emailFulfillment?.recipientName}}</strong></span>
              <span class="email small">{{cart?.selectedItems[0].emailFulfillment?.recipientEmail}}</span>
            </div>

            <div class="flex-container sender">
              <span class="label">From <strong>{{cart?.selectedItems[0].emailFulfillment?.senderName}}</strong></span>
              <!-- <span class="email small">angel@studiosimpati.co</span> -->
            </div>

            <div class="flex-container message">
              <span class="label">Message</span>
              <span class="message small"
                >{{cart?.selectedItems[0].emailFulfillment?.messageFromSender}}</span
              >
            </div>
          </div>
        </li>
      </ul>

      <div class="flex-container">
        <div class="book-view">
          <!-- <button class="btn black" (click)="clearCartBooking()">Book An Appointment</button> -->
          <button class="btn black" (click)="downloadPDF()">Download Gift card PDF</button>
          <button class="btn black" (click)="clearCart()" routerLink="/giftcards">Purchase Gift Cards</button>
        </div>
      </div>

      <!--CTAs-->

      <div class="ctas single">
        <div> 
          <h3 class="heading-4 small face-icon">
            <span class="eyebrow small face-icon">ASK AIDA</span>
            Tell us your skincare dreams and we'll show you where to glow.
          </h3>

          <a class="btn-primary" href="https://www.silvermirror.com/product-quiz/">Take the quiz</a>
        </div>
      </div>
    </div>
  </section>
</main>
