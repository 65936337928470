import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { finalize, forkJoin } from 'rxjs';
import { DashboardService } from './dashboard.service';
import { Title } from '@angular/platform-browser';
import { SharedService } from '../shared-component/shared.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(private sharedService:SharedService, private titleService: Title, public dashboardService:DashboardService, public router: Router){
    forkJoin({
      loyalty: this.dashboardService.getLoyalty(),
      services: this.dashboardService.getServices(),
      allAppointments: this.dashboardService.getAllAppointments(),
    }).pipe(finalize(() => {
      this.getAppointments();
    }))
    .subscribe((res:any)=>{
      dashboardService.$allAppointments.next(res.allAppointments);
      dashboardService.$servicesList.next(res.services.data.services.edges);
      dashboardService.$loyalty.next(res.loyalty.data);
    })
  }

  ngOnInit(): void {
    this.titleService.setTitle('My Dashboard - Silver Mirror');
  }

  getAppointments(){
    this.dashboardService.getAppointmentsList().subscribe((res:any)=>{
      if(!res.errors){
        this.dashboardService.$myAppointments.next(res.data.myAppointments.edges);
      }else{
        const title = 'Information';
        const message = res.errors[0].message;
        this.sharedService.showNotification(title, message);
      }
    })
  }

  getClientName(){
    return this.dashboardService.authUser.firstName;
  }

}
