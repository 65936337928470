import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { AuthService } from 'src/app/auth/auth.service';
import { BookingService } from '../booking.service';
@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})

export class BreadcrumbsComponent {
  
  steps=[
    {step:['location', 'service', 'coupon'], transition: 1},
    {step:'whoscoming', transition: 1},
    {step:'services', transition: 1},
    {step:'add-ons', transition: 1},
    {step:'schedule', transition: 1},
    {step:'review', transition: 1},
  ]
  route:any='';

  showSubMenu:boolean=false;
  visible: boolean = false;
  user:any;
  path:any;



  constructor(private titleService: Title, private router: ActivatedRoute, private _location: Location, public router1: Router, public authService: AuthService, public bookingService: BookingService) {
    this.titleService.setTitle('Booking - Silver Mirror');
    this.route=this.router.url;
    router.url.subscribe((url=>{
      const path = url.length ? url[0].path : 'location';
      this.path = url.length ? url[0].path : 'location';
      const index = this.steps.findIndex((step) => step.step.includes(path));
      this.steps[index].transition = 3;
      for (let i = index-1; i >= 0; i--){
        this.steps[i].transition = 2;
      }
    }));
  }
  ngOnInit(): void {
  this.authService.$AuthUser.subscribe((user)=>{
    if(user){
      this.user = user;
      setTimeout(() => {
    const UserPopupButton:any = document.querySelector('.menu-item-has-children');
        UserPopupButton.addEventListener('click', this.onclick.bind(this));
      }, 6000);
    }
    user ? this.user = user : null;
  })
}

  getRouteState(transition:number){
    switch (transition) {
      case 1:
        return ''
      case 2:
        return 'completed'
      case 3:
        return 'active'
      default:
        return ''
    }
  }

  navigateBack(){
    this._location.back();
  }

  onclick() {
    
    this.visible = !this.visible;
  }
  logout() {
    localStorage.clear();
    this.authService.$AuthUser.next(null);
    this.router1.navigateByUrl("/auth", {replaceUrl: true});
  }
  menuToggle(){
    this.showSubMenu = !this.showSubMenu
  }

}
