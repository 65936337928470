import { Component, Input } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { SharedService } from 'src/app/shared-component/shared.service';
import { DashboardService } from '../../dashboard.service';
import { RebookService } from '../../rebook.service';
import { ADDON_CATEGORIES } from 'src/app/constants/constants';

@Component({
  selector: 'app-appointment-tile',
  templateUrl: './appointment-tile.component.html',
  styleUrls: ['./appointment-tile.component.scss']
})
export class AppointmentTileComponent {

  @Input() appointment:any;
  @Input() cancel:boolean = true;
  @Input() rebook:boolean = false;
  @Input() canReschedule:boolean = false;
  @Input() activeTab:number = 1;
  @Input() appointmentType:string = 'past';
  toggleMobileList:boolean = false;
  zoomLink!:string;
  toggleOptions:boolean = false;


  constructor(private dashboardService: DashboardService, private router:Router, public sharedService:SharedService, private rebookService:RebookService){
    setTimeout(() => {
      this.getZoomLink();
    }, 100);
  }

  cancelAppointment(){
    const message = "Are you sure you want to cancel your appointment?"
      this.sharedService.openConfirmationAlert(message).then((res:any)=>{
        if(res){
          if(this.appointment?.cancellable){
            this.dashboardService.cancelAppointment(this.appointment.id, this.appointment.client.id).subscribe((res:any)=>{
              if(!res.errors){
                const title = 'Appointment canceled successfully';
                const message = 'Your appointment has been canceled successfully';
               // this.sharedService.showNotification(title, message);
                this.updateAppointments();
              }else{
                const title = 'Information';
                const message = res.errors[0].message;
                this.sharedService.showNotification(title, message);
              }
            })
          }else{
            const title = 'Appointments canceled within 24 hours are subject to 50% service fee.';
            const message = 'Please call our team to move forward with cancellation';
            this.sharedService.showNotification(title, message); 
          }
        }
      });
  }
  toggleCal(event: MouseEvent): void {
    // Prevent the event from bubbling up and triggering the parent <li>'s click handler
    event.stopPropagation();
    // Toggle the options visibility
    this.toggleOptions = !this.toggleOptions;
  }
  
  openLink(ev:any, link:any){
    ev.preventDefault();
    window.open(link, '_blank');
  }
  updateAppointments(){
    new Promise((resolve, reject)=>{
      this.dashboardService.getAllAppointments().subscribe((res:any)=> {
        this.dashboardService.$allAppointments.next(res);
        resolve(true);
      });
    }).then(()=>{
      this.dashboardService.getAppointmentsList().subscribe((res:any)=>{
        if(!res.errors){
          this.dashboardService.$myAppointments.next(res.data.myAppointments.edges);
        }else{
          const title = 'Information';
          const message = res.errors[0].message;
          this.sharedService.showNotification(title, message);
        }
      })
    });
  }

  navigateToReschedule(){
    const params:any = {
      state:{
        appointment:this.appointment,
      }
    }
    this.router.navigateByUrl('/dashboard/reschedule', params)
  }

  cancelBooking(){
    if(this.appointment.guestsAppointment){
      this.groupCancel();
    }else{
      this.cancelAppointment();
    }
  }

  groupCancel(){
    const message = `Are you sure you want to cancel all ${this.appointment?.guestsAppointment?.appointments?.length + 1} appointments?`
      this.sharedService.openConfirmationAlert(message).then((res:any)=>{
        if(res){
          if(this.appointment?.cancellable){
            let promises = [];
            this.appointment.guestsAppointment.appointments.map((apt:any)=>{
              promises.push(this.dashboardService.cancelAppointment(apt.id, apt.client.id));
            });
            promises.push(this.dashboardService.cancelAppointment(this.appointment.id, this.appointment.client.id));
            forkJoin(promises).subscribe(res=>{
              const title = 'Appointment canceled successfully';
              const message = 'Your appointment has been canceled successfully';
              this.sharedService.showNotification(title, message);
              this.updateAppointments();
            });
          }else{
            const title = 'Appointments canceled within 24 hours are subject to 50% service fee';
            const message = 'Please call our team to move forward with cancellation';
            this.sharedService.showNotification(title, message); 
          }
        }
      });
  }

  rebookAppointment(){
    this.rebookService.rebookAppointment(this.appointment);
  }

  isAddon(service:any){
    const addonsCategories = ADDON_CATEGORIES;

    return addonsCategories.includes(service.service.category.name.toUpperCase())
  }

  getZoomLink(){
    if(this.appointment && this.appointment.id){
      const payload = {
        aptID: this.appointment.id
      }
      this.dashboardService.getZoomLink(payload).subscribe((res:any)=>{
        if(res.data && res.data.appointment.customFields.length){
          this.zoomLink = res.data.appointment.customFields[0].textValue;
        }
      })
    }
  }

}
