import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import { averta_regular, averta_bold, fright_disp_bold } from './fonts';

export interface PDF_INTERFACE {
  productName: string;
  amount: string,
  buyerName: string;
  buyerEmail: string;
  services: any,
  description:any,
  download: boolean
}

@Injectable({
  providedIn: 'root'
})
export class PdfService {

  pdf:any;
  pageWidth:any;
  pageHeight:any;

  constructor() {
    this.pdf = new jsPDF();
    this.pageWidth = this.pdf.internal.pageSize.getWidth();
    this.pageHeight = this.pdf.internal.pageSize.getHeight();
    jsPDF.API.events.push(['addFonts', this.callAddFont()]);
  }

  callAddFont = ()=>{
    try {
      this.pdf.addFileToVFS('Averta-Regular-normal.ttf', averta_regular);
      this.pdf.addFileToVFS('Averta-Bold-bold.ttf', averta_bold);
      this.pdf.addFileToVFS('Freight Disp Bold-bold.ttf', fright_disp_bold);
      this.pdf.addFont('Averta-Regular-normal.ttf', 'AvertaRegular', 'normal');
      this.pdf.addFont('Averta-Bold-bold.ttf', 'AvertaBold', 'bold');
      this.pdf.addFont('Freight Disp Bold-bold.ttf', 'FreightDispBold', 'bold');
  } catch (error) {
      console.error('Error adding fonts:', error);
  }
  }

  createPDF(param:PDF_INTERFACE, download:true|false){
    // Add Logo
    
    let x = 8;
    let y = 8;

    const url = 'assets/images/pdf-logo.png'; // logo Image in PNG format
    x = (this.pageWidth - 50) / 2;
    this.addImage(url, x, y, 50, 9.56);
    

    // Add Title
    this.pdf.setFontSize(26);
    this.pdf.setFont('FreightDispBold', 'bold');
    this.pdf.setCharSpace(.1);
    this.pdf.text(param.productName, this.pageWidth/2, 34, {align: 'center'});

    // Add Form
    this.createForm(param);

    // Add Footer
    //this.createFooter();

    // Save PDF
    if(download){
      this.pdf.save('Silver_Mirror_Package.pdf');
    }else{
      return this.pdf.output('datauristring');
    }

    // Preview PDF
    // this.pdfFrame.nativeElement.src = this.pdf.output('datauristring');
  }

  createForm(param:PDF_INTERFACE){

    this.pdf.setFontSize(12);
    this.pdf.setFont('AvertaRegular', 'normal');
    this.pdf.setCharSpace(.2);
    this.pdf.text("NAME: "+param.buyerName, 38.5, 60, {align: 'left'});
    /* this.pdf.setFillColor(214,235,255);
    this.pdf.roundedRect(8, 54, (this.pageWidth / 2 - 12), 10, 5.5, 5.5, 'F');
    this.pdf.setFontSize(11);
    this.pdf.setFont('AvertaRegular', 'normal');
    this.pdf.text(param.buyerName, 13.5, 60.5, {align: 'left'}); */

    /* this.pdf.setFontSize(8);
    this.pdf.setFont('AvertaRegular', 'normal');
    this.pdf.text("EMAIL", (this.pageWidth / 2 + 9.5), 50, {align: 'left'});
    this.pdf.setFillColor(214,235,255);
    this.pdf.roundedRect((this.pageWidth / 2 + 4), 54, (this.pageWidth / 2 - 12), 10, 5.5, 5.5 ,'F');
    this.pdf.setFontSize(11);
    this.pdf.setFont('AvertaRegular', 'normal');
    this.pdf.text(param.buyerEmail, (this.pageWidth / 2 + 9.5), 60.5, {align: 'left'}); */

    this.pdf.setFontSize(12);
    this.pdf.setFont('AvertaRegular', 'normal');
    this.pdf.text("EMAIL: " +param.buyerEmail, 38.5, 75, {align: 'left'});
    //this.pdf.setFillColor(214,235,255);
    //this.pdf.roundedRect(8, 76, (this.pageWidth  - 16), 10, 5.5, 5.5, 'F');
   /*  this.pdf.setFontSize(11);
    this.pdf.setFont('AvertaRegular', 'normal');
    this.pdf.text(`${param.buyerEmail}`, 13.5, 82.5, {align: 'left'}); */

   /*  this.pdf.setFontSize(8);
    this.pdf.setFont('AvertaRegular', 'normal');
    this.pdf.text("GIFT CARD AMOUNT", 13.5, 72, {align: 'left'});
    this.pdf.setFillColor(214,235,255);
    this.pdf.roundedRect(8, 76, (this.pageWidth / 2 - 12), 10, 5.5, 5.5, 'F');
    this.pdf.setFontSize(11);
    this.pdf.setFont('AvertaRegular', 'normal');
    this.pdf.text(`$${param.amount}`, 13.5, 82.5, {align: 'left'});
*/
    //this.pdf.setFontSize(8);
    //this.pdf.setFont('AvertaRegular', 'normal');
    //this.pdf.text("GIFT CARD CODE", (this.pageWidth / 2 + 9.5), 72, {align: 'left'});
    
 
    this.pdf.setFontSize(8);
    this.pdf.setFont('AvertaRegular', 'normal');
    //this.pdf.text("DESCRIPTION", 13.5, 94, {align: 'left'});
    this.pdf.setFillColor(214,235,255);
    this.pdf.roundedRect(35, 98, this.pageWidth - 70, 45, 5.5, 5.5, 'F');
    let splitMsg = this.pdf.splitTextToSize(param.description, 130);
    this.pdf.setFont('AvertaRegular', 'normal');
    this.pdf.setFontSize(11);
    this.pdf.text(splitMsg, 70, 105.5, {align: 'left', lineHeightFactor:1.67});


    this.pdf.setFillColor(255,227,119);
    this.pdf.roundedRect((this.pageWidth / 2 - 35), 93, (this.pageWidth / 2 - 34), 10, 5.5, 5.5, 'F');
    this.pdf.setFontSize(11);
    this.pdf.setFont('AvertaRegular', 'normal');
    this.pdf.text("PACKAGE INCLUDES:", (this.pageWidth / 2 ), 99.5, {align: 'center'});

   /*  this.pdf.setLineDashPattern([2,2], 0); .
    this.pdf.setDrawColor(75,72,73);
    this.pdf.line(16, 152, this.pageWidth - 16, 152); */
    this.pdf.setFontSize(9);
    this.pdf.setFont('AvertaRegular', 'normal');
    this.pdf.setCharSpace(.1);
    const formattedDate = this.getCurrentDateInMMDDYY();
    this.pdf.text("Package will expire after 90 days from the purchase date ("+formattedDate+"). Limit one per customer.", this.pageWidth/2, 155, {align: 'center'});
    this.pdf.setFontSize(9);
    this.pdf.setFont('AvertaRegular', 'normal'); 
    this.pdf.setCharSpace(.1);
    
    this.pdf.text("Cannot be combined with any other offer.", this.pageWidth/2, 160, {align: 'center'});


    this.pdf.setFontSize(9);
    this.pdf.setFont('AvertaRegular', 'normal'); 
    this.pdf.setCharSpace(.1);
    
    this.pdf.text("Book your next facial and show your receipt to the front desk to redeem.", this.pageWidth/2, 165, {align: 'center'});

    // this.pdf.roundedRect(8, 94, (this.pageWidth / 2 - 4), 15, 8, 8);
    // this.pdf.roundedRect(this.pageWidth/2 + 4, 94, (this.pageWidth / 2 - 12), 15, 8, 8);

    // this.pdf.roundedRect(8, 118, this.pageWidth - 24, 45, 8, 8);

    // this.pdf.setLineDashPattern([2,2], 0);
    // this.pdf.line(32, 177, this.pageWidth - 24,177);

   /*  this.pdf.setFontSize(11);
    this.pdf.setFont('AvertaBold', 'bold');
    this.pdf.text("About Silver Mirror", this.pageWidth/2, 163, {align: 'center'});

    // this.pdf.setFont('AvertaRegular', 'normal');
    const text = 'Silver Mirror, the original facial bar founded in 2016, has been changing the skincare game since its inception. By fusing state-of-the-art medspa technology with the mastery of world-class estheticians, our customized facial treatments are not only designed to fit your busy schedule but also adapt to your skin\'s unique needs, all while creating an experience of unrivaled luxury.'
    let splitText = this.pdf.splitTextToSize(text, 185);
    this.pdf.setFont('AvertaRegular', 'normal');
    this.pdf.text(splitText, this.pageWidth/2 -6, 171, {align: 'center', lineHeightFactor:1.67});

    const url = 'assets/images/gift-card-email-banner-round.jpg'; // logo Image in PNG format
    this.addImage(url, (this.pageWidth - 80) / 2, 198, 80, 50); */
  }

  createFooter(){
    let x = 8;
    let y = this.pageHeight - 34;
    this.pdf.setFont('AvertaRegular', 'normal');

    this.pdf.setFontSize(7);
    this.pdf.text('SILVER MIRROR LOCATIONS', x, y, {align: 'left'});
    this.pdf.setFontSize(8);

    this.pdf.setFont('AvertaBold', 'bold');
    const text1 = this.pdf.text('New York:', x, y+5, {align: 'left'});
    this.pdf.setFont('AvertaRegular', 'normal');
    this.pdf.text('Upper East Side  •  Flatiron  •  Bryant Park  •  Manhattan West', x+this.pdf.getTextWidth('New York:')+2, y+5, {align: 'left'});

    this.pdf.setFont('AvertaBold', 'bold');
    this.pdf.text('Washington, DC:', x, y+10, {align: 'left'});
    this.pdf.setFont('AvertaRegular', 'normal');
    this.pdf.text('Dupont Circle  •  Navy Yard  •  Penn Quarter', x+this.pdf.getTextWidth('Washington, DC:')+3, y+10, {align: 'left'});

    this.pdf.setFont('AvertaBold', 'bold');
    this.pdf.text('Miami:', x, y+15, {align: 'left'});
    this.pdf.setFont('AvertaRegular', 'normal');
    this.pdf.text('Brickell  •  Coral Gables', x+this.pdf.getTextWidth('Miami:')+2, y+15, {align: 'left'});

    this.pdf.setFontSize(6);
    this.pdf.text('MORE INFO', this.pageWidth - 8, y-4, {align: 'right'});
    const url = 'assets/images/gift-card-scan-code.jpg'; // logo Image in PNG format
    this.addImage(url, this.pageWidth - 20, y-2, 12, 12);    
    this.pdf.text('(888) 677-0055      |      SILVERMIRROR.COM', this.pageWidth - 11, y+14, {align: 'right'});
  }

  addImage(url:string, x:number, y:number, width:number, height:number){
    const logo = new Image();
    logo.src = url;
    this.pdf.addImage(logo, x, y, width, height);
  }
  getCurrentDateInMMDDYY() {
    const today = new Date();
  
    // Extract month, day, and year
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Add 1 for the month and pad with zero if needed
    const day = String(today.getDate()).padStart(2, '0'); // Pad with zero if needed
    const year = String(today.getFullYear()).slice(-2); // Get last two digits of the year
  
    return `${month}/${day}/${year}`;
  }
  // createPDF(param:PDF_INTERFACE){
  //   // Set Properties
  //   this.pdf.setProperties({
  //     title: "Silver_Mirror_Gift_Card.pdf"
  //   });
  //   // Add Logo
    
  //   let x = 8;
  //   let y = 8;

  //   const url = 'assets/images/pdf-logo.png'; // logo Image in PNG format
  //   x = (this.pageWidth - 50) / 2;
  //   this.addImage(url, x, y, 50, 10);
    

  //   // Add Title
  //   this.pdf.setFontSize(26);
  //   this.pdf.setFont('Georgia', 'bold');
  //   this.pdf.text('Facial Gift Card', this.pageWidth/2, 34, {align: 'center'});

  //   // Add Form
  //   this.createForm(param);

  //   // Add Footer
  //   this.createFooter();

  //   // Save PDF
  //   this.pdf.save('Silver_Mirror_Gift_Card.pdf');

  //   // Preview PDF
  //   // this.pdfFrame.nativeElement.src = this.pdf.output('datauristring');
  // }

  // createForm(param:PDF_INTERFACE){
  //   this.pdf.setFontSize(8);

  //   this.pdf.setFontSize(8);
  //   this.pdf.setFont('Georgia', 'bold');
  //   this.pdf.text("SENDER NAME", 13.5, 50, {align: 'left'});
  //   this.pdf.setFillColor(216,235,250);
  //   this.pdf.roundedRect(8, 54, (this.pageWidth / 2 - 12), 10, 5.5, 5.5, 'F');
  //   this.pdf.setFontSize(12);
  //   this.pdf.setFont('Georgia', 'normal');
  //   this.pdf.text(param.sender, 13.5, 60.5, {align: 'left'});

  //   this.pdf.setFontSize(8);
  //   this.pdf.setFont('Georgia', 'bold');
  //   this.pdf.text("RECIPIENT NAME", (this.pageWidth / 2 + 9.5), 50, {align: 'left'});
  //   this.pdf.setFillColor(216,235,250);
  //   this.pdf.roundedRect((this.pageWidth / 2 + 4), 54, (this.pageWidth / 2 - 12), 10, 5.5, 5.5 ,'F');
  //   this.pdf.setFontSize(12);
  //   this.pdf.setFont('Georgia', 'normal');
  //   this.pdf.text(param.receiver, (this.pageWidth / 2 + 9.5), 60.5, {align: 'left'});

  //   this.pdf.setFontSize(8);
  //   this.pdf.setFont('Georgia', 'bold');
  //   this.pdf.text("GIFT CARD AMOUNT", 13.5, 72, {align: 'left'});
  //   this.pdf.setFillColor(216,235,250);
  //   this.pdf.roundedRect(8, 76, (this.pageWidth / 2 - 12), 10, 5.5, 5.5, 'F');
  //   this.pdf.setFontSize(12);
  //   this.pdf.setFont('Georgia', 'normal');
  //   this.pdf.text(`$${param.amount}`, 13.5, 82.5, {align: 'left'});

  //   this.pdf.setFontSize(8);
  //   this.pdf.setFont('Georgia', 'bold');
  //   this.pdf.text("GIFT CARD CODE", (this.pageWidth / 2 + 9.5), 72, {align: 'left'});
  //   this.pdf.setFillColor(216,235,250);
  //   this.pdf.roundedRect((this.pageWidth / 2 + 4), 76, (this.pageWidth / 2 - 12), 10, 5.5, 5.5, 'F');
  //   this.pdf.setFontSize(12);
  //   this.pdf.setFont('Georgia', 'normal');
  //   this.pdf.text(param.code, (this.pageWidth / 2 + 9.5), 82.5, {align: 'left'});

  //   this.pdf.setFontSize(8);
  //   this.pdf.setFont('Georgia', 'bold');
  //   this.pdf.text("MESSAGE", 13.5, 94, {align: 'left'});
  //   this.pdf.setFillColor(216,235,250);
  //   this.pdf.roundedRect(8,98, this.pageWidth - 16, 45, 5.5, 5.5, 'F');
  //   let splitMsg = this.pdf.splitTextToSize(param.message, 130);
  //   this.pdf.setFont('Georgia', 'normal');
  //   this.pdf.setFontSize(12);
  //   this.pdf.text(splitMsg, 13.5, 105.5, {align: 'left'});

  //   this.pdf.setLineDashPattern([2,2], 0);
  //   this.pdf.line(16, 151, this.pageWidth - 16, 151);

  //   // this.pdf.roundedRect(8, 94, (this.pageWidth / 2 - 4), 15, 8, 8);
  //   // this.pdf.roundedRect(this.pageWidth/2 + 4, 94, (this.pageWidth / 2 - 12), 15, 8, 8);

  //   // this.pdf.roundedRect(8, 118, this.pageWidth - 24, 45, 8, 8);

  //   // this.pdf.setLineDashPattern([2,2], 0);
  //   // this.pdf.line(32, 177, this.pageWidth - 24,177);

  //   this.pdf.setFontSize(12);
  //   this.pdf.setFont('Georgia', 'bold');
  //   this.pdf.text("About Silver Mirror", this.pageWidth/2, 163, {align: 'center'});

  //   const text = 'Silver Mirror, the original facial bar founded in 2016, has been changing the skincare game since its inception. By fusing state-of-the-art medspa technology with the mastery of world-class estheticians, our customized facial treatments are not only designed to fit your busy schedule but also adapt to your skin\'s unique needs, all while creating an experience of unrivaled luxury.'
  //   let splitText = this.pdf.splitTextToSize(text, 180);
  //   this.pdf.setFont('Georgia', 'normal');
  //   this.pdf.text(splitText, this.pageWidth/2, 171, {align: 'center'});

  //   const url = 'assets/images/gift-card-email-banner-round.png'; // logo Image in PNG format
  //   this.addImage(url, (this.pageWidth - 80) / 2, 198, 80, 50);
  // }

  // createFooter(){
  //   let x = 8;
  //   let y = this.pageHeight - 28;
  //   this.pdf.setFontSize(10);
  //   this.pdf.setFont('Georgia', 'normal');

  //   this.pdf.text('SILVER MIRROR LOCATIONS', x, y, {align: 'left'});
  //   this.pdf.text('New York: Upper East Side • Flatiron • Bryant Park • Manhattan West', x, y+6, {align: 'left', lineHeightFactor: 4});
  //   this.pdf.text('Washington, DC: Dupont Circle • Navy Yard • Penn Quarter', x, y+10, {align: 'left' , lineHeightFactor: 4});
  //   this.pdf.text('Miami: Brickell • Coral Gables', x, y+14, {align: 'left' , lineHeightFactor: 4});

  //   this.pdf.text('MORE INFO', this.pageWidth - 8, y-6, {align: 'right'});
  //   const url = 'assets/images/gift-card-scan-code.jpg'; // logo Image in PNG format
  //   this.addImage(url, this.pageWidth - 20, y-4, 12, 12);    
  //   this.pdf.text('(888) 677-0055 | SILVERMIRROR.COM', this.pageWidth - 8, y+12, {align: 'right'});
  // }

  // addImage(url:string, x:number, y:number, width:number, height:number){
  //   const logo = new Image();
  //   logo.src = url;
  //   this.pdf.addImage(logo, x, y, width, height);
  // }

}
