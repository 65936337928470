<app-header mainTitle="Login and Create User"></app-header>

<!--Main Content-->
		
    <main id="main">

        <section class="login">
            <div class="container flex-container">
        
                <article class="grid">
        
                    <span class="login-sparkles"></span>
        
                    <h1 class="heading-4">Login or Create an Account</h1>
        
                    
                    <div [formGroup]="emailForm" class="login-form" *ngIf="formType == 'email'">
                        <input type="email" formControlName="email" id="email" name="email" placeholder="Email Address" [(ngModel)]="email">
                        <div class="required" *ngIf="emailForm.controls.email.invalid && emailForm.controls.email.touched">
                            <div *ngIf="emailForm.controls.email.errors?.required">Email address is required</div>
                            <div *ngIf="emailForm.controls.email.errors?.email">Invalid Email address</div>
                        </div>
                        <input class="btn" type="submit" value="Continue" (click)="emailCheck()">
                    </div>
                    <div [formGroup]="passwordForm" class="login-form" *ngIf="formType == 'password'">
                        <input maxLength="30" minLength="8" formControlName="password" type="password" id="password" name="password" placeholder="Enter Password" [(ngModel)]="enteredPassword">
                        <div class="required" *ngIf="passwordForm.controls.password.invalid && passwordForm.controls.password.touched">
                            <div *ngIf="passwordForm.controls.password.errors?.required">Password is required</div>
                            <div *ngIf="passwordForm.controls.password.errors?.minlength || passwordForm.controls.password.errors?.maxlength">Invalid Password</div>
                        </div>
                        <input  class="btn" type="submit" value="Continue" (click)="checkPassword()">
                        <span style="text-align: center" *ngIf="formType == 'password'"><a class="text-link" (click)="changeForm('otp')">Login with OTP</a> | <a class="text-link" (click)="handleForgotPassword();">Forgot Password</a></span>
                        <span style="text-align: center"><a class="text-link" (click)="changeForm('email')">Back to Login</a></span>
                    </div>
                    <div [formGroup]="otpForm" class="login-form" *ngIf="formType == 'otp'">
                        <input maxLength="6" minLength="4" formControlName="otp" type="text" id="otp" name="otp" placeholder="Enter OTP" [(ngModel)]="enteredotp">
                        <div class="required" *ngIf="otpForm.controls.otp.invalid && otpForm.controls.otp.touched">
                            <div *ngIf="otpForm.controls.otp.errors?.required">OTP is required</div>
                            <div *ngIf="otpForm.controls.otp.errors?.minlength || otpForm.controls.otp.errors?.maxlength">Invalid OTP</div>
                        </div>
                        <input  class="btn" type="submit" value="Continue" (click)="checkOTP()">
                        <span style="text-align: center" *ngIf="formType == 'otp'"><a class="text-link" (click)="changeForm('otp', true)">Resend OTP</a>
                        </span>
                       <!--  <span  style="text-align: center;font-size: 14px;color: green;" *ngIf="this.authService.otpResend">OTP resend successfully.</span> -->

                        <span style="text-align: center"><a class="text-link" (click)="changeForm('email')">Login via Password</a></span>
                    </div>
                </article>
                <div class="grid">
                    <p class="xx-small">Your personal data will be used to support your experience throughout this website, to manage access to your account, and for other purposes described in our <a href="https://silvermirror.com/privacy-policy/">privacy policy</a>.</p>
                </div>
        
            </div>
        </section>

    </main>
<app-footer></app-footer>