<app-header mainTitle="Book an Appointment"></app-header>

<section class="services-flow">
	<div class="container grid">
		
		<!--Breadcrumbs-->
        <app-breadcrumbs class="breadcrumbs-container"></app-breadcrumbs>
		
		<app-mobile-menu class="user-head mobile-head"></app-mobile-menu>
		<div class="content-container">
			
			<!--Services Tabs-->
			
			<!--Guest Tabs-->
						
			<!--Mobile Tabs/Filters-->
			<app-services-tabs></app-services-tabs>
			
			<!--Services List-->
            <app-services-list *ngIf="nutritionCart?.availableCategories?.length" [services]="nutritionCart.availableCategories | filterService" (addServiceEvent)="addService($event)"></app-services-list>
			<!-- <app-addons-list *ngIf="tabs.service == 'addon'" [client]="tabs.guest" [cart]="cart"></app-addons-list> -->
		</div>
		
		<!--Sidebar-->
		<!-- <?php include('includes/services/sidebar-guests-empty.php'); ?> -->
        <aside *ngIf="nutritionCart" [ngClass]="(nutritionService.mobileCartView | async) ? 'active' : '' " [ngClass] = "!nutritionCart?.selectedItems?.length ? 'empty':'' ">
            <app-cart [cart]="nutritionCart"></app-cart>
			<span (click)="continue()"><a class="btn active">Continue</a></span>
			<button style="margin-bottom: 16px;" (click)="nutritionService.toggleMobileCart()" class="btn black view-cart mobile-only">View Cart
				<span class="item-count">{{this.nutritionCart.selectedItems.length}}</span>
			</button>
			<span (click)="continue()"><a class="btn active view-cart mobile-only">Continue</a></span>
        </aside>
		
	</div>
</section>

<!-- <app-footer></app-footer> --> 