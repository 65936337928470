import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { GiftcardComponent } from "./giftcard.component";
import { DetailComponent } from "./detail/detail.component";
import { ReviewComponent } from "./review/review.component";
import { ThanksComponent } from "./thanks/thanks.component";
// import { GiftcardsComponent } from "./giftcards.component";
// import { ReviewPurchaseComponent } from "./review-purchase/review-purchase.component";
// import { ThanksComponent } from "./thanks/thanks.component";
// import { AuthGuard } from "src/app/guards/auth.guard";
// import { GiftcardDetailComponent } from "./giftcard-detail/giftcard-detail.component";
// import { EmailDesignComponent } from "./email-design/email-design.component";

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: "giftcards",
        component: GiftcardComponent,
        children: [
          { path: "", redirectTo: "/giftcards/detail", pathMatch: 'full' },
          {
            path: "detail",
            children: [
              {
                path: "preset/:preset",
                component: DetailComponent,
              },
              {
                path: "",
                pathMatch: "full",
                component: DetailComponent
              }
            ]
          },
          {
            path: "review",
            component: ReviewComponent,
            // canActivate: [AuthGuard]
          },
          {
            path: "thanks",
            component: ThanksComponent,
            // canActivate: [AuthGuard]
          },
        ],
      },
    ]),
  ],
  exports: [RouterModule],
})
export class GiftcardRoutingModule {}
