<div class="popup-screen"></div>

<div class="popup card-details">
	<button class="close" (click)="serviceModalRef.close()"><span class="accessibility">Close Popup</span></button>
	
	<img class="desktop-only" [src]="'https://blvd.silvermirror.com/assets/cover-' + service.id + '.png'" alt=""/>
	<img class="mobile-only" [src]="'https://blvd.silvermirror.com/assets/thumb-' + service.id + '.png'" alt=""/>

	<!-- <div class="mobile-popup-image" [style.background-image]="'url(https://blvd.silvermirror.com/assets/cover-' + service.id + '.png)'"></div> -->
	
	<article>
		<ngx-simplebar >
			<h2 class="heading-6 flex-container"><span class="service-name" [innerHTML]="getServiceName(service?.name)"></span> 
				<!-- <span class="heading-6 small">{{sharedService.formatPrice(service.listPrice) | currency:'USD':'symbol' : '1.0-0'}}</span> -->
			</h2>
			<span class="pill fill outline">{{service?.category.replace("Facials ", "")}}</span>
			<span class="badge-img"><img [src]="'https://blvd.silvermirror.com/assets/badge-' + service.id + '.png'" /></span>
			
			<!-- <p class="x-small skin-types"><span class="pill">Skin Type</span> {{sharedService.getSkinType(service?.description)}}</p> -->
			<p class="x-small service-description" [innerHTML]="sharedService.getDescriptionOnly(service?.description)"></p>

			<!-- <p *ngIf="this.isBaseServiceAdded()" style="margin-bottom: 16px; font-style: italic;" class="x-small">Facials, Microchanneling and Chemical Peels cannot be performed together.</p> -->
		</ngx-simplebar>
		<!-- Add Service Feature Commented -->
		<button *ngIf="!cart?.selectedItems?.length" class="btn black mobile-only" (click)="addService()">Add Service</button>
		<!-- <button *ngIf="this.isBaseServiceAdded()" class="btn black" (click)="addAddons()">Add-on</button> -->
	</article>
	<div class="fixed-button">
		<button *ngIf="!cart?.selectedItems?.length" class="btn black" (click)="addService()">Add Service</button>
	</div>
</div>