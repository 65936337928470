import { NgFor } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar-memberships',
  standalone: true,
  imports: [NgFor],
  templateUrl: './progress-bar-memberships.component.html',
  styleUrl: './progress-bar-memberships.component.scss'
})
export class ProgressBarMembershipsComponent {

  @Input() totalMonths: number = 36; // Total months
  @Input() milestoneInterval: number = 6; // Interval for milestones
  @Input() progressMonths: number = 16; // Completed months

  milestones: number[] = []; // Milestone labels
  progressPercentage: number = 0; // Progress bar percentage

  ngOnInit(): void {
    this.calculateProgress();
    this.generateMilestones();
  }

  private calculateProgress(): void {
    this.progressPercentage = (this.progressMonths / this.totalMonths) * 100;
  }

  private generateMilestones(): void {
    for (let i = 0; i <= this.totalMonths; i += this.milestoneInterval) {
      this.milestones.push(i);
    }
  }
}
