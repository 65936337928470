import { ChangeDetectorRef, Component, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription, firstValueFrom, forkJoin, map } from 'rxjs';
import { NotificationService } from '../../notification.service';
import { Options, ChangeContext } from '@angular-slider/ngx-slider';
import { BookingService } from '../booking.service';
import { SharedService } from 'src/app/shared-component/shared.service';
import { CalendarComponent } from "../../shared-component/calendar/calendar.component";
import * as moment from "moment";
import { AuthService } from 'src/app/auth/auth.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RebookService } from 'src/app/dashboard/rebook.service';
import { DisadleContinueService } from 'src/app/disadle-continue.service';
import { GiftcardService } from 'src/app/giftcard/giftcard.service';


//import { LocationGroupSchedulePipe } from './location-group-schedule.pipe';


@Component({
  selector: 'app-scheduling',
  templateUrl: './scheduling.component.html',
  styleUrls: ['./scheduling.component.scss']
})
export class SchedulingComponent implements OnInit, OnDestroy {
  showInfo = false;
  cart:any;
  @ViewChild(CalendarComponent) calendarComponent:any;
  toggleBio:boolean=false;
  activeStaffId: string | null = null;
  availableDates:BehaviorSubject<any> = new BehaviorSubject([]);
  availableTimes:BehaviorSubject<any> = new BehaviorSubject([]);
  allStaffAvailableTimes:BehaviorSubject<any> = new BehaviorSubject([]);
  staffVarients:BehaviorSubject<any> = new BehaviorSubject([]);
  staffList:BehaviorSubject<any> = new BehaviorSubject([]); // For staff filter
  selectedTime:any;
  selectedDate:any;
  selectedStaff:any;
  // cartDetail:any = [];
  selectedItems:any = [];
  toggleLocationFilter:boolean = false;
  toggleTimeFilter:boolean = false;
  toggleStaffFilter:boolean = false;
  cacheMonths:any = [];
  currentIndex: number = 0;
  minValue: number = 0;
  maxValue: number = 23;
  options: Options = {
    floor: 0,
    ceil: 23,
    step:1,
    noSwitching: true,
    translate: (value: number): string => {
      if(value >= 0 && value <12){
        return value != 0 ? value + 'AM' : '12' + 'AM';
      }else{
        return (value - 12 == 0 ? '12' : value - 12) + 'PM'
      }
    }
  }; 
  estheticianPref:any = 1;
  locations:any = [];
  selectedLocation: any;
  locationTab:any = 'DC';
  transformedLocations:any;
  selectedVariants:any = [];
  clientCartSubscription!:Subscription;
  optionsSimplebar = { autoHide: false, scrollbarMinSize: 100 };
  loadingTime:boolean=false;
  // originalStaffData: any[] = [];
  toggleMobilePreferenceEsthetician:boolean=false;
  
  selectedTimes: { [staffId: string]: string } = {}; 
  selectedTimeId: string | null = null; 
  selectedStaffId: string | null = null; 
  filterText:string='';
  
  disableContinue = false;
  subscription: Subscription = new Subscription;

  ngOnInit(): void {
   /*  let wrapper = document?.querySelector('.simplebar-content-wrapper');
    if (wrapper) {
      wrapper.addEventListener('scroll', (event) => {
        //console.log("scrolled",event);
        this.ocScrollClose();

      });
    } */

    this.bookingService.ngxUiLoaderService.start();
    this.authService.$AuthUser.value ? this.rebookService.getLastAppointment() : null;
    if(this.bookingService.isMobile())
      {
        this.bookingService.clientCart$.subscribe((cart)=>{ 
          if(!cart.guests.length){
            this.toggleMobilePreferenceEsthetician=true;
          }
        });
        
    }
    // this.originalStaffData = [...this.staffData];

    /* setTimeout(() => {
      this.currentIndex = this.bookingService.selectedLocationTab;
    }, 100);  */   
    if(this.authService.$AuthUser.value){
      this.bookingService.takeCartOwnership().subscribe((res:any)=>{
        if(!res.errors){
          this.bookingService.updateCartDetail();
        }
      });
    }
    this.bookingService.updateCartDetail();
    // this.getBookableDates();
    this.clientCartSubscription = this.bookingService.clientCart$.subscribe(async (cart)=>{
      if(cart && cart.id){
        this.cart = cart;
        this.selectedItems = cart.selectedItems;
        // this.ifStaffVariantSelected();
        // this.getBookableDates();
        if(cart.selectedItems.length == 0){
          // window.location.href="/booking/services";
        }
        this.getStaffList();
        let offerId = this.cart?.offers[0]?.id;
        if(offerId){
          const resRemoveCartOffer:any = await firstValueFrom(this.bookingService.removeCartOffer(offerId));
          if(!resRemoveCartOffer.errors){
            this.bookingService.getCartDetail();
            this.sharedService.removeLocalStorageItem('promoCode');
          }
        }
      }
    })
    this.cart ? this.removeStaff() : null;
    //Location filter by service[START]
    this.bookingService.getCartDetail().subscribe((res: any) => {
      for (const selecteditem of res.data.cart?.selectedItems) {
             // this.bookingService.activeLocationFilterByService(selecteditem.item.id)
       
      }

      this.bookingService.getLocations().subscribe((res: any) => {
        if (!res.errors) {
          this.bookingService.locationList$.next([...res.data.locations.edges]);
        } else {
          this.sharedService.showNotification("Error getting locations: ", res.errors[0].message);
        }
      });

     /*  this.bookingService.locationList$.subscribe(locations => {
        if(locations && locations.length){
          this.locations = locations;
          const selectedLoc = this.locations.filter((loc:any)=> loc.node.id == this.selectedLocation);
          console.log("selectedLoc",selectedLoc[0].node.address.state);
          if(selectedLoc.length){
            switch (selectedLoc[0].node.address.state) {
              case 'NY':
                this.locationTab = 'NYC';
                this.expand(0, 'NY');
                break;
              case 'DC':
                this.locationTab = 'DC';
                this.expand(1, 'DC');
                break;
              case 'FL':
                this.locationTab = 'MIAMI';
                this.expand(2, 'MIAMI');
                break;
            
              default:
                this.locationTab = 'NYC';
                this.expand(0, 'NYC');
                break;
            }
            this.changeRef.detectChanges();

          }
        }
      }) */
  }); 
      //Location filter by service[END]
      const storedLocation = this.sharedService.getLocalStorageItem('selectedLocation');
      if (storedLocation) {
        this.selectedLocation = storedLocation;
      }

     /*  this.bookingService.locationList$.subscribe((locations: any[]) => {
        // Transform the locations using the locationGroupSchedule pipe
        var transformedLocations = this.locationGroupSchedulePipe.transform(locations, 'city', this.bookingService.activeLocationByService$.value);
        const selectedIndex = transformedLocations.findIndex(location => location.tabSelected === true);

        // Set the currentIndex based on the selectedIndex
        if (selectedIndex !== -1) {
          this.currentIndex = selectedIndex;
        }
      
      }); */
      //this.ngxUiLoaderService.stop();
      this.subscription = this.disadleContinueService.disableContinue$.subscribe(value => {
        this.disableContinue = value;
      });

  }
  ocScrollClose(){
    this.activeStaffId = null;
    document.body.classList.remove('staff-modal-open');
    //console.log('Clicked outside, popup closed');  // Debugging line
    Object.keys(this.activeStaffClass).forEach(id => {
      this.activeStaffClass[id] = false;
    });
  }

  ngOnDestroy(): void {
      this.clientCartSubscription.unsubscribe();
  }

  constructor(private changeRef:ChangeDetectorRef, public bookingService: BookingService, private ngxUiLoaderService:NgxUiLoaderService,
    private router:Router, public sharedService:SharedService, private disadleContinueService:DisadleContinueService,
    private authService:AuthService, private rebookService:RebookService){}
  
  getStaffVariantByServiceId(serviceId:string){
    this.cart.map((cart:any)=>{
      const service = cart.availableItems.filter((item:any)=>item.id == serviceId);
      service.length ? this.staffVarients.next(service[0].staffVariants) : null;
      return;
    })
  }

  async getBookableDates(staffIDs:any, staffChange?:boolean){
    //this.bookingService.ngxUiLoaderService.start();

    const locationId = this.cart.location.id;
    let currentMonth = this.calendarComponent?.currentMonth;
    let indexOfCacheMonth = this.cacheMonths?.length ? this.cacheMonths.findIndex((cache:any)=> cache?.isSame(currentMonth)) : -1;
    //console.log("indexOfCacheMonth",indexOfCacheMonth);
    if(indexOfCacheMonth == -1 || indexOfCacheMonth == 0 || !staffChange){
      let lowerRange = moment(currentMonth).startOf('month').format('YYYY-MM-DD');
      let upperRange = moment(currentMonth).endOf('month').format('YYYY-MM-DD');
      this.bookingService.getScheduleDates(locationId, lowerRange, upperRange, staffIDs).subscribe((res:any)=>{
        if(!res.errors){
          const cacheAvailableDates = this.availableDates.value;
          this.cacheMonths.push(currentMonth);
          // this.availableDates.next([...res.data.cartBookableDates, ...cacheAvailableDates]);  // cacheDates are removed because of esthetician filter 8-8-23
          this.availableDates.next([...res.data.cartBookableDates]);
          //console.log("getBookableDates not error");
          this.bookingService.ngxUiLoaderService.stop();
        }else{
          alert(res.errors[0].message);
        }
      })
    }
  }

  monthChange(ev?:any, staffChange?:boolean){
    let staffIDs = this.staffData.map((staff:any)=> 
      {
        if(staff.checked){
          const mainServiceId = this.cart.selectedItems.map((item:any)=>{
            if(item.guest == null && item.item.optionGroups.length == 0){
              return item.item.id
            }
          }).filter(Boolean)[0];
          const staffId = staff.id

          const staffVariantId = mainServiceId.substring(mainServiceId.lastIndexOf(":")+1) + staffId.substring(staffId.lastIndexOf(":"));
          return staffVariantId;
        }
      }
    ).filter(Boolean);
    this.getBookableDates(staffIDs, staffChange);
  }

  selectDate(ev:any){
    if (this.bookingService.isMobile()) {
      setTimeout(() => {
          const showCalenButton = document.getElementById('show-calen');
          if (showCalenButton) {
            showCalenButton.click();
          }
        
      }, 1000);
    }
    
    this.loadingTime=true;
    this.bookingService.ngxUiLoaderService.start();
    this.allStaffAvailableTimes.next([]);
    this.availableTimes.next([]);
    this.selectedDate = ev;
    let staffIDs = this.staffList.value.map((staff:any)=> 
      {
        if(staff.node.filter){
          const mainServiceId = this.cart.selectedItems.map((item:any)=>{
            if(item.guest == null && item.item.optionGroups.length == 0){
              return item.item.id
            }
          }).filter(Boolean)[0];
          const staffId = staff.node.id

          const staffVariantId = mainServiceId.substring(mainServiceId.lastIndexOf(":")+1) + staffId.substring(staffId.lastIndexOf(":"));
          return staffVariantId;
        }
      }
    ).filter(Boolean);

    const mainServiceId = this.cart.selectedItems.map((item:any)=>{
      if(item.guest == null && item.item.optionGroups.length == 0){
        return item.item.id
      }
    }).filter(Boolean)[0];

    const isHydraFacialAdded = this.cart.selectedItems.filter((item:any)=>{
      return item.item.name.toLowerCase().includes('hydrafacial');
    });
    const isHydraFacialDeluxeAdded = this.cart.selectedItems.filter((item:any)=>{
      return item.item.name.toLowerCase().includes('hydrafacial deluxe');
    });
    let promises = [];
    let tempStaff:any = null;

    if(!this.getSelectedStaffList().length && !this.cart.guests.length){
      tempStaff = this.staffList.value;
      this.staffList.value.forEach((staff:any)=>{
        const staffVariantId = mainServiceId.substring(mainServiceId.lastIndexOf(":")+1) + staff.node.id.substring(staff.node.id.lastIndexOf(":"));
        promises.push(this.bookingService.getScheduleTimes(ev.fullDate, [staffVariantId]));
      })
      isHydraFacialAdded.length ? promises.push(this.bookingService.dateAppointments(ev.fullDate)) : null;
    }else if(this.getSelectedStaffList().length && !this.cart.guests.length){
      const selectedStaff = this.getSelectedStaffList();
      tempStaff = selectedStaff;

      selectedStaff.forEach((staff:any)=>{
        const staffVariantId = mainServiceId.substring(mainServiceId.lastIndexOf(":")+1) + staff.id.substring(staff.id.lastIndexOf(":"));
        promises.push(this.bookingService.getScheduleTimes(ev.fullDate, [staffVariantId]));
      })
      isHydraFacialAdded.length ? promises.push(this.bookingService.dateAppointments(ev.fullDate)) : null;

    }else{
      isHydraFacialAdded.length ? promises = [this.bookingService.getScheduleTimes(ev.fullDate, staffIDs), this.bookingService.dateAppointments(ev.fullDate)] : promises = [this.bookingService.getScheduleTimes(ev.fullDate, staffIDs)];
    }

    forkJoin(promises).subscribe((res:any)=>{
      const tempAvailableTime = [];
      if(isHydraFacialAdded.length){
        const appts:any = res[res.length-1];
        for (let index = 0; index < res.length-1; index++) {
          let availableTimes:any = res[index];
          
          const filteredAppts = appts.data.appointments.edges.map((appt:any)=>{
            return appt.node.appointmentServices.filter((service:any)=>{
              return service.service.name.toLowerCase().includes('hydrafacial');
            }).length ? appt : null
          }).filter((val:any) => val);
          
          filteredAppts.forEach((appt:any)=>{
            const start = appt.node.startAt
            const end = appt.node.endAt
            
            const startDate = new Date(start);
            const endDate = new Date(end);
            const graceTime: number = Number(isHydraFacialDeluxeAdded.length ? 60 : 45);
           
            const matchedTimes = availableTimes.data.cartBookableTimes.filter((time:any)=>{
              return (startDate.getTime() - (graceTime*60*1000)) <= new Date(time.startTime).getTime() && new Date(time.startTime).getTime() < (endDate.getTime());
            });

            matchedTimes.forEach((time:any)=>{
              const index = availableTimes.data.cartBookableTimes.indexOf(time);
              if(index){
                availableTimes.data.cartBookableTimes.splice(index, 1);
              }
            });
          });

          if(tempStaff){
            availableTimes.data.cartBookableTimes.length ? tempAvailableTime.push({staff:tempStaff[index].node ? tempStaff[index].node : tempStaff[index], availableTimes:availableTimes.data.cartBookableTimes}) : null;
          }else{
            this.availableTimes.next(availableTimes.data.cartBookableTimes);
            this.staffVarients.next([]);
          }
        }
        this.allStaffAvailableTimes.next(tempAvailableTime);
      }else{
        if(tempStaff){
          let mappedData:any = [];
          res.map((data:any, index:number)=>{
            mappedData.push({staff:tempStaff[index].node ? tempStaff[index].node : tempStaff[index], availableTimes:data.data.cartBookableTimes})
          })
          this.allStaffAvailableTimes.next(mappedData);
        }else{
          this.availableTimes.next(res[0].data.cartBookableTimes);
        }
      }
      this.bookingService.ngxUiLoaderService.stop();
    })
   
     }

  selectTime(time:any){
    time.selected = true;
    this.selectedTime = time;
    const serviceId:string = this.selectedItems[0].id;
    const locationId = this.cart.location.id;
    this.selectedTimeId = time.id; 
    this.selectedVariants= [];
    this.bookingService.getCartStaffVarients(time.id, serviceId, locationId).subscribe((res:any)=>{
      if(!res.errors){
        this.bookingService.bookableTimeId=time;
        this.staffVarients.next(res.data.cartBookableStaffVariants);
        // this.ifStaffVariantSelected();
      }else{
        alert(res.errors[0].message);
      }
    })
  }
  
  
  ngAfterViewInit() {
    this.bookingService.locationList$.subscribe(locations => {
      if (locations && locations.length) {
        this.locations = locations;
        const selectedLoc = this.locations.find((loc: any) => loc.node.id === this.selectedLocation);

        if (selectedLoc) {
          setTimeout(() => {
            switch (selectedLoc.node.address.state) {
              case 'NY':
                this.locationTab = 'NYC';
                this.expand('NYC');
                break;
              case 'DC':
                this.locationTab = 'DC';
                this.expand('DC');
                break;
              case 'FL':
                this.locationTab = 'MIAMI';
                this.expand('MIAMI');
                break;
              default:
                this.locationTab = 'NYC';
                this.expand('NYC');
                break;
            }
            this.changeRef.detectChanges();
          }, 0);
        }
      }
    });
  }
  timeToMinutes(time:any) {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  }
  async selectTimeMe(time: any, staff: string): Promise<void> {
    //console.log("TIME", time);
    const timeOnly = time.startTime.split('T')[1].split('-')[0];
    const timeWithNoSeconds = timeOnly.substring(0, 5);
    const timeInMinutes = this.timeToMinutes(timeWithNoSeconds);
    const date = new Date(time.startTime);
    const dayOfWeek = new Intl.DateTimeFormat('en-US', {
      weekday: 'short',
      timeZone: 'America/New_York'
    }).format(date).toUpperCase();

    //console.log("timeonly", timeOnly);
    //console.log("timeWithNoSeconds", timeWithNoSeconds);
    const retrievedDiscount = this.sharedService.getLocalStorageItem('discount');
    const cartLocation = this.sharedService.getLocalStorageItem('selectedLocation');

    if (retrievedDiscount) {
      const parsedDiscount = JSON.parse(retrievedDiscount);
      const matchingRecord = parsedDiscount.find((record: { location: string | null; }) => record.location === cartLocation);
      /* console.log("Parsed discount:", parsedDiscount);
      console.log("cartLocation", cartLocation);
      console.log("matchingRecord", matchingRecord); */
      if (matchingRecord) {
        const startRange = this.timeToMinutes(matchingRecord.start_time);
        const endRange = this.timeToMinutes(matchingRecord.end_time);
        if (timeInMinutes >= startRange && timeInMinutes <= endRange) {
          //console.log(`The time ${timeWithNoSeconds} is between ${matchingRecord.start_time} and ${matchingRecord.end_time}.`);
          if (matchingRecord.valid_days.includes(dayOfWeek)) {
            this.sharedService.setLocalStorageItem('coupon', matchingRecord.coupon_code);
          } else {

            this.sharedService.removeLocalStorageItem('coupon');
             let offerId = this.cart?.offers[0]?.id;
                if(offerId){
                  const resRemoveCartOffer:any = await firstValueFrom(this.bookingService.removeCartOffer(offerId));
                  if(!resRemoveCartOffer.errors){
                    this.bookingService.getCartDetail();
                    this.sharedService.removeLocalStorageItem('promoCode');
                  }
                }
          }
        } else {
          //console.log(`The time ${timeWithNoSeconds} is not within the valid range.`);
          this.sharedService.removeLocalStorageItem('coupon');
          let offerId = this.cart?.offers[0]?.id;
          if(offerId){
            const resRemoveCartOffer:any = await firstValueFrom(this.bookingService.removeCartOffer(offerId));
            if(!resRemoveCartOffer.errors){
              this.bookingService.getCartDetail();
              this.sharedService.removeLocalStorageItem('promoCode');
            }
          }
        }
      } else {
        let offerId = this.cart?.offers[0]?.id;
        if(offerId){
          const resRemoveCartOffer:any = await firstValueFrom(this.bookingService.removeCartOffer(offerId));
          if(!resRemoveCartOffer.errors){
            this.bookingService.getCartDetail();
            this.sharedService.removeLocalStorageItem('promoCode');
          }
        }
      }
    }else{
      let offerId = this.cart?.offers[0]?.id;
      if(offerId){
        const resRemoveCartOffer:any = await firstValueFrom(this.bookingService.removeCartOffer(offerId));
        if(!resRemoveCartOffer.errors){
          this.bookingService.getCartDetail();
          this.sharedService.removeLocalStorageItem('promoCode');
        }
      }
    }

    this.selectedStaff = staff;
    time.selected = true;
    this.selectedTime = time;
    this.bookingService.bookableTimeId=time.id;
    this.selectedVariants= [];
    this.selectedTimeId = time.id; 
    const serviceId: string = this.selectedItems[0].id;
    const locationId = this.cart.location.id;
    this.bookingService.getCartStaffVarients(time.id, serviceId, locationId).subscribe((res: any) => {
      if (!res.errors) {
        this.staffVarients.next(res.data.cartBookableStaffVariants);
      } else {
        alert(res.errors[0].message);
      }
    });
  }

  isSelected(timeId: string,staffId:string): boolean {
    return this.selectedTimeId === timeId &&  this.selectedStaffId===staffId;
  }
  
  selectStaff(staff:any){
    this.estheticianPref = 2
    if(this.cart.guests.length){
      staff ? staff.staff.selected = true : null;  
      this.selectedStaff = staff?.staff;
    }else{
      staff ? staff.node.selected = true : null;
      this.selectedStaff = staff?.node;
    }
  }

  removeStaff(){
    return new Promise((resolve, reject)=>{
      let requests:any = [];
      // = this.selectedItems.length ? this.selectedItems[0].id : "";
      if(this.selectedItems.length){
        this.selectedItems.map((item:any)=>{
          requests.push(this.bookingService.updateItemInCart(item.id, null))
        });
      }
      forkJoin(requests).subscribe(()=> resolve(true));
    });
  }


  assignStaff(){
    const requests:any = [];

    if(this.cart.guests.length){

      this.cart.selectedItems.map((item:any)=>{
        const variantID = this.selectedVariants.filter((vari:any)=> {
          if(item.guest == null){
            return vari.user == 'me' && vari.variant != 'any'
          }else{
            return vari.user == item.guest.label && vari.variant != 'any'
          }
        })[0]?.variant;
        variantID ? requests.push(this.bookingService.updateItemInCart(item.id, variantID, item.guestId)) : null;
      });

    }else{
      let variantID = '';
      if(!this.selectedStaff){
        this.selectedStaff = this.staffVarients.value[0];
        variantID = this.staffVarients.value[0].id;
      }else{
        this.staffVarients.value.map((staff:any)=>{
          staff.staff.id == this.selectedStaff.id ? variantID = staff.id : null;
        })
      }
      const mySelectedItems = this.cart.selectedItems.filter((item:any)=> item.guestId == null);
      mySelectedItems.map((item:any)=>{
        requests.push(this.bookingService.updateItemInCart(item.id, variantID, null));
      });
    }

    return new Promise((resolve)=>{
      if(requests.length){
        forkJoin(requests).subscribe(()=>{
          resolve(true); 
        })
      }else{
        resolve(true);
      }
    })
  }

  reserveCart(){
   // window.scrollTo(0, 0);
    const bookableTimeId = this.selectedTime?.id;
    if(bookableTimeId){
      this.bookingService.ngxUiLoaderService.start();
        this.assignStaff().then(res=>{
          this.bookingService.reserveCartItems(bookableTimeId).subscribe((res:any)=>{
            if(!res.errors){
              if(this.bookingService.isMobile()){
                this.bookingService.toggleMobileCart();
                this.bookingService.ngxUiLoaderService.stop();
              }
              else{
              this.bookingService.ngxUiLoaderService.stop();
              this.router.navigateByUrl('booking/review');
            }
            }
          })
        });
    }else{
      //console.log("this.selectedDate",this.selectedDate);
      const title = !this.selectedDate?'Appointment date not selected' :'Appointment time not selected';
      const message = !this.selectedDate? 'Please choose an appointment date':'Please choose an appointment time';
      this.sharedService.showNotification(title, message);
    }
  }

  getStaffList() {
    //console.log("getStaffList called");
    this.bookingService.getStaffList().subscribe((res: any) => {
      if (!res.errors) {
        const mainServiceId = this.cart.selectedItems.map((item: any) => {
          if (item.guest == null && item.item.optionGroups.length == 0) {
            return item.item.id
          }
        }).filter(Boolean)[0];
        let staffVariants: any[] = [];
        // Iterate through availableCategories and availableItems to find staffVariants
        this.cart.availableCategories.forEach((category: { availableItems: any[]; }) => {
          category.availableItems.forEach(item => {
            if (item.id === mainServiceId) {
              // Add item.staffVariants to the staffVariants array
              staffVariants = item.staffVariants;
            }
          });
        });

        const staffVariantIds = staffVariants.map(variant => variant.staff.id);
        // Filter res array based on staffVariantIds
        const filteredRes = res.filter((item: { node: { id: any; }; }) => staffVariantIds.includes(item.node.id));

          // Add staffVariantId to filteredRes items
        const staffwithVariantIds =filteredRes.forEach((item: any) => {
        const matchingVariant = staffVariants.find(variant => variant.staff.id === item.node.id);
        if (matchingVariant) {
          item.node.staffVariantId = matchingVariant.id; // Add staffVariantId to the item
        }
      });

        const locationId = this.cart.location.id;
        let currentMonth = this.calendarComponent?.currentMonth;
        let lowerRange = moment(currentMonth).startOf('month').format('YYYY-MM-DD');
        let upperRange = moment(currentMonth).endOf('month').format('YYYY-MM-DD');

        // Assuming staffIDs is an array of IDs extracted from filteredRes
        const staffIDs = filteredRes.map((item: { node: any; }) => item);

        // Making the API call for each staff ID

        if(!this.cart.guests.length){
          let promises:any = [];
         // this.bookingService.ngxUiLoaderService.start();
          staffIDs.forEach((staffId:any) => {
            //promises.push(this.bookingService.getScheduleDates(locationId, lowerRange, upperRange, staffId.node.staffVariantId));
          });
    /*       forkJoin(promises).subscribe((response:any)=>{
            response.forEach((res:any, index:any) => {
              // Find the corresponding item in filteredRes based on staffId
              const foundItem = filteredRes.find((item: any) => item.node.id === staffIDs[index].node.id);
              // Update 'enable' key based on response
              if (!res.errors) {
                if(res.data.cartBookableDates.length){
                  //this.bookingService.ngxUiLoaderService.stop();
                staffIDs[index].node.enable = true; // or whatever logic you need
                }else{
                  staffIDs[index].node.enable = false; // or whatever logic you need
                }
              } else {
                
                alert(res.errors[0].message);
              }
              
            });
            this.bookingService.ngxUiLoaderService.stop();
          }) */
        }
        this.staffList.next(staffIDs);

        this.getBookableDates([], true);

      }
    })
  }

  getSelectedStaffCount(){
    let staffIDs = this.staffList.value.map((staff:any)=> 
      {
        if(staff.node.filter){
          return staff.node.id
        }
      }
    ).filter(Boolean);
    return staffIDs.length;
  }

  getSelectedStaff(){
    let staff = this.staffList.value.map((staff:any)=> 
      {
        if(staff.node.filter){
          return staff.node
        }
      }
    ).filter(Boolean);
    return staff[0];
  }

  filterStaff(ev:any, staff?:any){
    let staffs = this.staffList.value;
    staffs.map((staff:any)=>{
      staff.node.filter = false;
    });
    if(staff){
      staff.node.filter = true;
      this.staffList.next(staffs);
    }
    this.selectStaff(staff);
    this.selectedDate = null;
    this.availableTimes.next([]);
    this.staffVarients.next([]);
    this.monthChange(null, false);
    this.toggleStaffFilter = false;
  }

   changeTimeRange(changeContext: ChangeContext): void {
    // const min = changeContext.value;
    // const max = changeContext.highValue;
    // const date = new Date()
  } 

  clearStaffFilter(selection:boolean){
    let staffs = this.staffList.value;
    staffs.map((staff:any)=>{
      staff.node.filter = selection;
    });
    this.staffList.next(staffs);
    this.getBookableDates([], true)
  }

  clearTimeFilter(){
    this.minValue = 9;
    this.maxValue = 20;
  }

  closeMobileFilter(){
    this.toggleTimeFilter = false;
    this.toggleStaffFilter = false;
  }

  applyEstheticianFilter(){
    this.toggleStaffFilter = false;
    this.monthChange(null, false);
    this.availableTimes.next([]);
    this.staffVarients.next([]);
    this.selectedDate = null;
  }
  closeEstheticianFilter(){
    this.toggleStaffFilter = false;
  }
  changeEstheticianPreference(){
    if(this.estheticianPref == 1){
      let staffVarients = this.staffVarients.value;
      staffVarients.map((staff:any)=>staff.selected = false);
      this.staffVarients.next(staffVarients);
    }
  }

  selectEvent(ev:any){
    const staff = {
      node: ev
    }
    this.filterStaff(null, staff);
    this.changeRef.detectChanges();
  }

  onChangeSearch(ev:any){
    // this.selectedStaff = null;
  }

  get lastAppointmentStaff(){
    let lastAppt = this.rebookService.$lastAppointment.value;
    if(lastAppt && lastAppt.length){
      const lastApptStaff = lastAppt[0].node.appointmentServices.map((service:any)=>{
        return service.staff;
      });
      return lastApptStaff;
    }else{
      return []
    }
  }

  get staffData(){
    if(this.staffList.value.length){
      const lastApptStaff = this.lastAppointmentStaff;
      let authUser = this.authService.$AuthUser.value;
      const staffList = this.staffList.value.map((staff:any)=>{
        let preferredStaff = null;
        if(authUser){
          authUser.customFields && authUser.customFields[0] ? preferredStaff = authUser.customFields[0].textValue : null;
          preferredStaff ? (preferredStaff == staff.node.id ? staff.node.preferred = true : null) : null;
          if(lastApptStaff.length){
            lastApptStaff.forEach((apptStaff:any) => {
              apptStaff.id == staff.node.id ? staff.node.recent = true : null;
            });
          }
        }
        return staff.node;
      });
      return staffList
    }else{
      return []
    }
  }
  expand(locKey: string) {
    //console.log('Expanding tab:', locKey);
    this.locationTab = locKey;

    setTimeout(() => {
      this.changeRef.detectChanges();
    }, 0);
  }
  locationSelected(id: any) {
    const location = this.sharedService.getLocalStorageItem("selectedLocation");
    return location && location == id ? "active" : '';
  }
  selectLocation(id:any){
    const locationId = this.sharedService.getLocalStorageItem('selectedLocation');
    const cartId = this.sharedService.getLocalStorageItem('cartId');
    this.bookingService.getCartDetail().subscribe((res:any)=>{ 
    if(locationId == id && cartId){
      this.router.navigateByUrl('/booking/whoscoming');
    }else if(locationId != id && res.data?.cart.selectedItems.length>0){
      const message = "If you change the location, your cart will be clear.<br /> Are you sure you want to change the location?"
      this.sharedService.openConfirmationAlert(message).then((res:any)=>{
        if(res){
          //this.createCart(id);
        }else{
          this.router.navigateByUrl('/booking/whoscoming');
        }
      });
    }else{
      //this.createCart(id);
    }
  });
    this.sharedService.removeLocalStorageItem('isSameService');
  }
  addBodyClass(){
    if (this.toggleLocationFilter || this.toggleStaffFilter || this.toggleTimeFilter || this.toggleMobilePreferenceEsthetician) {
      document.body.classList.add('schedule-filter-active');
  } else {
      document.body.classList.remove('schedule-filter-active');
  }
  }
 

  staffSelectionChange(ev:any){
    this.allStaffAvailableTimes.next([]);
    // this.selectStaff(staff);
    this.selectedDate = null;
    this.availableTimes.next([]);
    this.staffVarients.next([]);
    this.monthChange(null, false);
    // this.toggleStaffFilter = false;
  }

  getSelectedStaffList(){
    return this.staffData.filter((staff:any)=> staff.checked);
  }
  unselectSelectedStaff(staff:any){
    this.staffData.forEach((staffItem: any) => {
      if (staffItem.id === staff.id) {
        staffItem.checked = false; 
        this.monthChange(null, false);
      }
    });
  }
  
  

  variantSelection(ev:any, guest:any){
    const data = {
      user: guest!= 'me' ? guest.label : guest,
      variant: ev.target.value
    }
    const index = this.selectedVariants.findIndex((variant:any) => {
      if(guest != 'me'){
        return variant.user == guest.label;
      }else{
        return variant.user == guest
      }
    });
    index == -1 ? this.selectedVariants.push(data) : null;
    this.selectedVariants.map((variant:any)=>{
      if(guest != 'me'){
        variant.user == guest.label ? variant.variant = ev.target.value : null;
      }else{
        variant.user == guest ? variant.variant = ev.target.value : null;
      }
    })
  }

  isVariantDisabled(variant:any, guest:any){
    const isSelected = this.selectedVariants.filter((vari:any)=>{
      if((vari.user != guest) && variant.id == vari.variant){
        return vari
      }
    })
    return isSelected.length;
  }
  async changeLocation(locationId: any) {
    const message = "Changing the location may affect service or add-on availability in your cart. Do you want to continue?";
    const confirmation = await this.sharedService.locationOpenConfirmationAlert(message);
    this.bookingService.ngxUiLoaderService.start();
    if (!confirmation) {
      if(this.sharedService.keep_my_cart_and_continue) {
        
        await this.bookingService.changeLocation(locationId);
        const storedLocation = this.sharedService.getLocalStorageItem('selectedLocation');
        if (storedLocation) {
          this.selectedLocation = storedLocation;
        }
        this.selectedDate = null;
        this.allStaffAvailableTimes.next([]);
        this.availableTimes.next([]);
        this.staffVarients.next([]);
        this.bookingService.ngxUiLoaderService.stop();
      }
    } else {
      this.bookingService.createCart(locationId).subscribe(async (res: any) => {
        this.sharedService.setLocalStorageItem('selectedLocation', locationId);
        this.sharedService.setLocalStorageItem('cartId', res.data.createCart.cart.id);
        this.router.navigateByUrl('/booking/whoscoming');
        
      });
    }
  
    // Ensure that getBookableDates is called regardless of the condition
    await this.getBookableDates([], true);
    this.bookingService.ngxUiLoaderService.stop();
    this.toggleLocationFilter = false;
  }
  
pickMyEsthetician(){
  this.toggleMobilePreferenceEsthetician=false;
  this.toggleStaffFilter=true;

  
}
pickAnyEsthetician(){
  this.toggleMobilePreferenceEsthetician=false;

}
activeStaffClass: { [key: string]: boolean } = {};

toggleBioStatus(staffId: any) {
  
  this.activeStaffId = staffId;
  if (staffId) {
    
    document.body.classList.add('staff-modal-open');
  } else {
   
    document.body.classList.remove('staff-modal-open');
  }
  // If staffId is null, deactivate all
  if (staffId === null) {
    Object.keys(this.activeStaffClass).forEach(id => {
      this.activeStaffClass[id] = false;
      this.showInfo =false;
    });
  } else {
    // Set all to false first
    Object.keys(this.activeStaffClass).forEach(id => {
      this.activeStaffClass[id] = false;
      this.showInfo =false;
    });

    // Activate the clicked staff id
    this.activeStaffClass[staffId] = true;
  }
}

isBioActive(staffId: any) {
if(this.activeStaffId === staffId)
  {
  return this.activeStaffId === staffId;
  }else{
    return '';
  }
}
@HostListener('document:click', ['$event'])
onClickOutside(event: Event) {
  const target = event.target as HTMLElement;

  // Check if the click happened inside the popup or on the icon
  const clickedInsidePopup = target.closest('.staff-bio-popup');
  const clickedOnIcon = target.closest('.staff-icon');

  // If the click was outside both the popup and the icon, close the popup
  if (!clickedInsidePopup && !clickedOnIcon && this.activeStaffId) {
    this.activeStaffId = null;
    document.body.classList.remove('staff-modal-open');
    
    Object.keys(this.activeStaffClass).forEach(id => {
      this.activeStaffClass[id] = false;
      this.showInfo =false;
    });
  }
}
getTextBio(text: any) {
  const parts = text.split('BIO:');
  if (parts.length > 1) {
    const bioText = parts[1].trim();
    return bioText;
  } else {
    // Handle the case where 'BIO:' is not found in the text
    return false;
  }
}
getBadgeBio(text: any) {
  const badgeMatch = text.match(/BADGE:([A-Z0-9-]+)/);
  if (badgeMatch) {
    return badgeMatch[1].split('-').map((part: string) => part.toLowerCase());
  }
  return null; 
}
getRoleBio(text: string): string[] | null {
  // Update the regex to match roles only and stop before 'BIO:' or any other unexpected content
  const roleMatch = text.match(/ROLE:([A-Za-z\s,]+)\n/);
  
  // If a match is found, split the roles by comma and trim any extra spaces
  return roleMatch ? roleMatch[1].split(',').map(role => role.trim()) : null;
}


}
