import { Component, Input } from '@angular/core';
import { DashboardService } from '../dashboard.service';

@Component({
  selector: 'app-loyalty',
  templateUrl: './loyalty.component.html',
  styleUrls: ['./loyalty.component.scss']
})
export class LoyaltyComponent {

  loyaltyData:any;

  constructor(public dashboardService: DashboardService){
    dashboardService.$loyalty.subscribe(data=>{
      this.loyaltyData = data;
    })
  }  

}
