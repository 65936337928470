import { Component } from '@angular/core';
import { PackageService } from '../package.service';
import { firstValueFrom } from 'rxjs';
import { SharedService } from 'src/app/shared-component/shared.service';
import { AuthService } from 'src/app/auth/auth.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TrackingService } from '../tracking.service';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent {

  enableStep2:boolean = false;
  giftcardsList:any = [];
  isCustomAmountSelected:boolean = true;
  customAmount:any;
  cart:any;
  user:any;
  today:any;
  paramPreset:any;
  giftcartsItem:any;
  recipientType:string = "someone";
  recipientForm!:FormGroup;
  giftCardDetails:any;
  isGiftVoucherSelected:boolean=false;

  constructor(private route: ActivatedRoute,private trackingService:TrackingService, public packageService:PackageService, private sharedService:SharedService, private authService:AuthService, private formBuilder:FormBuilder, private router:Router){
   
   
    this.route.params.subscribe(params => {
      this.paramPreset = params['preset'];
    });
  
   /*  this.packageService.getPackagesList().subscribe((giftcarts: any)=>{
      console.log("giftcarts",giftcarts);
      this.selectGiftCard(giftcarts.data.products.edges[0]);
      this.giftcartsItem = giftcarts.data.products.edges;
    
  }); */
  
 
    
    const giftCardDetail = localStorage.getItem('packageDetail');
      if(giftCardDetail){
        this.giftCardDetails = JSON.parse(giftCardDetail);
      }
    this.getToday();
    this._buildForm();
    const cartID = localStorage.getItem('packageCart');
    cartID ? this.getCartDetail() : null;
    this.getPackageList();
    
   /*  if (this.giftcardsList.length > 0) {
      this.giftcardsList[0].node.selected = true;
    } */
    packageService.clientCart$.subscribe(cart=>{
      if(this.paramPreset){
        const gift = {sku: this.paramPreset};
        this.selectGiftCard(gift);
      }
      if(cart){
        this.cart = cart;
        this._patchData();
      }
    })
    this.authService.$AuthUser.subscribe((user:any)=>{
      if(user){
        this.user = user;
        this._patchAdditionalInfoForm(user);
      }
    });
  }
  ngOnInit() {
    setTimeout(() => {
      if(!this.paramPreset){
        this.loadGiftcards();}
    }, 1000); // Delay of 2000 milliseconds (2 seconds)
}

  loadGiftcards() {
    this.packageService.getPackagesList().subscribe((res: any) => {
        this.giftcardsList = res.data.products.edges;
        this.giftcardsList.forEach((giftcard: any) => {
            giftcard.selected = false; // Ensure all are unselected on reload
        });
    });
}
  _buildForm(){
    // PaymentForm
    this.recipientForm = this.formBuilder.group({
      recipientName: ['', Validators.required],
      // senderName: ['', Validators.required],
      recipientEmail: ['', Validators.compose([Validators.required, Validators.email])],
      // senderEmail: ['', Validators.compose([Validators.email])],
      message: [''],
      sendDate: [this.today, Validators.compose([Validators.required, this.sendDateValidator])],
    });
  }

  _patchAdditionalInfoForm(user:any){
    this.recipientForm.patchValue({
      // senderName:user.firstName + ' ' + user.lastName,
      // senderEmail: user.email,
      sendDate: this.today
    });
    // this.recipientForm.controls.senderEmail.disable();
    // this.recipientForm.controls.senderName.disable();
  }

  _patchData(){
    // Patch giftcard selection
    if(this.cart.selectedItems.length && this.cart.selectedItems[0].item.id == 'GIFT_CARD'){
      this.isCustomAmountSelected = true;
      this.customAmount =  this.cart?.selectedItems[0]?.price/100;
    }else if(this.cart.selectedItems.length && this.cart.selectedItems[0].item.id != 'GIFT_CARD'){
      const selectedGiftCard = this.giftcardsList.filter((giftcard:any)=>giftcard.node.name == this.cart.selectedItems[0].item.name);
      selectedGiftCard.length ? this.selectGiftCard(selectedGiftCard[0]) : null;
    }

    const recipientType = localStorage.getItem('recipientType');
    recipientType ? this.recipientType = recipientType : null;

    // Patch emailfulfilment
    if(this.isCustomAmountSelected){
      // from cart
      this._patchRecipientForm(this.cart?.selectedItems[0]?.emailFulfillment);
    }else{
      // from local storage
      let recipient:any = localStorage.getItem('packageRecepient');
      if(recipient){
        recipient = JSON.parse(recipient);
        recipient.messageFromSender = recipient.message;
        recipient.deliveryDate = recipient.sendDate;
        this._patchRecipientForm(recipient);
      }
    }
  }

  _patchRecipientForm(recipient:any){
    if(recipient){
      this.recipientForm.patchValue({
        recipientName: recipient.recipientName,
        // senderName: recipient.senderName,
        recipientEmail: recipient.recipientEmail,
        // senderEmail: this?.user?.email,
        message: recipient.messageFromSender,
        sendDate: new Date() > new Date(recipient.deliveryDate) ? this.today : this.getToday(recipient.deliveryDate),
      });
    }
  }

  getToday(dateValue?:string){
    let date = dateValue ? new Date(dateValue) : new Date();
    let m = Number(new Date().getMonth()) + 1;
    let y = new Date().getFullYear();
    let d = new Date().getDate();
    // let returnDate = (m < 10 ? '0'+m : m)  + '/' + (d < 10 ? '0'+d : d) + '/' + y;
    let returnDate = y + '-' + (m < 10 ? '0'+m : m)  + '-' + (d < 10 ? '0'+d : d);
    !dateValue ? this.today = returnDate : null;
    return returnDate;
  }

  sendDateValidator(control: FormControl): { [key: string]: any } | null {
    if(control.value){
      let date = new Date();
      let m = Number(new Date().getMonth()) + 1;
      let y = new Date().getFullYear();
      let d = new Date().getDate();
      let returnDate = (m < 10 ? '0'+m : m)  + '/' + (d < 10 ? '0'+d : d) + '/' + y;
      const today = returnDate;

      let controlDate =  control.value.substr(5,2) + '/' + control.value.substr(8,2) + '/' + control.value.substr(0,4);

      const valid = new Date(controlDate).setHours(0,0,0,0) >= new Date(today).setHours(0,0,0,0);

      return valid ? null : { invalidSendDate: true };
    }else{
      return { invalidSendDate: true };
    }
  }

  changeRecipient(ev:any){
    if(ev == 'myself'){
      const user = this.authService.$AuthUser.value;
      if(user){
        this.recipientForm.patchValue({
          recipientName: user.firstName + ' ' + user.lastName,
          // senderName: user.firstName + ' ' + user.lastName,
          recipientEmail: user.email,
          // senderEmail: user.email,
          sendDate: this.today
        });
      }
    }else if(ev == 'someone'){
      const user = this.authService.$AuthUser.value;
      if(user){
        this.recipientForm.reset();
        this._patchAdditionalInfoForm(user);
      }
    }else{
      this.recipientForm.reset();
    }
    localStorage.setItem('recipientType', ev);
  }
  async ngOnDestroy() {
    await this.packageService.getPackagesList().subscribe((res: any) => {
        this.giftcardsList = res.data.products.edges;
        this.giftcardsList.forEach((giftcard: any) => {
            giftcard.selected = false;
        });
    });
}


  async getPackageList(){
    await this.packageService.getPackagesList().subscribe((res:any)=>{
      this.giftcardsList = res.data.products.edges;
      this.packageService.clientCart$.subscribe(cart=>{
        if(this.paramPreset){
          const gift = {sku: this.paramPreset};
          this.selectGiftCard(gift);
        }
    });
  });
  }

  activeStep(step:number){
    this.enableStep2 = step == 1 ? false : true;
  }
  saveSelection(giftCard:any){
    this.isGiftVoucherSelected=true;
    let toSave = {"packageName":giftCard.node.name,"packageDescription":giftCard.node.description,"packageAmount":giftCard.node.unitCost/100,"customAmount":"false","packageServices":giftCard.node.vouchers[0].services};
    //console.log("TOSAVE",toSave);
    this.sharedService.setLocalStorageItem('packageDetail', JSON.stringify(toSave));
  }

  selectGiftCard(gift: any) {
   // console.log("this.giftcardsList1",this.giftcardsList);
    if (gift.sku) {
      this.giftcardsList.forEach((card: any) => {
        if (card.node.sku === gift.sku) {
          card.node.selected = true;
          this.saveSelection(card);
        } else {
          card.node.selected = false;
        }
      });
    } else {
      this.giftcardsList.forEach((card: any) => {
        card.node.selected = false; 
      });
      const clickedCard = this.giftcardsList.find((card: any) => card.node.sku === gift.node.sku);
      if (clickedCard) {
        clickedCard.node.selected = true;
      }
      //console.log("this.giftcardsList2",this.giftcardsList);
    }
  }
  

  selectCustomAmount(){
    this.isCustomAmountSelected = true;
  }

  confirmAmount(){
    if(this.isSelectedGiftcardChange()){
      this.addGiftcard();
    }else{
      this.enableStep2 = true;
    }
  }

  async addGiftcard(){
    if(this.isCustomAmountSelected){
      if(!this.customAmount || Number(this.customAmount) < 25 || Number(this.customAmount) > 1000){
        const title = 'Enter purchase amounts between $25 and $1000';
        const message = '';
        return this.sharedService.showNotification(title, message);
      }
    }
    this.enableStep2 = true;
  }

  async createCart(){
    const cartId = localStorage.getItem('packageCart');
    if(!cartId){
      const resCreateCart: any = await firstValueFrom(this.packageService.createCart());
      if(!resCreateCart.errors){
        localStorage.setItem('packageCart', resCreateCart.data.createCart.cart.id);
      }else{
        this.sharedService.showNotification("Error", resCreateCart.errors[0].message);
      }
    }
  }

  async addProduct(){
    const selectedGiftcard = this.giftcardsList.filter((giftcard:any)=> giftcard.node.selected);
    //console.log("selectedGiftcard",selectedGiftcard);
    if(selectedGiftcard.length){
      if(this.cart && this.cart.selectedItems.length){
        const selectedItemId = this.cart.selectedItems[0].id;
        const resRemoveProduct: any = await firstValueFrom(this.packageService.removeProductFromCart(selectedItemId));
      }
      const itemId = selectedGiftcard[0].node.id
      //console.log("itemId",itemId);
      const resAddProduct: any = await firstValueFrom(this.packageService.addProductToCart(itemId));
    }
  }

  async addGiftcardInCart(){
    await this.createCart();
    const cartId = localStorage.getItem('packageCart');
    if(this.cart && this.cart.selectedItems.length){
      const selectedItemId = this.cart.selectedItems[0]?.id;
      await firstValueFrom(this.packageService.removeProductFromCart(selectedItemId));
    }
    await firstValueFrom(this.packageService.addGiftCard());
    await this.getCartDetail();
    const packageDetail = localStorage.getItem('packageDetail');
      if(packageDetail){
        var packageDetails = JSON.parse(packageDetail);
      }

    const trackItem = {
      name: packageDetails.packageName,
      id:"GIFT_CARD",
      listPrice:packageDetails.packageAmount,
      total:packageDetails.packageAmount
    }
      
    this.trackingService.addItem(trackItem);
  }

  async getCartDetail(){
    const resCartDetail: any = await firstValueFrom(this.packageService.cartDetail());
    if(!resCartDetail.errors){
      this.packageService.clientCart$.next(resCartDetail.data.cart);
    }else{
      this.sharedService.showNotification("Error", resCartDetail.errors[0].message);
    }
  }

  async addReceipient(){
    const giftCardDetail = localStorage.getItem('packageDetail');
      if(giftCardDetail){
        var giftCardDetails = JSON.parse(giftCardDetail);
      }
    const selectedItemId = this.cart.selectedItems[0].id;
    const resCardDesign: any = await firstValueFrom(this.packageService.setCardDesign(selectedItemId));

    //if(this.cart && this.cart?.selectedItems?.length){
      let payload = this.recipientForm.value;
      payload.senderName = "Me";
      const resEmailfulfilment: any = await firstValueFrom(this.packageService.updateEmailfulfilment(payload));
      if(!resEmailfulfilment.errors){
        // this.giftCardService.getCartDetail();
        const title = 'Recipient information';
        const message = 'added successfully';
       //this.sharedService.showNotification(title, message);
        this.router.navigateByUrl("/packages/review");
      }else{
        this.sharedService.showNotification("Error", resEmailfulfilment.errors[0].message);
      }
    //}else{
      // this.giftCardService.setEmailfulfilment(this.recipientForm.value).subscribe((res:any)=>{
      //   if(!res.errors){
      //     this.giftCardService.getCartDetail();
      //     this.router.navigateByUrl("/giftcard/review");
      //     // this.router.navigateByUrl("/giftcard/email-design");
      //     const title = 'Recipient information';
      //     const message = 'added successfully';
      //     this.sharedService.showNotification(title, message);
      //   }else{
      //     this.sharedService.showNotification("Error", res.errors[0].message);
      //   }
      // });
    //}
  }

  async continue(){
    if(this.isGiftVoucherSelected){
    await this.createCart();
    // Add Recepient
    await this.addProduct();
    this.router.navigateByUrl("/packages/review");
    // Cart Detail
    await this.getCartDetail();
  }else {
    const title = 'Please select a Package';
        const message = '';
        return this.sharedService.showNotification(title, message);
  }
  }
  formatDescription(description: string): string {
    if (!description) {
      return '';
    }

    // Regular expression to replace hyphens that are not preceded by a number
    return description.replace(/(?<!\d)-/g, '<br>-');
  }

  // Changes Detection
  isSelectedGiftcardChange(){
   if(this.customAmount){
    let toSave = {"giftCardName":"GIFT_CARD","giftCardAmount":this.customAmount,"customAmount":"true"};
    this.sharedService.setLocalStorageItem('giftCardDetail', JSON.stringify(toSave));
   }
   return true;
  }

}
