import { Component } from '@angular/core';
import { GiftcardService } from '../giftcard.service';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared-component/shared.service';
import { PDF_INTERFACE, PdfService } from '../pdf.service';
import { Subscription, firstValueFrom } from 'rxjs';
import { TrackingService } from '../tracking.service';

@Component({
  selector: 'app-thanks',
  templateUrl: './thanks.component.html',
  styleUrls: ['./thanks.component.scss']
})
export class ThanksComponent {

  cart:any;
  isCustomAmountSelected:any;
  isGiftcardPurchase:boolean = false;
  giftcardCode:any;
  cartSubscription!:Subscription;

  constructor(private giftcardService:GiftcardService, private router:Router, private trackingService:TrackingService, public sharedService:SharedService, private pdfService:PdfService){
    if(!this.cart){
      this.cartSubscription = giftcardService.clientCart$.subscribe((cart:any)=>{
        this.cart = cart;
        if(this.cart){
          const giftId = this.cart?.selectedItems[0]?.id
          if(this.cart.selectedItems.length && this.cart.selectedItems[0].item.id == 'GIFT_CARD'){
            this.isCustomAmountSelected = true;
            this.getGiftcardCode();
          }else{
            let recipient:any = localStorage.getItem('giftcardRecepient');
            if(recipient){
              recipient = JSON.parse(recipient);
              recipient.messageFromSender = recipient.messageFromSender;
              recipient.deliveryDate = recipient.deliveryDate;
              recipient.unitCost = recipient.unitCost;
              this.cart.selectedItems[0].emailFulfillment = recipient;
            }
            !this.isGiftcardPurchase ? this.giftcardPurchase() : null;
          }
        }else{
          router.navigateByUrl('/giftcards', {replaceUrl: true});
        }
      })
    }
  }

  injectGTAG(giftId:string){
    this.trackingService.setUserDetail();
    this.trackingService.purchase();

    (window as any).gtag('event', 'conversion', {
      'send_to': 'AW-1056511307/uq4qCNDxjpECEMuq5PcD',
      'value': Number(this.sharedService.formatPrice(this.cart?.summary?.total)),
      'currency': 'USD',
      'transaction_id': giftId
      });
  }

  clearCart(){
    this.giftcardService.clientCart$.next(null);
    localStorage.removeItem('giftcardRecepient');
    localStorage.removeItem('giftCardDetail');
    localStorage.removeItem('promoCode');
    this.isGiftcardPurchase = false;
    this.cartSubscription.unsubscribe();
    this.router.navigateByUrl('/giftcards', {replaceUrl: true})
  }
  clearCartBooking(){
    this.giftcardService.clientCart$.next(null);
    localStorage.removeItem('giftcardRecepient');
    localStorage.removeItem('giftCardDetail');
    localStorage.removeItem('promoCode');
    this.isGiftcardPurchase = false;
    this.cartSubscription.unsubscribe();
    this.router.navigateByUrl('/booking', {replaceUrl: true});
  }

  downloadPDF(){

    const amount = !this.isCustomAmountSelected ? (this.cart?.selectedItems[0].emailFulfillment?.unitCost/100).toFixed(2) : (this.cart?.summary?.subtotal/100).toFixed(2)

    const param:PDF_INTERFACE = {
      productName: this.sharedService.getGiftCardName(),
      sender:this.cart?.selectedItems[0].emailFulfillment?.senderName,
      receiver:this.cart?.selectedItems[0].emailFulfillment?.recipientName,
      amount: amount,
      code:this.giftcardCode,
      message:this.cart?.selectedItems[0].emailFulfillment?.messageFromSender ? this.cart?.selectedItems[0].emailFulfillment?.messageFromSender : ''
    }

    this.pdfService.createPDF(param, true);
    const title = 'Silver Mirror Gift Card has been successfully downloaded!';
    const message = '';
    //this.sharedService.showNotification(title, message);
  }

  async giftcardPurchase(){
    const resClientByEmail: any = await firstValueFrom(this.giftcardService.getClientByEmail(this.cart.clientInformation.email));
    const payload = {
      "amount": this.cart?.selectedItems[0].emailFulfillment?.unitCost,
      "giftcardName": this.cart?.selectedItems[0].item.name,
      "note": "",
      "deliveryDate": this.cart?.selectedItems[0]?.emailFulfillment?.deliveryDate,
      "messageFromSender": this.cart?.selectedItems[0].emailFulfillment?.messageFromSender ? this.cart?.selectedItems[0].emailFulfillment?.messageFromSender : '',
      "recipientEmail": this.cart?.selectedItems[0]?.emailFulfillment?.recipientEmail,
      "recipientName": this.cart?.selectedItems[0].emailFulfillment?.recipientName,
      "senderName": this.cart?.selectedItems[0].emailFulfillment?.senderName,
      "clientId": resClientByEmail.data.clients.edges[0].node.id
    }

    const resProcessGiftcard: any = await firstValueFrom(this.giftcardService.processGiftcard(payload));
    if(!resProcessGiftcard.errors){
      this.giftcardCode = resProcessGiftcard.giftcard.data.giftCard.code;
      this.injectGTAG(resProcessGiftcard.giftcard.data.giftCard.id);
    }
    this.isGiftcardPurchase = true;
  }

  async getGiftcardCode(){
    const resGiftcardCode: any = await firstValueFrom(this.giftcardService.getGiftcardCode(this.cart.id));
    if(!resGiftcardCode.errors){
      this.giftcardCode = resGiftcardCode?.data?.cart?.orders[0]?.lineGroups[0]?.lines[0]?.giftCardCode;
      this.sendGiftCardMail();
      this.injectGTAG(this.cart.id);
    }
  }

  async sendGiftCardMail(){
    let formData = new FormData();
    const amount = !this.isCustomAmountSelected ? (this.cart?.selectedItems[0].emailFulfillment?.unitCost/100).toFixed(2) : (this.cart?.summary?.subtotal/100).toFixed(2)

    const param:PDF_INTERFACE = {
      productName: this.sharedService.getGiftCardName(),
      sender:this.cart?.selectedItems[0].emailFulfillment?.senderName,
      receiver:this.cart?.selectedItems[0].emailFulfillment?.recipientName,
      amount: amount,
      code:this.giftcardCode,
      message:this.cart?.selectedItems[0].emailFulfillment?.messageFromSender ? this.cart?.selectedItems[0].emailFulfillment?.messageFromSender : ''
    }
    //console.log("param",param);
    let pdfBase64 = this.pdfService.createPDF(param, false);
    pdfBase64 = pdfBase64.split(',')[1] || pdfBase64;
    
    formData.append('file', pdfBase64); // Append the cleaned base64 data
    formData.append('sender', this.cart?.selectedItems[0].emailFulfillment?.senderName);
    formData.append('receiver', this.cart?.selectedItems[0].emailFulfillment?.recipientName);
    formData.append('amount', amount);
    formData.append('code', this.giftcardCode);
    formData.append('message', this.cart?.selectedItems[0].emailFulfillment?.messageFromSender ? this.cart?.selectedItems[0].emailFulfillment?.messageFromSender : '');
    formData.append('senderEmail', this.cart.clientInformation.email);
    formData.append('receiverEmail', this.cart?.selectedItems[0]?.emailFulfillment?.recipientEmail);
    formData.append('giftcardName', this.sharedService.getGiftCardName());

    this.giftcardService.sendGiftcardMail(formData).subscribe();

  }

  ngOnDestroy(){
    this.clearCart();
  }

}
